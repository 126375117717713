<script>
import {
  getAuth,
  sendPasswordResetEmail,
  GoogleAuthProvider
} from "firebase/auth";
import Button from "../components/Button.vue";
import Input from "../components/Input.vue";
import { app, db } from "../firestore/init.js";
import * as UnicornStudio from '../lib/unicornStudio';

const auth = getAuth(app);

export default {
  components: {
    Button,
    Input,
  },
  data() {
    return {
      email: '',
      showGoogleSignIn: false,
      resettingPassword: false,
      passwordReset: false,
      validation: {
        invalidEmail: false,
        error: "",
      },
    };
  },
  mounted() {
    this.showGoogleSignIn = localStorage.getItem('signedInWithGoogle');
    
    this.unicornStudio = UnicornStudio;
    if(window.location.hostname.includes('unicorn.studio') && window.UnicornStudio) {
      this.unicornStudio = window.UnicornStudio;
    }
    if(this.unicornStudio && this.unicornStudio.init) {
      this.unicornStudio.init();
    }
  },
  beforeUnmount() {
    if(this.unicornStudio && this.unicornStudio.destroy) {
      this.unicornStudio.destroy();
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.resetPassword();
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    resetPassword() {
      this.resettingPassword = true;
      sendPasswordResetEmail(auth, this.email).then(() => {
        console.log("Password reset email sent.");
        this.resettingPassword = false;
        this.passwordReset = true;
      }).catch((error) => {
        this.resettingPassword = false;
        this.validation.error = "Error sending password reset email: ", error;
        console.error("Error sending password reset email: ", error);
      });
    },

  },
};
</script>

<template>
  <div class="login-container">
    <div v-if="resettingPassword" class="loading-bar"></div>
    <div class="form-container">
      <div class="login-form">
        <div class="unicorn-studio-logo" ref="unicornStudioLogo" data-us-production="true" data-us-project-src="../Login.json"></div>
        <h1 class="h2">Reset password</h1>
          <form class="form" @submit="submitForm">
            <div class="field">
              <Input 
                class="field-input"
                autocomplete="username"
                type="email"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <p v-if="passwordReset" class="success-text">Password reset email sent. Please check your email. <a href="/login">Back to login.</a></p>
            <p v-else-if="validation.error" class="validation-error">
              {{ validation.error }}
            </p>
            <Button v-if="!passwordReset" :loading="resettingPassword" class="primary large mt-4 w-100" type="submit">
              Reset password
            </Button>
          </form>
        
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login-form {
  padding: 3rem 2rem;

  .h2 {
    text-align: center;
    margin-bottom: 1em;
  }
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  color: var(--font-color);
  background-color: var(--artboard-color);

  @media only screen and (max-width: 58rem) {
    flex-direction: column;
  }
}

.form-container {
  border-radius: 0.5rem;
  width: 34rem;
  margin: 0 auto;
  border: 1px solid var(--border-color);
}

.field {
  border: 1px solid var(--bg-color);
  border-radius: 0.4rem;
}

.form-container {
  @media only screen and (max-width: 58rem) {
    margin-top: 5rem;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--accent-color);
}

.logo {
  display: block;
  width: 5rem;
  margin: 0 auto 3rem;
  margin-bottom: 3rem;
  color: inherit;

  img {
    width: 100%;
  }
}

.unicorn-studio-logo {
  width: 12rem;
  height: 12rem;
  margin: 0 auto 2rem;
}

video,
iframe {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
}

iframe {
  outline: none;
  border: 0;
}

.signup-link {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--font-color) !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--bg-color) !important;
}
</style>