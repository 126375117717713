<script>
import { randomInt } from '../scripts/Helpers.js';

export default {
    props: ['template'],
    data() {
        return {
            fakeSizes: [[16, 9], [16, 9], [16, 9], [16, 9], [16, 9]]
        }
    },
    mounted() {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        const ctx = this.$refs.placeholder.getContext('2d');
        const size = this.fakeSizes[randomInt(0, 3)];
        this.$refs.placeholder.width = size[0] * 20;
        this.$refs.placeholder.height = size[1] * 20;
        ctx.fillStyle = darkThemeMq ? '#3b3c42' : '#eaeaea';
        ctx.fillRect(0, 0, this.$refs.placeholder.width, this.$refs.placeholder.height);
    }
}

</script>

<template>
    <div class="design-listing-item-loading" :class="{'template-loading-item': template}">
      <div class="design-item-link">
        <canvas ref="placeholder"></canvas>
      </div>
      <div class="design-listing-info mt-1">
        <div class="design-listing-name">██████</div>
      </div>
    </div>
</template>

<style lang="scss">

.design-listing-item-loading {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: var(--bg-color);

  &.template-loading-item {
    width: 12%;
    margin-bottom: -0.8rem;

    canvas {
      aspect-ratio: 1;
      object-fit: cover;
      height: auto;
      margin-top: -0.8rem;
    }

    .design-listing-name {
      font-size: 1.2rem;
    }
  }

  .design-item-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  canvas {
    position: relative;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;
    padding: 1.5rem;
    animation: listing-loading 1s linear infinite;
  }

  .design-listing-info {
    padding: 0 2rem 2rem;
    width: 100%;
    color: inherit;
  }

  .design-listing-name {
    color: var(--border-color);
    animation: listing-loading 1s linear infinite;
  }
}

@keyframes listing-loading {
  0% {
    opacity: 0.65;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.65;
  }
}

</style>