import { vertexShader, BLEND, UNIVERSAL_UNIFORMS } from '../ShaderHelpers.js';
import { BLEND_MODES } from '../../scripts/Constants.js';
import { Vec2, Vec3 } from 'curtainsjs';

const mouseFragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;
in vec3 vVertexPosition;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;

uniform float uAmount;
uniform float uRadius;
uniform float uScale;
uniform float uTime;
uniform float uChromAbAmount;
uniform vec3 uColor;
uniform float uDistortion;
uniform int uBlendMode; 
uniform float uMix;
${UNIVERSAL_UNIFORMS}
${BLEND}

const float PI = 3.1415926;

out vec4 fragColor;

float random(vec2 p) {
  p = fract(p * vec2(123.45, 678.90));
  p += dot(p, p + 45.32);
  return fract(p.x * p.y);
}

mat2 rot(float a) {
    float c = cos(a);
    float s = sin(a);
    return mat2(c, -s, s, c);
}

vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec2 angleToDir(float angle) {
    float rad = angle * 2.0 * PI;
    return vec2(cos(rad), sin(rad));
}

void main() {
    vec2 uv = vTextureCoord;
    vec2 pingpongUv = uv;

    vec3 mouseRgb = texture(uPingPongTexture, pingpongUv).rgb;
    vec3 mouseTrail = rgb2hsv(mouseRgb);
    float angle = mouseTrail.x;
    float strength = mouseTrail.z * (uAmount * 5.0);

    vec2 direction = angleToDir(angle);

    vec4 bg = texture(uTexture, uv - (direction * 0.1 * strength * uDistortion));
    vec4 color = vec4(0,0,0,1);

    color.rgb = vec3(strength * mix(mouseRgb, uColor, uMix));
    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;

    if(uBlendMode > 0) {
      vec3 blendedRgb = blend(uBlendMode, color.rgb + dither, bg.rgb);
      fragColor = vec4(mix(bg.rgb, blendedRgb, mouseTrail.z), 1.0);
    } else {
      fragColor = mix(bg, color, mouseTrail.z);
    }
}

`;

const mouseParams = {
  fragmentShader: mouseFragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: 'half-float',
    premultiplyAlpha: true,
  },
  uniforms: {
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080),
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(0, 0.51, 0.97),
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 0.5,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    distortion: {
      name: 'uDistortion',
      type: '1f',
      value: 0,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 1,
    },
  },
};

export const MOUSE_DRAW = {
  id: 'mouseDraw',
  label: 'Mouse draw',
  params: mouseParams,
  aspectRatio: 1,
  // animation: {
  //   active: true,
  //   speed: 1,
  // },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Strength',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    turbulence: {
      label: 'Turbulence',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    color: {
      label: 'Tint',
      value: new Vec3(0, 0.51, 0.97),
      output: 'color',
    },
    mix: {
      label: 'Color Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    bloom: {
      label: 'Bloom',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    decay: {
      label: 'Tail',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'This controls the length of the tail, or the speed of the decay',
    },
    distortion: {
      label: 'Distortion',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Adds a distortion to the underlying texture',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'ADD',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
  },
};
