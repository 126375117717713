import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

let fragmentShader = `#version 300 es
precision mediump float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBlueNoise;
${UNIVERSAL_UNIFORMS}


uniform vec3 uTint; // Color of the light
uniform vec2 uPos; // Position of the light
uniform float uAmount;
uniform float uScale;
uniform float uThreshold;
uniform float uDiffusion;
uniform float uAmbience;
uniform int uInvert;
uniform float uTime; // Time for dynamic effects

const float PI2 = 6.28318530718; // 2 * PI
const float TAU = 6.28318530718; // 2 * PI, same as PI2
const float EPSILON = 0.0001; // A small value to avoid precision issues

out vec4 fragColor;

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float luminance(vec3 color) {
    return dot(color, vec3(0.299, 0.587, 0.114));
}

vec3 Tonemap_tanh(vec3 x) {
    x = clamp(x, -40.0, 40.0);
    return (exp(x) - exp(-x)) / (exp(x) + exp(-x));
}

// Function to get blue noise offset
float getBlueNoiseOffset(vec2 st) {
    ivec2 texSize = textureSize(uBlueNoise, 0);
    vec4 blueNoise = texelFetch(uBlueNoise, ivec2(fract(st * (uResolution)/vec2(texSize) * vec2(texSize.x/texSize.y, 1.0)) * vec2(texSize)) % texSize, 0);
    float blueNoiseSample = blueNoise.r;
    return mod((blueNoiseSample * PI2) + (uTime * (1.0 / PI2)), PI2);
}

void main() {
    vec4 sceneColor = texture(uTexture, vTextureCoord);
    vec2 aspectRatio = uResolution / min(uResolution.x, uResolution.y);
    vec2 uv = vTextureCoord * aspectRatio;
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    pos = pos * aspectRatio;
    vec2 lightDir = normalize(pos - uv);
    bool hitObject = false;
    vec2 marchPos = uv;
    float rayDist = 0.;
    float blueNoiseOffset = getBlueNoiseOffset(marchPos);
    vec2 rayDirOffset = vec2(cos(blueNoiseOffset), sin(blueNoiseOffset));
    float diffusion = uDiffusion*0.125;
    float lightDist = length(pos - uv);
    float cappedScale = min(1.8, uScale);
    float ambient = 0.;
    
    vec2 offset = rayDirOffset * diffusion * blueNoiseOffset * 0.25;
    vec2 step = (lightDir + offset) * 0.01 * cappedScale;
    vec3 prevColor = vec3(0.0);

    for(int i = 1; i < 64; i++) {
        float marchDist = length(marchPos - uv);
        marchPos += step * mix(marchDist, 1., 0.4);
        vec4 texColor = texture(uTexture, marchPos / aspectRatio);

        rayDist = marchDist / lightDist;

        vec3 color = texColor.rgb;

        float colorDiff = length(color - prevColor);
        bool hit = uInvert == 0 ? colorDiff > uThreshold : colorDiff < uThreshold;
            
        if (hit && !hitObject) {
            hitObject = true;
            prevColor = color;
            if(marchDist < lightDist) {
              break;
            }
        } else {
          ambient -= 0.01;
        }
    }

    rayDist = mix(rayDist * lightDist, rayDist, uAmount * 1.5);
    
    vec3 lightColor = mix(uTint, sceneColor.rgb * uTint, (1.-rayDist + ambient * uAmbience));
    lightColor = Tonemap_tanh(sceneColor.rgb * lightColor);
    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    lightColor += dither;
    vec4 color = vec4(lightColor, 1.);
    
    ${computeFragColor('color')}
}
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    scale: {
      name: 'uScale',
      type: '1f',
      value: 1,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    ambiance: {
      name: 'uAmbience',
      type: '1f',
      value: 0.5,
    },
    diffusion: {
      name: 'uDiffusion',
      type: '1f',
      value: 0.5,
    },
    threshold: {
      name: 'uThreshold',
      type: '1f',
      value: 0.5,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(0.61, 0.78, 1),
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    invert: {
      name: 'uInvert',
      type: '1i',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const TWOD_LIGHT = {
  id: 'twodlight',
  label: '2D Light',
  params: params,
  aspectRatio: 1,
  texture: {
    src: 'https://assets.unicorn.studio/media/blue_noise_med.png',
    sampler: 'uBlueNoise',
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Intensity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ambiance: {
      label: 'Ambiance',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    tint: {
      label: 'Color',
      output: 'color',
      value: new Vec3(0.98, 0.12, 0.89),
    },
    diffusion: {
      label: 'Diffusion',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    threshold: {
      label: 'Threshold',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Helps you calibrate the shadow sensitivity',
    },
    invert: {
      label: 'Invert',
      value: 0,
      classic: true,
      options: {
        1: 'On',
        0: 'Off',
      },
    },
    ...interactiveProperties,
  },
};
