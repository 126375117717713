<script>
import { StudioStore } from '../stores/StudioStore.js';
import PlayButton from './PlayButton.vue';
import EasingIcon from './EasingIcon.vue';
import { formatStateEffectValue } from '../scripts/Helpers.js';

export default {
  components: { PlayButton, EasingIcon },
  data() {
    return {
      baseGridStep: 100,
      snapStep: 25,
      labelWidth: 120,
      rowHeight: 35,
      currentTime: 0,
      animationFrame: null,
      startTime: null,
      state: StudioStore.state,
      playheadPosition: 0,
      isHovered: false,
      collapseTimeout: null,
      isCollapsing: false,
      playback: {
        isPlaying: false,
        currentTime: 0,
        duration: 0,
        pausedAt: 0
      },
      scrollLeft: 0,
      dragRAF: null,
    }
  },
  watch: {
    "state.previewing": {
      handler(newVal) {
        if(newVal) {
          this.startPlayback();
        } else {
          this.stopPlayback();
        }
      }
    }
  },
  computed: {
    selectedAppearItems() {
      return StudioStore.state.selectedItems.filter(item => item.states.appear.length).length > 0;
    },
    groupedEvents() {
      const groups = {};
      if (!StudioStore.state.history) return groups;
      
      Object.entries(StudioStore.state.history).forEach(([itemId, item]) => {
        if (item && item.states && item.states.appear) {
          item.states.appear.forEach((event, index) => {
            const eventId = `${itemId}-${index}`;
            const eventWithItem = {
              ...event,
              item: item
            };
            groups[eventId] = {
              name: item.name || itemId,
              events: [eventWithItem]
            };
          });
        }
      });
      
      return groups;
    },
    totalDuration() {
      let maxTime = 1000;
      Object.values(this.groupedEvents).forEach(group => {
        group.events.forEach(event => {
          const eventEnd = event.transition.delay + event.transition.duration;
          maxTime = Math.max(maxTime, eventEnd);
        });
      });
      // Round up to nearest 250ms and add 20% padding
      maxTime = Math.ceil(maxTime * 1.2);
      return Math.ceil(maxTime / 250) * 250;
    },
    timeMarkers() {
      const markers = [];
      let step = 100; // Base step in ms
      let floats = 2;
      
      // Adjust step size based on total duration
      if (this.totalDuration > 50000) {
        step = 5000;
        floats = 0;
      } else if (this.totalDuration > 30000) {
        step = 3000;
      } else if (this.totalDuration > 20000) {
        step = 2000;
      } else if (this.totalDuration > 10000) {
        step = 1000;
      } else if (this.totalDuration > 5000) {
        step = 500;
      } else if (this.totalDuration > 2500) {
        step = 250;
      }
      
      // Round total duration up to nearest step
      const roundedDuration = Math.ceil(this.totalDuration / step) * step;
      const numberOfSteps = roundedDuration / step;
      
      // Generate markers at fixed step intervals
      for (let i = 0; i <= numberOfSteps; i++) {
        const time = ((i * step) / 1000).toFixed(floats);
        markers.push(time);
      }
      
      return markers;
    },
    gridSize() {
      // Use the exact same step calculation as timeMarkers
      let step = 100;
      if (this.totalDuration > 20000) {
        step = 2000;
      } else if (this.totalDuration > 10000) {
        step = 1000;
      } else if (this.totalDuration > 5000) {
        step = 500;
      } else if (this.totalDuration > 2500) {
        step = 250;
      }
      
      return `${(step / this.totalDuration) * 100}%`;
    },
    scrollEffectOpen() {
      return StudioStore.state.openStateEffect?.type === 'scroll';
    },
    isPlaying() {
      return this.playback.isPlaying;
    },
    timelineWidth() {
      return this.$el?.querySelector('.timeline-grid')?.offsetWidth || 0;
    },
    playheadStyle() {
      // Calculate relative position accounting for labelWidth
      const timelineWidth = this.$el?.querySelector('.timeline-grid')?.offsetWidth || 0;
      const relativePosition = (this.playheadPosition / 100) * timelineWidth;

      return {
        left: `${this.labelWidth + relativePosition}px`,
        top: !this?.selectedAppearItems && !this?.isHovered ? '3.2rem' : '3.2rem'
      }
    },
    timelineHeight() {
      // if (!this?.selectedAppearItems && !this.isHovered) {
      //   return '3.8rem' // Height of timeline-header
      // }
      return 'auto'
    },
    playbackDuration() {
      let maxTime = 1000;
      StudioStore.state.history.forEach(item => {
        if (item.states?.appear) {
          item.states.appear.forEach(event => {
            const eventEnd = event.transition.delay + event.transition.duration;
            maxTime = Math.max(maxTime, eventEnd);
          });
        }
      });
      return maxTime;
    }
  },
  methods: {
    handleDragStart(itemId, event, dragEvent, type = 'move') {
      dragEvent.dataTransfer.setData('text/plain', '');
      const img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
      dragEvent.dataTransfer.setDragImage(img, 0, 0);
      
      // Cache timeline dimensions and initial values
      const timeline = this.$el.querySelector('.timeline-grid');
      this.dragStartRect = timeline.getBoundingClientRect();
      this.dragStartPixelsPerMs = this.dragStartRect.width / this.totalDuration;
      
      // Store initial values
      this.dragStartX = dragEvent.clientX;
      this.dragType = type;
      this.dragEvent = event;
      this.initialDelay = event.transition.delay;
      this.initialDuration = event.transition.duration;
      this.dragItemId = itemId;
      this.initialTotalDuration = this.totalDuration;
    },

    handleDrag(itemId, event, dragEvent) {
      // Cancel any existing RAF
      if (this.dragRAF) {
        cancelAnimationFrame(this.dragRAF);
      }

      // Skip invalid drag events
      if (!dragEvent.clientX) return;
      
      // Use RAF to smooth out updates
      this.dragRAF = requestAnimationFrame(() => {
        const deltaX = dragEvent.clientX - this.dragStartX;
        const deltaMs = deltaX / this.dragStartPixelsPerMs;

        // Cache commonly used values
        const snapStep = this.snapStep;
        const totalDuration = this.initialTotalDuration;

        let updatedEvent = false;

        if (this.dragType === 'move') {
          const newDelay = Math.max(0, this.initialDelay + deltaMs);
          const snappedDelay = Math.round(newDelay / snapStep) * snapStep;
          
          if (event.transition.delay !== Math.min(snappedDelay, totalDuration)) {
            event.transition.delay = Math.min(snappedDelay, totalDuration);
            updatedEvent = true;
          }

        } else if (this.dragType === 'resize-end') {
          const newDuration = this.initialDuration + deltaMs;
          const snappedDuration = Math.round(newDuration / snapStep) * snapStep;
          const maxDuration = Math.max(
            snapStep,
            Math.min(snappedDuration, totalDuration - event.transition.delay)
          );
          
          if (event.transition.duration !== maxDuration) {
            event.transition.duration = maxDuration;
            updatedEvent = true;
          }

        } else if (this.dragType === 'resize-start') {
          const newDelay = Math.max(0, this.initialDelay + deltaMs);
          const snappedDelay = Math.round(newDelay / snapStep) * snapStep;
          const delayDiff = snappedDelay - this.initialDelay;
          
          if (event.transition.delay !== snappedDelay) {
            event.transition.delay = snappedDelay;
            event.transition.duration = Math.max(
              snapStep,
              this.initialDuration - delayDiff
            );
            updatedEvent = true;
          }
        }

        // Only emit if values actually changed
        if (updatedEvent) {
          this.$emit('update:event', { itemId, event });
        }
      });
    },

    handleEventClick(itemId, event) {
      let item = event.item;
      StudioStore.setSelectedItem(item);
      StudioStore.state.openStateEffect = item.states.appear.find(n => n.id === event.id);
    },

    togglePlayback() {
      if (this.isPlaying) {
        this.pausePlayback();
      } else {
        this.startPlayback();
      }
    },

    startPlayback() {
      if(!StudioStore.state.curtain?.planes) return;
      
      
      if (this.playback.pausedAt) {
        this.startTime = performance.now() - this.playback.pausedAt;
        
        // Adjust all animation timestamps to resume from pause point
        StudioStore.state.curtain.planes.forEach(plane => {
          plane.userData.createdAt = performance.now() - this.playback.pausedAt;
        });
        
        this.playback.pausedAt = 0;
      } else {
        this.startTime = performance.now();
        // Reset animation states
        StudioStore.state.curtain.planes.forEach(plane => {
          plane.userData.createdAt = this.startTime;
        });
        
        // Reset appear effects
        StudioStore.state.history
          .filter(n => n.states && n.states.appear.length)
          .forEach(item => {
            item.local.createdAt = this.startTime;
            item.states.appear.forEach(effect => {
              effect.resetState();
            });
          });
      }
      
      this.playback.isPlaying = true;
      this.animate();
    },

    pausePlayback() {
      this.playback.isPlaying = false;
      this.playback.pausedAt = performance.now() - this.startTime;
      
      // Freeze all animations by updating their createdAt times
      StudioStore.state.curtain.planes.forEach(plane => {
        plane.userData.createdAt = this.startTime - (performance.now() - this.playback.pausedAt);
      });
      
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }
    },

    stopPlayback() {
      this.playback.isPlaying = false;
      this.playback.currentTime = 0;
      this.playback.pausedAt = 0;
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }
    },

    updatePlaybackTime(currentTime) {
      this.playback.currentTime = currentTime;
      
      StudioStore.state.history
        .filter(n => n.states && n.states.appear.length && n.getPlane())
        .forEach(item => {
          const plane = item.getPlane();
          plane.userData.createdAt = performance.now() - currentTime;
          item.local.createdAt = performance.now() - currentTime;

          if(!StudioStore.state.history.some(n => n.animating)) {
            item.states.appear.forEach(effect => {
              effect.complete = false;
            });
          }
          item.updateStateEffects();
        });
    },

    animate() {
      if (!this.playback.isPlaying) return;
      
      const currentTime = performance.now() - this.startTime;
      // Ensure we don't exceed playbackDuration
      const playbackTime = Math.min(currentTime, this.playbackDuration);
      
      // Calculate playhead position more precisely
      this.playheadPosition = (playbackTime / this.totalDuration) * 100;
      
      // Update current playback time
      this.updatePlaybackTime(playbackTime);

      if(!StudioStore.state.animatingEffects.length) {
        StudioStore.renderFrame();
      }
      
      if (playbackTime >= this.playbackDuration) {
        this.stopPlayback();
        this.playheadPosition = 0;
        return;
      }
      
      this.animationFrame = requestAnimationFrame(() => this.animate());
    },

    formatValue(value, event) {
      if (!event || !event.item || !event.prop) {
        return value;
      }

      // Check for any prop that has a corresponding Mode property
      const modeProperty = `${event.prop}Mode`;
      if (event.item[modeProperty]) {
        const mode = event.item[modeProperty];
        return mode === 'relative' ? `${Math.round(value * 100)}%` : `${Math.round(value)}px`;
      }

      const params = event.item.getParams();
      if (!params) {
        return value;
      }

      return formatStateEffectValue(value, event.prop, params);
    },

    handleKeydown(event) {
      if (event.code === 'Space' && this.isHovered) {
        this.togglePlayback();
      }
    },

    handleMouseEnter() {
      if (this.collapseTimeout) {
        clearTimeout(this.collapseTimeout);
      }
      this.isCollapsing = false;
      this.isHovered = true;
    },
    
    handleMouseLeave() {
      clearTimeout(this.collapseTimeout);
      if(this?.selectedAppearItems) {
        this.isHovered = false;
        return;
      };
      
      this.collapseTimeout = setTimeout(() => {
        this.isCollapsing = true;
        setTimeout(() => {
          this.isCollapsing = false;
          this.isHovered = false;
        }, 200); // Match this with CSS transition duration
      }, 500); // Delay before starting collapse
    },

    seekToTime(timeMs) {
      
      // Calculate new startTime to achieve desired playback position
      this.startTime = performance.now() - timeMs;
      this.playback.pausedAt = timeMs;
      
      // Use totalDuration instead of playbackDuration for consistent positioning
      this.playheadPosition = (timeMs / this.totalDuration) * 100;
      
      // Update animations to new time
      this.updatePlaybackTime(timeMs);
      this.pausePlayback();
    },

    handleScroll(event) {
      this.scrollLeft = event.target.scrollLeft;
    },

    handleResize() {
      this.scrollLeft = 0;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('resize', this.handleResize);
    this.stopPlayback();
    if (this.dragRAF) {
      cancelAnimationFrame(this.dragRAF);
    }
  }
}
</script>

<template>
  <div class="timeline-container" :class="{ 'timeline-peek': !selectedAppearItems && !isHovered || scrollEffectOpen, 'timeline-collapsing': isCollapsing }"
    @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="timeline-content" :style="{ height: timelineHeight }">
      <!-- Fixed header section -->
      <div class="timeline-header">
        <div class="timeline-controls">
          <PlayButton 
            :animating="isPlaying"
            :visible="true"
            :animation="true"
            :borderless="true"
            @toggle-animation="togglePlayback"
          />
        </div>
        <div class="timeline-main-header">
          <div class="time-markers-container">
            <div class="time-markers" :style="{ transform: `translateX(-${scrollLeft}px)` }">
              <div v-for="time in timeMarkers" 
                  :key="time" 
                  class="marker"
                  :style="{ left: `${(time * 1000 / totalDuration) * 100}%` }"
                  @click="seekToTime(parseFloat(time) * 1000)">
                {{ time }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Playhead moved outside scroll container -->
      <div class="playhead" :style="playheadStyle">
        <div class="playhead-caret"></div>
        <div class="playhead-line"></div>
      </div>

      <!-- Scrollable content section -->
      <div class="timeline-scroll-container" @scroll="handleScroll">
        <div class="timeline-left-column">
          <template v-for="[itemId, group] in Object.entries(groupedEvents)" :key="itemId">
            <div v-for="event in group.events" 
                 :key="`${itemId}-${event.id}`" 
                 class="property-label">
              {{ event.item.getParams().properties[event.prop].label }}
            </div>
          </template>
        </div>

        <div class="timeline-main">
          <div class="timeline-grid">
            <div class="grid-background"></div>
            <!-- Timeline content -->
            <template v-for="[itemId, group] in Object.entries(groupedEvents)" :key="itemId">
              <div class="item-group">
                <div v-for="event in group.events" 
                     :key="`${itemId}-${event.id}`" 
                     class="timeline-row">
                  <div class="event-bar"
                       :style="{
                         left: `${(event.transition.delay / totalDuration) * 100}%`,
                         width: `${(event.transition.duration / totalDuration) * 100}%`,
                       }"
                       :class="{ 'event-bar-selected': state.openStateEffect?.id === event.id }"
                       draggable="true"
                       @click="handleEventClick(itemId, event)"
                       @dragstart.stop="handleDragStart(itemId, event, $event, 'move')"
                       @drag="handleDrag(itemId, event, $event)">
                    <div class="resize-handle left"
                         draggable="true"
                         @dragstart.stop="handleDragStart(itemId, event, $event, 'resize-start')"
                         @drag.stop="handleDrag(itemId, event, $event)"></div>
                    <div class="event-bar-content" style="position: relative;">
                      <div class="event-value start" v-if="(event.transition.duration / totalDuration) > 0.1">{{ formatValue(event.value, event) }}</div>
                      <div class="flex align-center">
                        <EasingIcon :ease="event.transition.ease" size="14" class="mr-1" />
                        <div class="event-bar-duration">{{ event.transition.duration }}ms</div>
                      </div>
                      <div class="event-value end" v-if="(event.transition.duration / totalDuration) > 0.1">{{ formatValue(event.endValue ?? event.item[event.prop], event) }}</div>
                    </div>
                    <div class="resize-handle right"
                         draggable="true"
                         @dragstart.stop="handleDragStart(itemId, event, $event, 'resize-end')"
                         @drag.stop="handleDrag(itemId, event, $event)"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.timeline-container {
  position: absolute;
  bottom: 0;
  left: 29rem;
  right: 29rem;
  background: var(--bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.4rem;
  transition: transform 0.2s ease-out;
  
  &.timeline-peek {
    transform: translateY(calc(100% - 3.8rem));
    
    .timeline-left-column {
      // Hide everything except the controls in collapsed state
      > *:not(.timeline-controls) {
        display: none;
      }
    }
  }

  &.timeline-collapsing {
    transform: translateY(calc(100% - 3.8rem));
    transition: transform 0.2s ease-in;
  }
}

.timeline-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.timeline-header {
  position: sticky;
  top: 0;
  z-index: 4;
  background: var(--bg-color);
  display: flex;
  height: 3.8rem;
  border-bottom: 1px solid var(--border-color);
}

.timeline-main-header {
  flex: 1;
  min-width: 0;
  position: relative;
  overflow: hidden;
}

.timeline-scroll-container {
  display: flex;
  max-height: calc(v-bind(rowHeight) * 5px + 2px);
  overflow-y: auto;
  position: relative;
}

.timeline-controls {
  position: sticky;
  left: 0;
  z-index: 5;
  background: var(--bg-color);
  width: calc(v-bind(labelWidth) * 1px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--border-color);
}

.timeline-left-column {
  position: sticky;
  left: 0;
  z-index: 3;
  background: var(--bg-color);
  width: calc(v-bind(labelWidth) * 1px);
  box-shadow: 2px 0 6px var(--artboard-color);
  border-right: 1px solid var(--border-color);
}

.timeline-main {
  flex: 1;
  min-width: 0;
  position: relative;
}

.timeline-grid {
  flex: 1;
  position: relative;
  min-width: 800px; // Ensure minimum width for scrolling
  margin-left: 0;
}

.grid-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, var(--border-color) 1px, transparent 1px);
  background-size: v-bind(gridSize) 100%;
  background-position: 0 0; // Ensure grid lines start from the edge
}

.timeline-row {
  height: calc(v-bind(rowHeight) * 1px);
  position: relative;
}

.event-bar {
  position: absolute;
  top: 0.3rem;
  height: calc(100% - 0.6rem);
  background: var(--selected-radio-color);
  border-radius: 0.4rem;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  min-width: 4rem;
  position: relative;
  
  .event-bar-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0.3rem;
      bottom: 0.3rem;
      width: 0.2rem;
      border-radius: 0.2rem;
      background: rgba(0, 0, 0, 0.15);
    }

    &:before {
      left: 0.3rem;
    }

    &:after {
      right: 0.3rem;
    }
  }
  
  .resize-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1rem;
    cursor: ew-resize;
    z-index: 999;
    border-radius: 0.4rem;
    
    &.right {
      right: -0.5rem;
    }
    
    &.left {
      left: -0.5rem;
    }
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  
  &:hover {
    filter: brightness(1.1);
  }

  &.event-bar-selected {
    background: var(--primary-color);
  }
}

.playhead {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  z-index: 999;
  pointer-events: none;
  height: 100%;
  //margin-left: calc(v-bind(labelWidth) * 1px);

  &:before {
    content: '';
    position: absolute;
    top: -0.5rem;
    height: 0.5rem;
    left: -0.5rem;
    width: 1rem;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    background-color: var(--primary-color);
  }
  .playhead-caret {
    position: absolute;
    top: 0;
    left: -0.5rem;
    width: 1rem;
    height: 0.5rem;
    background-color: var(--primary-color);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }

  .playhead-line {
    position: absolute;
    top: 0.4rem;
    bottom: 0;
    left: -0.5px;
    width: 1px;
    background-color: var(--primary-color);
  }
}

.item-group-header {
  padding: 0.5rem 1rem;
  background: var(--bg-secondary-color);
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
}

.event-bar {
  .event-value {
    position: absolute;
    font-size: 10px;
    white-space: nowrap;
    opacity: 0.7;
    
    &.start {
      left: 1rem;
    }
    
    &.end {
      right: 1rem;
    }
  }
}

.time-markers-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.time-markers {
  position: absolute;
  height: 100%;
  min-width: 80rem;
  width: 100%;
  will-change: transform;
}

.marker {
  position: absolute;
  transform: translateX(-50%);
  padding: 0.5rem;
  color: var(--font-secondary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  
  &:hover {
    color: var(--font-primary-color);
  }
}

.property-label {
  height: calc(v-bind(rowHeight) * 1px);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: var(--font-secondary-color);
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    color: var(--font-primary-color);
    background: var(--bg-hover-color);
  }
}

</style> 