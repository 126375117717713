import { StudioStore } from '../stores/StudioStore';
import { Vec2, Vec3 } from 'curtainsjs';
import { generateUUID } from '../scripts/Helpers.js';
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../scripts/StateEffect.js';

function array_move(arr, old_index, new_index) {
  new_index = Math.max(new_index, 0); // Ensure the new index is not negative
  new_index = Math.min(new_index, arr.length - 1); // Ensure the new index does not exceed array length - 1

  const item = arr.splice(old_index, 1)[0]; // Remove the item from the old position
  arr.splice(new_index, 0, item); // Insert the item at the new position

  return arr;
}

function deserializeNestedArray(obj) {
  if (!obj) return [];
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj && typeof obj === 'string') {
    obj = JSON.parse(obj);
  }
  return Object.values(obj);
}

function unpackBreakpoints(bps) {
  bps.forEach(breakpoint => {
    for (let prop in breakpoint.props) {
      if (breakpoint.props[prop]) {
        if (breakpoint.props[prop]?.type === 'Vec2') {
          breakpoint.props[prop] = new Vec2(breakpoint.props[prop]._x, breakpoint.props[prop]._y);
        } else if (breakpoint.props[prop]?.type === 'Vec3') {
          breakpoint.props[prop] = new Vec3(
            breakpoint.props[prop]._x,
            breakpoint.props[prop]._y,
            breakpoint.props[prop]._z
          );
        } else if (typeof breakpoint.props[prop] === 'object') {
          breakpoint.props[prop] = deserializeNestedArray(breakpoint.props[prop]);
        }
      }
    }
  });
  return bps;
}

function refreshPlanes() {
  StudioStore.refreshPlanes(
    () => {
      if(StudioStore.state.curtain) {
        StudioStore.state.curtain.resize();
        StudioStore.saveDesignState();
      }
    },
    null,
    true
  );
}

function addItem(item, index) {
  const addItem = () => {
    if (index !== undefined) {
      StudioStore.state.history.splice(index, 0, item);
    } else {
      StudioStore.state.history.push(item);
    }
    StudioStore.refreshPlanes(() => {
      if(StudioStore.state.curtain){
        StudioStore.state.curtain.resize();
      }
      StudioStore.saveDesignState();
      StudioStore.setSelectedItem(item);
      StudioStore.state.duplicating = false;
      item.initializeBreakpoints();
    });
  };

  addItem();
}

function deleteItem(item, swap) {
  const parent = item.parentLayer ? item.getParent() : null;
  if (StudioStore.isSelected(item)) {
    StudioStore.setSelectedItem('');
  }

  if (parent && !swap) {
    parent.effects = parent.effects.filter(n => n !== item.parentLayer);
    parent.resize(0.5);
  } else if (item.effects && item.effects.length) {
    item.getChildEffectItems().forEach(child => {
      StudioStore.state.history = StudioStore.state.history.filter(n => n.local.id !== child.local.id);
    });
  }

  if (item.usesPingPong && !swap) {
    StudioStore.state.curtain.planes
      .filter(n => n.type === 'PingPongPlane')
      .forEach(plane => {
        plane.remove();
        plane.renderer.removePlane(plane);
        plane = null;
      });
  }

  StudioStore.state.history = StudioStore.state.history.filter(n => n.local.id !== item.local.id);
}

export class CreateCommand {
  constructor(item, index) {
    this.item = item;
    this.index = index;
  }

  execute() {
    addItem(this.item, this.index);
  }

  unexecute() {
    deleteItem(this.item);
    refreshPlanes();
  }
}

export class ReplaceCommand {
  constructor(newItem, oldItem, index) {
    this.newItem = newItem;
    this.oldItem = oldItem;
    this.index = index;
  }

  execute() {
    addItem(this.newItem, this.index);
    deleteItem(this.oldItem, true);
    refreshPlanes();
  }

  unexecute() {
    addItem(this.oldItem, this.index);
    deleteItem(this.newItem, true);
    refreshPlanes();
  }
}

export class UpdateCommand {
  constructor(items) {
    // Expect items to be an array of {state, id}
    this.items = JSON.parse(JSON.stringify(items));
  }

  execute() {
    this.items.forEach(item => {
      const currentItemState = item.state;
      const currentItem = StudioStore.state.history.find(n => n.local.id === item.id);
      if (currentItem) {
        for (let prop in currentItemState) {
          if (prop === 'states') {
            if (currentItemState.states.appear.length) {
              currentItem.states.appear = currentItemState.states.appear.map(n => new StateEffectAppear(n));
            }
            if (currentItemState.states.scroll.length) {
              currentItem.states.scroll = currentItemState.states.scroll.map(n => new StateEffectScroll(n));
            }
            if (currentItemState.states.hover.length) {
              currentItem.states.hover = currentItemState.states.hover.map(n => new StateEffectHover(n));
            }
          } else if (currentItemState[prop] && currentItemState[prop].type) {
            if (currentItemState[prop].type === 'Vec2') {
              currentItem[prop] = new Vec2(currentItemState[prop]._x, currentItemState[prop]._y);
            } else if (currentItemState[prop].type === 'Vec3') {
              currentItem[prop] = new Vec3(
                currentItemState[prop]._x,
                currentItemState[prop]._y,
                currentItemState[prop]._z
              );
            }
          } else if (prop === 'breakpoints') {
            currentItemState[prop] = unpackBreakpoints(currentItemState[prop]);
          } else if (currentItemState[prop]) {
            currentItem[prop] = currentItemState[prop];
          }
        }
        this.finalizeItemUpdate(currentItem);
      }
    });

    this.finalizeUpdate();
  }

  unexecute() {
    // Since there's no changes array provided for unexecute in your original command,
    // this will simply reverse the changes made by execute by setting properties back to their original states.
    this.items.forEach(item => {
      const currentItemState = item.state;
      const currentItem = StudioStore.state.history.find(n => n.local.id === item.id);
      if (currentItem) {
        for (let prop in currentItemState) {
          if (prop === 'states') {
            if (currentItemState.states.appear.length) {
              currentItem.states.appear = currentItemState.states.appear.map(n => new StateEffectAppear(n));
            }
            if (currentItemState.states.scroll.length) {
              currentItem.states.scroll = currentItemState.states.scroll.map(n => new StateEffectScroll(n));
            }
            if (currentItemState.states.hover.length) {
              currentItem.states.hover = currentItemState.states.hover.map(n => new StateEffectHover(n));
            }
          } else if (currentItemState[prop] && currentItemState[prop].type) {
            if (currentItemState[prop].type === 'Vec2') {
              currentItem[prop] = new Vec2(currentItemState[prop]._x, currentItemState[prop]._y);
            } else if (currentItemState[prop].type === 'Vec3') {
              currentItem[prop] = new Vec3(
                currentItemState[prop]._x,
                currentItemState[prop]._y,
                currentItemState[prop]._z
              );
            }
          } else if (currentItemState[prop]) {
            currentItem[prop] = currentItemState[prop];
          }
        }
        this.finalizeItemUpdate(currentItem);
      }
    });

    this.finalizeUpdate();
  }

  finalizeItemUpdate(item) {
    if (item.isElement) {
      item.render();
    }
  }

  finalizeUpdate() {
    StudioStore.renderFrame();
    StudioStore.state.drawUIUpdater++;
  }
}

export class DeleteCommand {
  constructor(items) {
    this.items = StudioStore.state.selectedItems.length > 1 ? StudioStore.state.selectedItems : [items];
    this.itemData = this.items.map(item => ({
      item,
      index: item.getIndex(),
      parent: item.parentLayer ? item.getParent() : null,
      children: item.effects
        ? item.getChildEffectItems().map(n => ({
            item: n,
            index: n.getIndex(),
            effectIndex: item.effects.indexOf(n.parentLayer),
          }))
        : [],
      effectIndex: item.parentLayer ? item.getParent().effects.indexOf(item.parentLayer) : null,
    }));
  }

  execute() {
    this.itemData.forEach(({ item }) => {
      deleteItem(item);
    });
    refreshPlanes();
  }

  unexecute() {
    this.itemData.forEach(({ item, index, parent, effectIndex }) => {
      addItem(item, index);
      
      // Restore parent-child relationship if this was a child effect
      if (parent && effectIndex !== null && item.parentLayer) {
        if (!parent.effects.includes(item.parentLayer)) {
          if (effectIndex < parent.effects.length) {
            parent.effects.splice(effectIndex, 0, item.parentLayer);
          } else {
            parent.effects.push(item.parentLayer);
          }
        }
      }
    });
    refreshPlanes();
  }
}

export class ClearCommand {
  constructor(items) {
    this.items = items;
  }

  execute() {
    StudioStore.state.history
      .filter(n => !n.isBackground)
      .forEach((item, index) => {
        deleteItem(item);
      });
    refreshPlanes();
  }

  unexecute() {
    StudioStore.state.history = this.items;
    refreshPlanes();
  }
}

export class MoveCommand {
  constructor(newIndex, oldIndex, bottom, top) {
    this.newIndex = newIndex;
    this.oldIndex = oldIndex;
    this.bottom = bottom;
    this.top = top;

    // Store initial state needed for undo/redo
    const item = StudioStore.state.history[oldIndex];
    this.itemId = item.local.id;
    const originalParent = item.parentLayer ? item.getParent() : null;
    this.originalParentId = originalParent?.local.id || null;
    this.originalParentLayerId = item.parentLayer;
    this.originalEffectIndex = originalParent ? originalParent.effects.indexOf(item.parentLayer) : -1;

    // Store information about the destination at the time of command creation
    const destinationItem = StudioStore.state.history[newIndex];
    this.destinationId = destinationItem?.local.id;

    // We'll determine the exact move type during execute
    this.moveType = null;
    this.newParentId = null;
    this.newParentLayerId = null;
    this.executedHistoryMove = false;
  }

  findItem(id) {
    return StudioStore.state.history.find(n => n.local.id === id);
  }

  findParent(parentId) {
    return StudioStore.state.history.find(n => n.local.id === parentId);
  }

  execute() {
    const item = this.findItem(this.itemId);
    const destinationAtIndex = StudioStore.state.history[this.newIndex];

    if (!item) {
        console.error("MoveCommand execute: Item not found");
        return;
    }

    const currentOldIndex = StudioStore.state.history.indexOf(item);
    if (currentOldIndex === -1) {
        console.error("MoveCommand execute: Item index lookup failed");
        return;
    }

    const itemIsEffect = item.layerType === 'effect';
    const originalParent = this.originalParentId ? this.findParent(this.originalParentId) : null;

    let targetParent = null;
    let targetEffectIndex = -1;

    if (itemIsEffect && destinationAtIndex?.isElement && !this.top && !this.bottom) {
        targetParent = destinationAtIndex;
        targetEffectIndex = targetParent.effects.length;
    }
    else if (itemIsEffect && destinationAtIndex?.parentLayer && !this.top && !this.bottom) {
        targetParent = this.findParent(destinationAtIndex.getParent()?.local.id);
        if (targetParent) {
            const destEffectIdx = targetParent.effects.indexOf(destinationAtIndex.parentLayer);
            if (destEffectIdx !== -1) {
                targetEffectIndex = this.newIndex > currentOldIndex ? destEffectIdx + 1 : destEffectIdx;
            } else {
                 targetEffectIndex = targetParent.effects.length;
            }
        }
    }
    else if (itemIsEffect && originalParent && destinationAtIndex?.parentLayer && destinationAtIndex.getParent()?.local.id === this.originalParentId) {
         targetParent = originalParent;
         const destEffectIdx = targetParent.effects.indexOf(destinationAtIndex.parentLayer);
         if (destEffectIdx !== -1) {
            let finalDestIndex = destEffectIdx;
            const currentEffectIndex = originalParent.effects.indexOf(this.originalParentLayerId);

            if (this.top && this.newIndex < currentOldIndex) finalDestIndex = Math.max(0, destEffectIdx);
            else if (this.bottom && this.newIndex > currentOldIndex) finalDestIndex = destEffectIdx + 1;
            else if (this.newIndex > currentOldIndex) finalDestIndex = destEffectIdx + 1;
            else finalDestIndex = destEffectIdx;

            if (currentEffectIndex !== -1 && finalDestIndex > currentEffectIndex) {
                finalDestIndex--;
            }

            targetEffectIndex = finalDestIndex;

         } else {
            targetEffectIndex = -1;
         }
    }

    if (targetParent && (!originalParent || originalParent.local.id !== targetParent.local.id)) {
        this.moveType = 'TO_CHILD';
        this.newParentId = targetParent.local.id;

        if (originalParent && this.originalParentLayerId) {
            originalParent.effects = originalParent.effects.filter(id => id !== this.originalParentLayerId);
        }

        this.newParentLayerId = this.originalParentLayerId || generateUUID();
        item.parentLayer = this.newParentLayerId;

        if (targetEffectIndex !== -1 && targetEffectIndex <= targetParent.effects.length) {
             targetParent.effects.splice(targetEffectIndex, 0, this.newParentLayerId);
        } else {
             targetParent.effects.push(this.newParentLayerId);
        }

    } else if (!targetParent && originalParent) {
        this.moveType = 'TO_TOP_LEVEL';
        originalParent.effects = originalParent.effects.filter(id => id !== this.originalParentLayerId);
        item.parentLayer = null;
        this.newParentId = null;
        this.newParentLayerId = null;

    } else if (targetParent && originalParent && originalParent.local.id === targetParent.local.id) {
        this.moveType = 'REORDER_CHILD';
        this.newParentId = originalParent.local.id;
        this.newParentLayerId = this.originalParentLayerId;

        const currentEffectIndex = originalParent.effects.indexOf(this.originalParentLayerId);

        if (currentEffectIndex !== -1 && targetEffectIndex !== -1 && targetEffectIndex <= originalParent.effects.length) {
            originalParent.effects = array_move(originalParent.effects, currentEffectIndex, targetEffectIndex);
        } else {
            console.warn("MoveCommand execute: Could not determine valid effect indices for reorder.");
            this.moveType = null;
        }

    } else {
        this.moveType = 'REORDER_TOP_LEVEL';
        this.newParentId = null;
        this.newParentLayerId = null;
    }

    if (this.moveType !== 'REORDER_CHILD' && this.moveType !== null) {
       const currentIndexBeforeMove = StudioStore.state.history.indexOf(item);
       if (currentIndexBeforeMove !== -1) {
           StudioStore.state.history = array_move(StudioStore.state.history, currentIndexBeforeMove, this.newIndex);
           this.executedHistoryMove = true;
       } else {
            console.error("MoveCommand execute: Item lost before history move.");
            this.moveType = null;
       }
    } else {
        this.executedHistoryMove = false;
    }

    if (this.moveType) {
        refreshPlanes();
    }
  }

  unexecute() {
    const item = this.findItem(this.itemId);
    if (!item || !this.moveType) {
        console.error("MoveCommand unexecute: Item not found or moveType invalid.");
        return;
    }

    const originalParent = this.originalParentId ? this.findParent(this.originalParentId) : null;
    const newParent = this.newParentId ? this.findParent(this.newParentId) : null;

    if (this.executedHistoryMove) {
        const currentIndex = StudioStore.state.history.indexOf(item);
        if (currentIndex !== -1) {
            StudioStore.state.history = array_move(StudioStore.state.history, currentIndex, this.oldIndex);
        } else {
             console.error("MoveCommand unexecute: Item lost before history move reversal.");
             return;
        }
    }

    switch (this.moveType) {
        case 'TO_CHILD':
            if (newParent && this.newParentLayerId) {
                newParent.effects = newParent.effects.filter(id => id !== this.newParentLayerId);
            } else if (!newParent && this.newParentId) {
                 console.warn("MoveCommand unexecute: 'newParent' not found during TO_CHILD reversal.");
            }

            if (originalParent && this.originalParentLayerId && this.originalEffectIndex !== -1) {
                item.parentLayer = this.originalParentLayerId;
                const insertIndex = Math.min(this.originalEffectIndex, originalParent.effects.length);
                originalParent.effects.splice(insertIndex, 0, this.originalParentLayerId);
            } else if (!this.originalParentId) {
                 item.parentLayer = null;
            } else {
                 console.error("MoveCommand unexecute: Cannot restore item to original parent during TO_CHILD reversal.");
                 item.parentLayer = null;
            }
            break;

        case 'TO_TOP_LEVEL':
            item.parentLayer = this.originalParentLayerId;
            if (originalParent && this.originalParentLayerId && this.originalEffectIndex !== -1) {
                 const insertIndex = Math.min(this.originalEffectIndex, originalParent.effects.length);
                 originalParent.effects.splice(insertIndex, 0, this.originalParentLayerId);
            } else {
                console.error("MoveCommand unexecute: Cannot restore item to original parent during TO_TOP_LEVEL reversal.");
                item.parentLayer = null;
            }
            break;

        case 'REORDER_CHILD':
            if (originalParent && this.originalParentLayerId) {
                const currentEffectIndex = originalParent.effects.indexOf(this.originalParentLayerId);
                if (currentEffectIndex !== -1 && this.originalEffectIndex !== -1 && this.originalEffectIndex <= originalParent.effects.length) {
                    originalParent.effects = array_move(originalParent.effects, currentEffectIndex, this.originalEffectIndex);
                } else {
                     console.warn("MoveCommand unexecute: Could not determine valid effect indices for REORDER_CHILD reversal.");
                }
            } else {
                 console.error("MoveCommand unexecute: Original parent not found for REORDER_CHILD reversal.");
            }
            break;

        case 'REORDER_TOP_LEVEL':
            break;
    }

    refreshPlanes();
  }
}

export class MaskCommand {
  constructor(newValue, oldValue, item) {
    this.newValue = newValue;
    this.oldValue = oldValue;
    this.item = item;
  }

  execute() {
    this.item.mask = this.newValue;
    refreshPlanes();
  }

  unexecute() {
    this.item.mask = this.oldValue;
    refreshPlanes();
  }
}

export class VisibilityToggleCommand {
  constructor(item, newVal, oldVal) {
    this.item = item;
    this.newVal = newVal;
    this.oldVal = oldVal;
  }

  handlePlanes() {
    const item = this.item;

    if (!item.isBackground) {
      item.getChildEffectItems().forEach(effect => {
        effect.getPlanes().forEach(plane => {
          plane.visible = item.visible;
        });
      });

      item.getPlanes().forEach(plane => {
        plane.visible = item.visible;
      });
    }
  }

  execute() {
    if(this.item.isBackground) {
      this.item.opacity = this?.newVal ? 1 : 0;
      const plane = this.item.getPlane();
      if(plane && plane.uniforms.opacity) {
        plane.uniforms.opacity.value = this.item.opacity;
      }
   
    } else {
      this.item.visible = this.newVal;
      this.handlePlanes();
      refreshPlanes();
    }
    StudioStore.save();
  }

  unexecute() {
    if(this.item.isBackground) {
      this.item.opacity = this?.oldVal ? 1 : 0;
      const plane = this.item.getPlane();
      if(plane && plane.uniforms.opacity) {
        plane.uniforms.opacity.value = this.item.opacity;
      }

    } else {
      this.item.visible = this.oldVal;
      this.handlePlanes();
      refreshPlanes();
    }
    StudioStore.save();
  }
}

export class AddStateEffectCommand {
  constructor(effect, newStateEffect) {
    this.effect = effect;
    this.newStateEffect = newStateEffect;
  }

  execute() {
    this.effect.states[this.newStateEffect.type].push(this.newStateEffect);
    StudioStore.save();
  }

  unexecute() {
    this.effect.states[this.newStateEffect.type] = this.effect.states[this.newStateEffect.type].filter(
      effect => effect.id !== this.newStateEffect.id
    );
    StudioStore.save();
  }
}

export class RemoveStateEffectCommand {
  constructor(item, stateEffectId) {
    this.item = item;
    this.stateEffectId = stateEffectId;
    this.stateEffect = [...item.states.appear, ...item.states.scroll, ...item.states.hover].find(
      effect => effect.id === stateEffectId
    );
  }

  execute() {
    if (!this.stateEffect || !this.stateEffect.type) {
      console.error('State effect not found or invalid:', this.stateEffectId);
      return;
    }

    this.item.states[this.stateEffect.type] = this.item.states[this.stateEffect.type].filter(
      effect => effect.id !== this.stateEffectId
    );
    
    if(this.item.local.stateEffectProps && this.item.local.stateEffectProps.hasOwnProperty(this.stateEffect.prop)) {
      delete this.item.local.stateEffectProps[this.stateEffect.prop];
    }
    StudioStore.save();
  }

  unexecute() {
    if (!this.stateEffect || !this.stateEffect.type) {
      console.error('State effect not found or invalid:', this.stateEffectId);
      return;
    }

    this.item.states[this.stateEffect.type].push(this.stateEffect);
    StudioStore.save();
  }
}

export class ResetPropCommand {
  constructor(effect, prop, oldValue, newValue) {
    this.effect = effect;
    this.prop = prop;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }

  execute() {
    this.effect[this.prop] = this.newValue;
    this.effect.updateUniforms();
    StudioStore.save();
  }

  unexecute() {
    this.effect[this.prop] = this.oldValue;
    this.effect.updateUniforms();
    StudioStore.save();
  }
}
