<script>
import ClassicToggle from './ClassicToggle.vue';
import InputField from './InputField.vue';
import Button from './Button.vue';
import Icon from './Icon.vue';
import Input from './Input.vue';
import UsernameEditor from './UsernameEditor.vue';
import { UserStore } from '../stores/UserStore.js';
import { DesignsStore } from '../stores/DesignsStore.js';
import { FirebaseStore } from '../stores/FirebaseStore.js';

export default {
  components: {
    ClassicToggle,
    InputField,
    Button,
    Icon,
    Input,
    UsernameEditor
  },
  props: {
    design: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showShareDropdown: false,
      showRemixUrlCopied: false,
      isCreatingRemix: false,
      isLoadingRemix: false,
      remixId: null,
      UserStore,
      DesignsStore,
      description: '',
      remix: null
    }
  },
  watch: {
    design: {
      deep: true,
      handler(newVal) {
        if (!this.remixId && this.design?.remixEnabled) {
          this.fetchRemixData();
        }
      }
    }
  },
  mounted() {
    // Add click outside listener when component is mounted
    document.addEventListener('click', this.handleClickOutside);    
    // If remixing is enabled, fetch the remix data
    if (this.design?.remixEnabled) {
      this.fetchRemixData();
    }
  },
  beforeUnmount() {
    // Remove listener when component is destroyed
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    hasCompletedProfile() {
      return UserStore.username;
    }
  },
  methods: {
    async fetchRemixData() {
      if (!this.design.id) return;
      
      this.isLoadingRemix = true;
      try {
        const remixDoc = await DesignsStore.getRemixByDesignId(this.design.id);
        if (remixDoc && remixDoc.exists()) {
          this.remixId = remixDoc.id;
          this.remix = remixDoc.data();
          if (this.remix && this.remix.description) {
            this.description = this.remix.description;
          }
        }
      } catch (error) {
        console.error('Error fetching remix data:', error);
      } finally {
        this.isLoadingRemix = false;
      }
    },
    getRemixUrl() {
      if (this.remixId) {
        return `https://unicorn.studio/remix/${this.remixId}`;
      }
      return 'Loading remix link...';
    },
    copyRemixUrl() {
      if (!this.remixId) return;
      
      navigator.clipboard.writeText(this.getRemixUrl());
      this.showRemixUrlCopied = true;
      setTimeout(() => {
        this.showRemixUrlCopied = false;
      }, 2000);
    },
    handleClickOutside(event) {
      // Check if click was outside the share controls
      const shareControls = this.$el;
      if (!shareControls.contains(event.target) && this.showShareDropdown) {
        this.showShareDropdown = false;
      }
    },
    async toggleRemixEnabled() {
      if (!this.design?.remixEnabled) {
        // Enable remixing - create a remix record
        this.isCreatingRemix = true;
        try {
          const remix = await DesignsStore.createRemix(this.design);
          if (remix) {
            this.remixId = remix.id;
            this.remix = remix;
            this.$emit('update:remixEnabled', true);
          }
        } catch (error) {
          console.error('Error creating remix:', error);
        } finally {
          this.isCreatingRemix = false;
        }
      } else {
        // Disable remixing - delete the remix record and update the flag
        try {
          if (this.remixId) {
            await DesignsStore.deleteRemix(this.remixId);
          }
          this.$emit('update:remixEnabled', false);
          this.remixId = null;
          this.description = ''; // Clear description when disabling remix
        } catch (error) {
          console.error('Error deleting remix:', error);
        }
      }
    },
    async updateRemixDescription() {
      if (!this.remixId) return;
      
      try {
        await DesignsStore.saveRemix(this.remixId, { description: this.description });
      } catch (error) {
        console.error('Error updating remix description:', error);
      }
    },
    updateRemixUsername(username) {
      if (!this.remixId) return;
      
      DesignsStore.saveRemix(this.remixId, { username: username });
    },
    updateRemixDiscoverability(value) {
      if (!this.remix) return;
      this.remix.discoverable = !this.remix.discoverable;
      
      DesignsStore.saveRemix(this.remixId, { discoverable: this.remix.discoverable });
    }
  }
}
</script>

<template>
  <div class="">
    <Button class="secondary mr-2 small" @click="showShareDropdown = true">Share</Button>
    <div v-if="showShareDropdown" class="modal modal__pop-in modal__share" @click.stop>
      <div class="flex align-center justify-between w-100">
        <label class="parameter-label">Allow remixing</label>
        <ClassicToggle 
          :value="design?.remixEnabled"
          style="width: 3.7rem;"
          :disabled="isCreatingRemix"
          @change="toggleRemixEnabled"
        />
      </div>
      <p class="p mt-1 font-secondary-color w-75">Allows anyone with the link to make copies of this project.</p>
      <div v-if="design?.remixEnabled">
        <div class="flex w-full mt-3">
          <InputField
            :value="getRemixUrl()"
            disabled="true"
            output="string"
            class="w-100"
            :loading="isLoadingRemix"
          />
          <Button 
            class="secondary small font-secondary-color ml-2 icon icon-auto-width" 
            @click="copyRemixUrl"
            :disabled="!remixId || isLoadingRemix"
          >
            <Icon size="14" style="color: var(--font-secondary-color)" :icon="showRemixUrlCopied ? 'checkSimple' : 'copy'" />
          </Button>
        </div>
        <div class="mt-2">
          <textarea class="input-field input-field__textarea w-100" 
            v-model="description"
            placeholder="Description of project"
            maxlength="200"
            @input="updateRemixDescription"
          ></textarea>
          <div class="character-count">
            {{ description.length }}/200
          </div>
        </div>
        <hr class="my-3" />
        <div class="flex align-center justify-between w-100">
          <label class="parameter-label">Make discoverable</label>
          <ClassicToggle 
            :value="remix ? (remix.discoverable || false) : false"
            style="width: 3.7rem;"
            @change="updateRemixDiscoverability"
          />
        </div>
        <p class="p mt-1 mb-1 font-secondary-color">By making a remix discoverable, it can be featured in the community gallery and remixed by others for personal or commercial use. If featured, your profile name and username will be displayed.</p>
        
        <div class="mt-3" v-if="!UserStore.username">
          <label class="parameter-label">Username</label>
          <UsernameEditor
            @update:username="updateRemixUsername"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal__share {
  right: 0rem;
  top: 5.4rem;
  left: unset;
}

.w-full {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.profile-completion-module {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

h4 {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
}

.username-message {
  margin-bottom: 0;
  
  &.success {
    color: var(--success-color, #4CAF50);
  }
  
  &.error {
    color: var(--error-color, #F44336);
  }
  
  &.loading {
    color: var(--font-secondary-color);
  }
}

.character-count {
  //text-align: right;
  font-size: 1rem;
  color: var(--font-secondary-color);
  margin-top: 0.25rem;
}
</style> 