import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  EASE,
  mixRadiusProperties,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const diffuseShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;

uniform float uAmount;
uniform float uTime;
uniform float xy;
uniform float uDirection;
uniform float uGrain;
uniform float uMixRadius;
uniform int uMixRadiusInvert;
uniform int uEasing;
uniform vec2 uPos;
${UNIVERSAL_UNIFORMS}
${EASE}

const float MAX_ITERATIONS = 24.;
const float PI = 3.14159265;
const float TWOPI = 6.2831853;


float random(vec2 p) {
  p = fract(p * vec2(123.45, 678.90));
  p += dot(p, p + 45.32);
  return fract(p.x * p.y);
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  float aspectRatio = uResolution.x/uResolution.y;
  float delta = fract(floor(uTime)/20.);
  float angle, rotation, amp;
  float inner = distance(uv * vec2(aspectRatio, 1), pos * vec2(aspectRatio, 1));
  float outer = max(0., 1.-distance(uv * vec2(aspectRatio, 1), pos * vec2(aspectRatio, 1)));
  float amount = uAmount * 2.;

  vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  pos = uMixRadius == 1.00 ? mPos : uPos;
  float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

  // #ifelseopen
  if (uMixRadiusInvert == 1) {
      dist = max(0., (0.5 - dist));
  }
  // #ifelseclose

  amount *= dist;

  vec4 col;
  
  if(amount <= 0.001) {
    col = texture(uTexture, uv);
  } else {
    vec4 result = vec4(0);
    float threshold = max(1. - uGrain, 2./MAX_ITERATIONS);
    const float invMaxIterations = 1.0 / float(MAX_ITERATIONS);

    vec2 dir = vec2(uDirection / aspectRatio, 1.-uDirection) * amount * 0.4;

    // Fixed iteration count for better mobile compatibility
    float iterations = 0.0;
    for(float i = 1.; i <= MAX_ITERATIONS; i++) {
      float th = i * invMaxIterations;
      if(th > threshold) break;

      float random1 = random(uv + th + delta);
      float random2 = random(uv + th * 2. + delta);
      float random3 = random(uv + th * 3. + delta);
      
      vec2 ranPoint = vec2(random1 * 2. - 1., random2 * 2. - 1.) * mix(1., random3, 0.8);
        
      result += texture(uTexture, uv + ranPoint * dir);
      iterations += 1.0;
    }

    result /= max(1.0, iterations);
    col = result;
  }

  ${computeFragColor('col')}
}

`;

const diffuseParams = {
  fragmentShader: diffuseShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    graininess: {
      name: 'uGrain',
      type: '1f',
      value: 0.5,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const DIFFUSE = {
  id: 'diffuse',
  label: 'Diffuse',
  params: diffuseParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Amount',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    graininess: {
      label: 'Grain',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    direction: {
      label: 'Direction',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
