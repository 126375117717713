import { rgbToHex } from './ColorHelpers';

export const randomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const formatDate = d => {
  if (d.seconds) {
    d = new Date(d.seconds * 1000);
  } else {
    d = new Date(d);
  }
  
  const now = new Date();
  const diffMs = now - d;
  const diffSec = Math.floor(diffMs / 1000);
  const diffMin = Math.floor(diffSec / 60);
  const diffHr = Math.floor(diffMin / 60);
  const diffDay = Math.floor(diffHr / 24);
  const diffWeek = Math.floor(diffDay / 7);
  const diffMonth = Math.floor(diffDay / 30);
  const diffYear = Math.floor(diffDay / 365);
  
  if (diffSec < 60) {
    return diffSec < 1 ? 'just now' : `${diffSec}s ago`;
  } else if (diffMin < 60) {
    return `${diffMin}${diffMin === 1 ? 'm' : 'm'} ago`;
  } else if (diffHr < 24) {
    return `${diffHr}${diffHr === 1 ? 'hr' : 'hrs'} ago`;
  } else if (diffDay < 7) {
    return `${diffDay}${diffDay === 1 ? ' day' : ' days'} ago`;
  } else if (diffWeek < 4) {
    return `${diffWeek}${diffWeek === 1 ? ' week' : ' weeks'} ago`;
  } else if (diffMonth < 12) {
    return `${diffMonth}${diffMonth === 1 ? ' month' : ' months'} ago`;
  } else {
    return `${diffYear}${diffYear === 1 ? ' year' : ' years'} ago`;
  }
};

export function itemCount(arr, item) {
  let count = 0;
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i] == item) count++;
  }
  return count;
}

export class Debouncer {
  static timeout;
  constructor(args) {
    this.interval = args.interval;
    this.fn = args.fn;
    this.args = args.args;
    this.immediate = args.immediate || false; // Add immediate property with default false
    this.called = false; // To track if the function has been called
  }

  clear() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  fire(args) {
    this.clear();
    if (this.immediate && !this.called) {
      args = args || [];
      this.fn(...args);
      this.called = true;
    } else {
      this.timeout = setTimeout(() => {
        if (!this.immediate) {
          args = args || [];
          this.fn(...args);
        }
        this.called = false;
      }, this.interval);
    }
  }
}

//https://www.telerik.com/blogs/debouncing-and-throttling-in-javascript
var timerId;
// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
// export const throttle  =  (func, delay, args) => {
// 	// If setTimeout is already scheduled, no need to do anything
// 	if (timerId) {
// 		return
// 	}

// 	// Schedule a setTimeout after delay seconds
// 	timerId  =  setTimeout(function () {
// 		func(args);

// 		// Once setTimeout function execution is finished, timerId = undefined so that in <br>
// 		// the next scroll event function execution can be scheduled by the setTimeout
// 		timerId  =  undefined;
// 	}, delay)
// }

// ES6 code
let lastCall = 0;
export const throttle = (fn, delay) => {
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
};

export class Throttler {
  static timeout;
  static lastCall = 0;

  constructor(args) {
    this.interval = args.interval;
    this.fn = args.fn;
    this.args = args.args || [];
  }

  clear() {
    if (Throttler.timeout) {
      clearTimeout(Throttler.timeout);
      Throttler.timeout = null;
    }
  }

  fire(args) {
    const now = Date.now();
    const elapsed = now - Throttler.lastCall;
    const doLater = () => {
      Throttler.lastCall = Date.now();
      this.fn.apply(this, args || this.args);
    };

    this.clear();

    if (elapsed > this.interval) {
      doLater();
    } else {
      Throttler.timeout = setTimeout(doLater, this.interval - elapsed);
    }
  }
}

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getPointDistToLine = (x, y, x1, y1, x2, y2) => {
  let a = x - x1;
  let b = y - y1;
  let c = x2 - x1;
  let d = y2 - y1;

  let lenSq = c * c + d * d;
  let param = lenSq !== 0 ? (a * c + b * d) / lenSq : -1;

  var xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * c;
    yy = y1 + param * d;
  }

  var dx = x - xx;
  var dy = y - yy;

  return Math.sqrt(dx * dx + dy * dy);
};

export function compressThumbnail(src, size, callback, isVideo = false) {
  const scaleWidth = size;

  const onLoadMedia = (mediaElement, isVideo) => {
    const oldWidth = mediaElement.videoWidth || mediaElement.width;
    const oldHeight = mediaElement.videoHeight || mediaElement.height;

    if (!oldWidth || !oldHeight) {
      console.error('Failed to get media dimensions');
      return callback(null, null);
    }

    const newHeight = Math.floor((oldHeight / oldWidth) * scaleWidth);
    const thumbCanvas = document.createElement('canvas');
    thumbCanvas.width = scaleWidth;
    thumbCanvas.height = newHeight;

    const thumbCtx = thumbCanvas.getContext('2d');
    thumbCtx.drawImage(mediaElement, 0, 0, scaleWidth, newHeight);

    return callback(thumbCanvas.toDataURL('image/webp'), thumbCanvas.width / thumbCanvas.height);
  };

  if (isVideo) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';

    video.addEventListener(
      'loadeddata',
      () => {
        onLoadMedia(video, true);
      },
      false
    );

    video.addEventListener('error', e => {
      console.error('Failed to load video', e);
      callback(null, null);
    });

    video.src = src;
  } else {
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = () => {
      onLoadMedia(image, false);
    };

    image.onerror = e => {
      console.error('Failed to load image', e);
      callback(null, null);
    };

    image.src = src;
  }
}

export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export function deserializeNestedArray(obj) {
  if (!obj) return [];
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj && typeof obj === 'string') {
    obj = JSON.parse(obj);
  }
  return Object.values(obj);
}

export function serializeNestedArray(arr, skip) {
  if (skip) {
    return arr;
  }
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    obj[i] = arr[i];
  }
  return obj;
}

export function formatStateEffectValue(value, prop, params) {
  let output = params?.properties[prop]?.output;
  if (value === undefined) return '';
  
  if (value.type === 'Vec2') {
    return `${Math.round(value.x * 100)}%, ${Math.round(value.y * 100)}%`;
  }
  
  if (value.type === 'Vec3') {
    if (params?.properties[prop]?.output === 'color') {
      // Convert Vec3 color values (assumed to be in 0-1 range) to RGB hex
      const r = Math.round(value.x * 255);
      const g = Math.round(value.y * 255);
      const b = Math.round(value.z * 255);
      return rgbToHex(r, g, b);
    }
    return `${Math.round(value.x * 100)}%, ${Math.round(value.y * 100)}%, ${Math.round(value.z * 100)}%`;
  }

  if(output === 'px') {
    return `${Math.round(value)}px`;
  }
  
  return `${Math.round(value * 100)}%`;
}
