import {
  vertexShaderNoMatrix,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  BLEND,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';
import { BLEND_MODES } from '../../scripts/Constants.js';

const fragmentShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uWeight;
  uniform float uScale;
  uniform float uAngle;
  uniform int uPattern;
  uniform vec2 uPos;
  uniform vec3 uStroke;
  uniform int uBlendMode;
  uniform float uTime;
  ${UNIVERSAL_UNIFORMS}
  ${BLEND}

  mat2 rotate2d(float _angle){
    return mat2(cos(_angle),-sin(_angle),
                sin(_angle),cos(_angle));
  }

  float grid(vec2 st, float tile) {
    float result = 0.;
    result = fract(st.x) < tile ? 1. : 0.;
    if(result == 0.) {
      result += fract(st.y) < tile ? 1. : 0.;
    }
    return result;
  }

  float stripe(vec2 st, float tile) {
    return fract(st.x) < tile ? 1. : 0.;
  }

  float arrows(vec2 st, float tile) {
    float s = fract(st.y) < mod(floor(st.x + st.y), 2.) ? st.x : st.y;
    return mod(floor(s), 2.);
  }

  float concentric_circle( vec2 st, float tile ) {
      return fract(length(st) - tile/2.) < tile ? 1. : 0.;
  }

  float circle(vec2 st, float tile) {
    vec2 gridPos = floor(st);
    vec2 cellPos = fract(st);
    return (length(cellPos - 0.5) < tile * 0.5) ? 1. : 0.;
  }

  float checkerboard(vec2 st, float tile){
    vec2 pos = floor(st); 
    return mod(pos.x+pos.y,2.0);
  }

  float wavy_lines(vec2 st, float tile) {
    float value = sin(st.x * 3.1415926 * 2.0 + st.y * 10.0) * 0.5 + 0.5;
    return value < tile ? 1. : 0.;
  }

  float hexagonal_pattern(vec2 st, float tile) {
    const float sqrt3 = 1.7320508;
    vec2 hexSize = vec2(1.0, sqrt3) * tile;
    vec2 grid = fract(st / hexSize);
    vec3 grid3 = vec3(grid, grid.y * 0.5);
    vec3 corner = round(grid3);
    vec3 v1 = abs(grid3 - corner);
    vec3 v2 = abs(grid3 + vec3(-0.5, sqrt3 * 0.5, 0.0) - corner);
    vec3 v3 = abs(grid3 + vec3(0.5, sqrt3 * 0.5, 0.0) - corner);
    vec3 dist = min(min(v1, v2), v3);
    return (dist.x + dist.y) < tile * 0.5 ? 1. : 0.;
  }

  float diamond_pattern(vec2 st, float tile) {
    vec2 diamond_coord = abs(st * 2.0);
    return (diamond_coord.x + diamond_coord.y) < tile ? 1. : 0.;
  }

  float spiral_pattern(vec2 st, float tile) {
    float r = length(st);
    float theta = atan(st.y, st.x);
    float spiral = mod(theta + r * 5.0, 3.1415926 * 2.0) - 3.1415926;
    return abs(spiral) < tile ? 1. : 0.;
  }

  float getPattern(vec2 st, float tile) {
    st.y -= uTime * 0.05;
    switch(uPattern) {
      case 0: return grid(st, tile); break;
      case 1: return stripe(st, tile); break;
      case 2: return circle(st, tile); break;
      case 3: return concentric_circle(st, tile); break;
      case 4: return arrows(st, tile); break;
      case 5: return checkerboard(st, tile); break;
      case 6: return wavy_lines(st, tile); break;
      case 7: return hexagonal_pattern(st, tile); break;
      case 8: return diamond_pattern(st, tile); break;
      case 9: return spiral_pattern(st, tile); break;
      default: return grid(st, tile);
    }
  }

  float supersamplePattern(vec2 st, float tile, float pixelSize) {
    float offset = pixelSize * 0.33; // One-third pixel offset
    float sum = 0.0;
    
    // Sample in a 3x3 grid
    for(float y = -1.0; y <= 1.0; y += 1.0) {
      for(float x = -1.0; x <= 1.0; x += 1.0) {
        sum += getPattern(st + vec2(x * offset, y * offset), tile);
      }
    }
    
    return sum / 9.0; 
  }

  float smoothPattern(vec2 st, float tile, float pixelSize) {
    float pattern = getPattern(st, tile);
    
    float neighbors = 0.0;
    neighbors += getPattern(st + vec2(pixelSize, 0.0), tile);
    neighbors += getPattern(st + vec2(-pixelSize, 0.0), tile);
    neighbors += getPattern(st + vec2(0.0, pixelSize), tile);
    neighbors += getPattern(st + vec2(0.0, -pixelSize), tile);
    
    if(abs(4.0 * pattern - neighbors) > 0.1) {
      return supersamplePattern(st, tile, pixelSize * 0.5);
    }
    return pattern;
  }

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    vec4 bg = texture(uTexture, uv);

    if(bg.a == 0.) {
      fragColor = vec4(0);
      return;
    }
    
    vec4 color = vec4(uStroke,1.);
    float aspectRatio = uResolution.x/uResolution.y;
    float res = max(uResolution.x, uResolution.y);
    float px = (1./res);
    float py = px / aspectRatio;
    float scl = (40. * uScale);
    float minpx = min(px, py);
    float tile = (minpx + uWeight/scl)*scl;
    tile = round(tile / minpx) * minpx;

    vec2 st = (uv - uPos) * scl * vec2(aspectRatio, 1);
    st = st * rotate2d(uAngle * 360. * 3.1415926 / 180.);

    // Use improved anti-aliasing with adaptive smoothing
    float pixelSize = 1.0 / res;
    // Use adaptive sampling - more samples near edges
    float pattern = smoothPattern(st, tile, pixelSize);

    color *= pattern;

    // #ifelseopen
    if(uBlendMode > 0) {
      color.rgb = blend(uBlendMode, color.rgb, bg.rgb);
    }
    // #ifelseclose
    
    fragColor = mix(bg, color, color.a);
  }
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShaderNoMatrix,
  crossorigin: 'Anonymous',
  depthTest: false,
  hidden: true,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    weight: {
      name: 'uWeight',
      type: '1f',
      value: 0,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0,
    },
    stroke: {
      name: 'uStroke',
      type: '3f',
      value: new Vec3(0.5),
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    pattern: {
      name: 'uPattern',
      type: '1i',
      value: 0,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const PATTERN = {
  id: 'pattern',
  label: 'Pattern',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pattern: {
      label: 'Pattern',
      value: 0,
      options: {
        0: 'Grid',
        5: 'Checkerboad',
        1: 'Stripes',
        2: 'Dots',
        3: 'Circles',
        4: 'Arrows',
        5: 'Checkerboard',
        9: 'Spiral',
      },
      responsiveDisabled: true,
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    weight: {
      label: 'Weight',
      value: 0.1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.001,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    stroke: {
      label: 'Fill',
      value: new Vec3(0.98, 0.12, 0.89),
      output: 'color',
      responsiveDisabled: true,
    },
    blendMode: {
      label: 'Blend mode',
      value: 'NORMAL',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
