<script>
import Checkbox from './Checkbox.vue'

export default {
  components: {
    Checkbox
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedValues: this.modelValue
    }
  },
  watch: {
    modelValue() {
      this.selectedValues = this.modelValue
    }
  },
  methods: {
    toggleOption(value) {
      const index = this.selectedValues.indexOf(value)
      if (index === -1) {
        this.selectedValues.push(value)
      } else {
        this.selectedValues.splice(index, 1)
      }
      this.$emit('update:modelValue', this.selectedValues)
    },
    isSelected(value) {
      return this.selectedValues.includes(value)
    }
  }
}
</script>

<template>
  <div class="checkbox-group w-100">
    <label class="checkbox-group__label" v-if="label">{{ label }}</label>
    <div class="checkbox-group__options">
      <div 
        v-for="option in options" 
        :key="option.value" 
        class="checkbox-group__option"
      >
        <Checkbox 
          :model-value="isSelected(option.value)"
          @update:model-value="toggleOption(option.value)"
        >
          {{ option.label }}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<style scoped>
.checkbox-group {
  display: flex;
  flex-direction: column;
  max-width: var(--param-width);
  gap: 0.5rem;
  background-color: var(--accent-color);
  border-radius: 0.4rem;
  padding: 0.7rem;
}

.checkbox-group__label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.checkbox-group__options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkbox-group__option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
