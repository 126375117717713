import { Layer } from './Layer.js';
import { optimizeShaders } from '../ShaderOptimize';
import { basicParams, basicNoDispNoBlendParams } from '../BasicShader.js';
import { deserializeNestedArray, serializeNestedArray } from '../../scripts/Helpers';
import { Vec2, Vec3 } from 'curtainsjs';
import { StudioStore } from '../../stores/StudioStore.js';
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../../scripts/StateEffect';

function getAspectRatio(dims, scale) {
  const ratio = dims[0] / dims[1];
  const width = Math.sqrt(ratio * (300000 * (scale || 1)));
  return [width, width / ratio];
}

export function getAnchorOffsets(anchorPoint) {
  const offsets = {
    topLeft: { x: 0, y: 0 },
    topCenter: { x: 0.5, y: 0 },
    topRight: { x: 1, y: 0 },
    rightCenter: { x: 1, y: 0.5 },
    bottomRight: { x: 1, y: 1 },
    bottomCenter: { x: 0.5, y: 1 },
    bottomLeft: { x: 0, y: 1 },
    leftCenter: { x: 0, y: 0.5 },
    center: { x: 0.5, y: 0.5 },
  };
  return offsets[anchorPoint] || offsets.topLeft;
}

export class Element extends Layer {
  isElement = true;

  constructor(args, id) {
    super(args, id);
    this.opacity = args.opacity || 1;
    this.effects = args.effects || [];
    this.displace = args.displace || 0;
    this.trackMouse = args.trackMouse || 0;
    this.anchorPoint = args.anchorPoint || 'center';
    this.mouseMomentum = args.mouseMomentum || 0;
    this.blendMode = args.blendMode || 'NORMAL';
    this.bgDisplace = args.bgDisplace || 0;
    this.mask = args.mask || 0;
    this.maskBackground = args.maskBackground || new Vec3(0);
    this.maskAlpha = args.maskAlpha || 0;
    this.maskDepth = args.maskDepth || 0;
    this.dispersion = args.dispersion || 0;
    this.axisTilt = args.axisTilt || 0;
    this.states = {
      appear: args.states && args.states.appear ? args.states.appear.map(n => new StateEffectAppear(n, this.local.id)) : [],
      scroll: args.states && args.states.scroll ? args.states.scroll.map(n => new StateEffectScroll(n, this.local.id)) : [],
      hover: args.states && args.states.hover ? args.states.hover.map(n => new StateEffectHover(n, this.local.id)) : [],
    };

    if(args.translateX || args.translateY) {
      this.translateX = args.translateX || 0;
      this.translateY = args.translateY || 0;
    }
  }

  package(skipSerialization) {
    return {
      layerName: this.layerName,
      visible: this.visible,
      locked: this.locked,
      aspectRatio: this.aspectRatio,
      axisTilt: this.axisTilt,
      anchorPoint: this.anchorPoint,
      blendMode: this.blendMode,
      breakpoints: this.prepBreakpoints(this.breakpoints),
      dispersion: this.dispersion,
      bgDisplace: this.bgDisplace,
      displace: this.displace,
      effects: serializeNestedArray(this.effects, skipSerialization),
      mask: this.mask,
      maskAlpha: this.maskAlpha,
      maskDepth: this.maskDepth,
      maskBackground: {
        type: 'Vec3',
        _x: this.maskBackground._x,
        _y: this.maskBackground._y,
        _z: this.maskBackground._z,
      },
      left: this.left,
      top: this.top,
      leftMode: this.leftMode,
      topMode: this.topMode,
      opacity: this.opacity,
      rotation: this.rotation,
      trackMouse: this.trackMouse,
      mouseMomentum: this.mouseMomentum,
      states: {
        appear: this.states.appear ? this.states.appear.map(n => n.package()) : [],
        scroll: this.states.scroll ? this.states.scroll.map(n => n.package()) : [],
        hover: this.states.hover ? this.states.hover.map(n => n.package()) : [],
      },
      width: this.width,
      widthMode: this.widthMode,
      height: this.height,
      heightMode: this.heightMode,
      ...this.packageType(skipSerialization),
    };
  }

  unpackage() {
    this.effects = deserializeNestedArray(this.effects);
    this.maskBackground = new Vec3(this.maskBackground._x, this.maskBackground._y, this.maskBackground._z);

    this.unpackageType();
    return this;
  }

  getParams(args) {
    args = args || {};
    return {
      params: basicParams,
      properties: {
        width: {
          label: 'Width',
          value: args.width ?? 100,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
          mode: 'widthMode'
        },
        widthMode: {
          label: 'Width mode',
          value: args.widthMode || 'fixed',
          eventDisabled: true,
        },
        height: {
          label: 'Height',
          value: args.height ?? 100,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
          mode: 'heightMode'
        },
        heightMode: {
          label: 'Height mode',
          value: args.heightMode || 'fixed',
          eventDisabled: true,
        },
        left: {
          label: 'X Position',
          value: args.left ?? 0.5,
          output: 'percent',
          step: 0.01,
          min: 0,
          max: 1,
        },
        leftMode: {
          label: 'X mode',
          value: args.leftMode || 'relative',
          eventDisabled: true,
        },
        top: {
          label: 'Y Position',
          value: args.top ?? 0.5,
          output: 'percent',
          step: 0.01,
          min: 0,
          max: 1,
        },
        topMode: {
          label: 'Y mode',
          value: args.topMode || 'relative',
          eventDisabled: true,
        },
        dispersion: {
          label: 'Dispersion',
          value: args.dispersion || 0,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
        },
        rotation: {
          label: 'Rotation',
          value: args.rotation || args.angle || 0,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
        },
        displace: {
          label: 'Displace',
          value: args.displace || 0,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
        },
        opacity: {
          label: 'Opacity',
          value: args.opacity ?? 1,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
        },
        trackMouseMove: {
          label: 'Track mouse',
          header: 'Interactivity',
          value: args.trackMouse || 0,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
          tooltip: 'The amount to which the mouse cursor controls the position of the object',
        },
        mouseMomentum: {
          label: 'Momentum',
          value: args.mouseMomentum || 0,
          min: 0,
          max: 1,
          step: 0.01,
          output: 'percent',
          tooltip: 'The amount of drag or delay of the track mouse effect',
        },
        ...this.default(args),
      },
    };
  }

  getPixelRatio(scale) {
    const curtainResolution = StudioStore.state.currentSize.realDimensions[0] / StudioStore.state.canvasWidth;
    return Math.max(1.5, curtainResolution) * (scale || StudioStore.state.scale);
  }

  createLocalCanvas() {
    const canvas = document.createElement('canvas');
    const screenScale = this.getPixelRatio();

    canvas.width = StudioStore.state.canvasWidth * screenScale;
    canvas.height = StudioStore.state.canvasHeight * screenScale;
    const ctx = canvas.getContext('2d');
    ctx.scale(screenScale, screenScale);

    this.local.canvas = canvas;
    this.local.ctx = ctx;

    // if(StudioStore.state.curtain && StudioStore.state.textureLoader) {
    //   StudioStore.state.textureLoader.loadCanvas(this.local.canvas, {
    //     sampler: 'uTexture',
    //     premultiplyAlpha: true,
    //   }, tex => {
    //     this.local.preloadedCanvasTexture = tex;
    //   });
    // }
  }

  resize() {
    if (this.local.canvas) {
      const screenScale = this.getPixelRatio();
      this.local.canvas.width = StudioStore.state.canvasWidth * screenScale;
      this.local.canvas.height = StudioStore.state.canvasHeight * screenScale;

      this.local.ctx.scale(screenScale, screenScale);
    }
  }

  box() {
    const anchorOffsets = getAnchorOffsets(this.anchorPoint);

    const { width, height } = this.getAbsoluteDimensions();
    const { left, top } = this.getAbsolutePosition();
    const offsetX = width * (0.5 - anchorOffsets.x);
    const offsetY = height * (0.5 - anchorOffsets.y);

    return {
      anchor: {
        x: left + offsetX,
        y: top + offsetY,
      },
      center: {
        x: left + width / 2,
        y: top + height / 2,
      },
      left,
      top,
      width,
      height
    };
  }

  getAbsolutePosition() {
    const canvasWidth = StudioStore.state.canvasWidth;
    const canvasHeight = StudioStore.state.canvasHeight;

    let left = this.local?.stateEffectProps?.left ?? this.left;
    let top = this.local?.stateEffectProps?.top ?? this.top;

    // Round values to prevent floating point issues
    const absoluteX = this.leftMode === 'fixed' ? 
      Math.round(left) : 
      Math.round(left * canvasWidth);
    
    const absoluteY = this.topMode === 'fixed' ? 
      Math.round(top) : 
      Math.round(top * canvasHeight);

    return { left: absoluteX, top: absoluteY };
  }

  getAbsoluteDimensions() {
    let absoluteWidth, absoluteHeight;
    const canvasWidth = StudioStore.state.canvasWidth;
    const canvasHeight = StudioStore.state.canvasHeight;

    let width = this.local?.stateEffectProps?.width ?? this.width;
    let height = this.local?.stateEffectProps?.height ?? this.height;
    if (this.widthMode !== 'auto') {
      absoluteWidth = this.widthMode === 'fixed' ? width : width * canvasWidth;

      if (this.heightMode === 'auto') {
        absoluteHeight = absoluteWidth / (this.aspectRatio || 1);
        this.height = this.widthMode === 'fixed' ? absoluteHeight : absoluteHeight / canvasHeight;
      }
    }

    if (this.heightMode !== 'auto') {
      absoluteHeight = this.heightMode === 'fixed' ? height : height * canvasHeight;

      if (this.widthMode === 'auto') {
        absoluteWidth = absoluteHeight * (this.aspectRatio || 1);
        this.width = this.widthMode === 'fixed' ? absoluteWidth : absoluteWidth / canvasWidth;
      }
    }

    if(this.layerType === 'text') {
      absoluteHeight = this.height
    }

    return { width: absoluteWidth, height: absoluteHeight };
  }

  getPositionOffset(transX, transY, ww, hh) {
    let width = ww || StudioStore.state.canvasWidth;
    let height = hh || StudioStore.state.canvasHeight;
    const sizes = getAspectRatio([width, height], 1);
    const originalAspectRatio = this.aspectRatio || 1;
    const currentAspectRatio = width / height;
    const adjustedRatio = originalAspectRatio / currentAspectRatio;
    const originalWidth = sizes[0] * Math.sqrt(adjustedRatio);
    const originalHeight = sizes[1] / Math.sqrt(adjustedRatio);

    const translateX = transX || this.translateX || 0;
    const translateY = transY || this.translateY || 0;

    let offsetX = (sizes[0] - originalWidth) / 2;
    let offsetY = (sizes[1] - originalHeight) / 2;

    if (this.layerType === 'image') {
      offsetX += originalWidth / 2;
      offsetY += originalHeight / 2;
    }

    let newX = translateX + offsetX;
    let newY = translateY + offsetY;

    return { x: newX, y: newY, offX: offsetX, offY: offsetY };
  }

  getBackCompatData(width, height) {
    const aspect = (width || StudioStore.state.canvasWidth) / (height || StudioStore.state.canvasHeight);
    const bcWidth = Math.sqrt(aspect * 300000);
    const ratio = (width || StudioStore.state.canvasWidth)/bcWidth;
    return {
      bcWidth,
      bcHeight: bcWidth / aspect,
      ratio,
    }
  }

  packageShaders() {
    let params = basicParams;
    if (this.displace === 0 && this.blendMode === 'NORMAL' && !this.mask) {
      params = basicNoDispNoBlendParams;
    }

    this.breakpoints = this.breakpoints.filter(n => {
      if (!n.props || !Object.keys(n.props).length) return false;
      
      Object.keys(n.props).forEach(key => {
        const value = n.props[key];
        if (value === null || value === undefined || Number.isNaN(value)) {
          delete n.props[key];
        }
      });
      
      return Object.keys(n.props).length > 0;
    });

    this.effects = this.effects.filter(n =>
      StudioStore.state.history.filter(n => n.visible).find(o => o.parentLayer === n)
    );

    optimizeShaders(this, { params });

    let events = [...this.states.appear, ...this.states.scroll, ...this.states.hover];

    if (this.states && events.length) {
      events.forEach(stateEffect => {
        let shaderProps = params?.uniforms?.[stateEffect.prop];
        if (shaderProps) {
          stateEffect.uniformData = {
            type: shaderProps.type,
            name: shaderProps.name,
          };
          console.log(stateEffect.uniformData);
        }
      });
    }

    let data = {
      uniforms: {}
    }

    // Convert fill colors if the prop is "fill" and ensure uniform properties are set for non-Desktop breakpoints
    this.breakpoints.forEach(bp => {
      if (bp.name !== 'Desktop') {
        Object.keys(bp.props).forEach(prop => {
          if (params.uniforms[prop]) {
            data.uniforms[prop] = params.uniforms[prop];
          }
        });
      }
    });

    this.data = data;
  }

  setAnchorPoint(newAnchor) {
    const { width: absoluteWidth, height: absoluteHeight } = this.getAbsoluteDimensions();
    const oldOffsets = getAnchorOffsets(this.anchorPoint);
    const newOffsets = getAnchorOffsets(newAnchor);

    const deltaX = (newOffsets.x - oldOffsets.x) * absoluteWidth;
    const deltaY = (newOffsets.y - oldOffsets.y) * absoluteHeight;

    const newAbsX = StudioStore.state.canvasWidth * this.left + deltaX;
    const newAbsY = StudioStore.state.canvasHeight * this.top + deltaY;

    this.left = newAbsX / StudioStore.state.canvasWidth;
    this.top = newAbsY / StudioStore.state.canvasHeight;

    this.anchorPoint = newAnchor;
    this.render();
    StudioStore.renderNFrames(1);
  }

  convertDimensionValue(dimension, fromMode, toMode) {
    let value = this[dimension];
    let containerSize = dimension === 'width' ? StudioStore.state.canvasWidth : StudioStore.state.canvasHeight;
    
    if (fromMode === toMode) return value;
    
    if(fromMode === 'auto') {
      fromMode = dimension === 'width' ? this.heightMode : this.widthMode;
    }

    const fixedToRelative = fromMode === 'fixed' && toMode === 'relative';
    const relativeToFixed = fromMode === 'relative' && toMode === 'fixed';

    if (fixedToRelative) {
      return (value / containerSize);
    } else if (relativeToFixed) {
      return value * containerSize;
    } else {
      return value;
    }
  }

  setDimensionMode(dimension, newMode) {
    const oldMode = this[dimension + 'Mode'];
    if (oldMode === newMode) return;

    const { width: absoluteWidth, height: absoluteHeight } = this.getAbsoluteDimensions(true);
    this.aspectRatio = absoluteWidth / absoluteHeight;

    const newValue = this.convertDimensionValue(dimension, oldMode, newMode);
    this[dimension] = newValue;
    this[dimension + 'Mode'] = newMode;

    requestAnimationFrame(() => {
      this.render();
      StudioStore.renderFrame();
    });
  }

  updateStateEffectProperty(prop, value) {
    if (!this.local.stateEffectProps) {
      this.local.stateEffectProps = {};
    }

    this.local.stateEffectProps[prop] = value;
  }

  removeStateEffectProperty(prop) {
    if (this.local.stateEffectProps && this.local.stateEffectProps[prop]) {
      delete this.local.stateEffectProps[prop];
    }
  }

  isHovered() {
    if(!StudioStore.state.mouse.moved) return false;

    let { left, top, width, height } = this.box();
    
    const anchorOffsets = getAnchorOffsets(this.anchorPoint);

    if(width < 0) {
      width = Math.abs(width);
    }

    if(height < 0) {
      top = top + height;
      height = Math.abs(height);
    }

    left = left - anchorOffsets.x * width;
    top = top - anchorOffsets.y * height;

    let mousePos = StudioStore.state.mouse.pos;
    
    return mousePos.x >= left && 
           mousePos.x <= left + width && 
           mousePos.y >= top && 
           mousePos.y <= top + height;
  }
}
