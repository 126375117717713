<script>
import Icon from './Icon.vue';

export default {
  components: { Icon },
  props: ["type", 'disabled', 'loading', 'href', 'icon'],
  data() {
    return {};
  },
};
</script>

<template>
  <a v-if="href" :href="href" class="button" :disabled="disabled || loading">
    <slot />
  </a>
  <button v-else :type="type" class="button" :disabled="disabled || loading">
    <Icon v-if="loading" icon="spinner" />
    <Icon v-else-if="icon" style="margin-right: 0.3rem;" :icon="icon" />
    <slot />
  </button>
</template>

<style lang="scss">

button {
  background-color: unset;
  appearance: none;
  border: 0;
  color: inherit;
  font: inherit;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.3rem;
  cursor: pointer;
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  padding: 0.8rem 1.4rem;

  &.large {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }

  &.small {
    padding: 0.6rem 1.2rem;
    font-size: 1.2rem;
  }

  &.disabled,
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.margin-top {
    margin-top: 1rem;
  }

  &.full-width {
    width: 100%;
  }

  &.param {
    padding: 0.5rem 2rem;
    margin: 1rem 0 0 2.5rem;
    width: calc(100% - 2.5rem);
    font-weight: 400;
    background-color: var(--accent-color);
    border: 0;

    &:hover {
      background-color: var(--border-color);
    }
  }

  &.primary {
    background-color: var(--primary-color);
    color: var(--font-color);
    border-top: 1px solid var(--primary-color-light);
    border-bottom: 1px solid var(--primary-hover-color);

    &:hover {
      background-color: var(--primary-hover-color);
    }

    // & + .secondary {
    //   margin-left: 1rem;
    // }
  }

  &.secondary {
    border: 1px solid var(--border-color);
    border-top: 1px solid var(--selected-radio-color);
    color: var(--font-color);

    &:hover {
      background-color: var(--border-color);
    }
  }

  &.secondary-inverse {
    background-color: var(--selected-fill-color);
    color: var(--bg-color);

    &:hover {
      background-color: var(--font-color);
    }
  }

  &.legend {
    background-color: var(--gold);
    color: var(--artboard-color);
    border: 1px solid var(--gold);

    &:hover {
      background-color: var(--gold-hover);
    }
  }

  &.full-width {
    width: 100%;
  }
  &.icon,
  &.button__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    border-radius: 0.3rem;

    &:hover {
      background-color: var(--accent-color);
    }

    &.icon-auto-width {
      width: auto;
    }

    &.icon-large {
      padding: 0.6rem;
    }

    &.icon-seconary {
      color: var(--font-secondary-color);
    }
  }

  &.button__icon-circle {
      padding: 0.5rem;
      border-radius: 50%;
    }

  &.button__icon-seconary {
    color: var(--font-secondary-color);
  }

  &.button__icon-active {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
}
</style>