<script>
import Icon from './Icon.vue';
import Input from './Input.vue';
import Button from './Button.vue';
import ActionMenu from './ActionMenu.vue';
import { StudioStore } from '../stores/StudioStore';
import { FontsStore } from '../stores/FontsStore';
import { FirebaseStore } from '../stores/FirebaseStore';
import { UserStore } from '../stores/UserStore';
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

function sortObjectKeys(obj) {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {};
  for (const key of sortedKeys) {
    sortedObj[key] = obj[key];
  }
  return sortedObj;
}

export default {
  components: {
    Icon,
    Input,
    ActionMenu,
    Button
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      activeGroup: 'All',
      initialFont: '',
      searchQuery: '',
      chosenFont: '',
      isDraggingOver: false,
      customFonts: [],
      uploadTasks: [],
      item: StudioStore.getSelectedItem()
    }
  },
  mounted() {
    FontsStore.loadFonts();

    this.chosenFont = this.selectedItem.fontFamily;
    document.addEventListener("mousedown", this.handleClickOutside);
    this.$refs.dropdown_options.scrollTop = (Object.keys(this.fontData).indexOf(this.chosenFont)-6) * 32.8;

    window.addEventListener('keyup', this.handleArrows);

  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener('keyup', this.handleArrows);
    if(this.selectedItem) {
      this.selectedItem.local.tempFontFamily = null;
      this.selectedItem.fontFamily = this.chosenFont;
    }
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.handleFontSelection(this.chosenFont)
        this.state.browsingFonts = false;
      }
    },
    handleAnchorClick(group) {
      this.activeGroup = group;
      this.$refs.dropdown_options.scrollTop = 0;
    },
    handleFontSelection(font) {
      const data = this.fontData[font];
      if(data) {
        let fontStyle = this.selectedItem.fontStyle;
        if(!data.variants[fontStyle]) {
          fontStyle = 'regular';
          this.selectedItem.fontStyle = 'regular';
        }

        if(data.files[fontStyle]) {
          const fontFace = new FontFace(font, `url(${data.files[fontStyle].replace("http:", "https:")})`, {});
          document.fonts.add(fontFace);
          if(this.selectedItem) {
            this.selectedItem.local.tempFontFamily = font;
          }
          fontFace.load().then(() => {
            if(this.selectedItem?.local?.ctx) {
              this.selectedItem.render();
              StudioStore.renderFrame();
            }
          });
        }
      } else {
        if(this.selectedItem?.local?.ctx) {
          this.selectedItem.local.tempFontFamily = null;
          this.selectedItem.render();
          StudioStore.renderFrame();
        }
      }
    },
    handleFontChange(font) {
      if(!this.fontStyles[this.selectedItem.fontStyle]) {
          this.selectedItem.fontStyle = 'regular'

          if(!this.fontStyles.regular) {
            this.selectedItem.fontStyle = 'italic';
          }
      }

      let selectedFontData = this.fontData[font];

      this.selectedItem.fontCSS = {
        family: selectedFontData.family,
        src: selectedFontData.files[this.selectedItem.fontStyle]
      }

      this.chosenFont = font;
      this.handleFontSelection(font);
      this.selectedItem.fontFamily = selectedFontData.family;
      requestAnimationFrame(() => {
        StudioStore.state.browsingFonts = false;
      });
    },
    getFontStyles() {
      if(this.fontData[this.selectedItem.fontFamily] && this.fontData[this.selectedItem.fontFamily].variants) {
        return this.fontData[this.selectedItem.fontFamily].variants.reduce((accumulator, value) => {
          return {...accumulator, [value]: value};
        }, {});
      } else {
        return {regular: 'regular'}
      }
    },
    partialStringSearch(query, stringArray) {
      const results = [];

      // Loop through the string array to find matches
      for (const str of stringArray) {
        const index = str.toLowerCase().indexOf(query.toLowerCase());
        
        if (index !== -1) {
          // Store the index and the original string as a tuple
          results.push([index, str]);
        }
      }

      // Sort results to prioritize matches at the beginning
      results.sort((a, b) => {
        return a[0] - b[0];
      });

      // Extract the sorted strings
      const sortedStrings = results.map(result => result[1]);

      return sortedStrings;
    },
    handleArrows(e) {
      if(e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        let index = this.visibleFonts.findIndex(n => n.family === this.chosenFont);
        if(e.key === 'ArrowUp') {
          index--;
        } else if(e.key === 'ArrowDown') {
          index++;  
        }
        if(this.visibleFonts[index]) {
          this.handleFontSelection(this.visibleFonts[index].family)
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      // This method can be used to modify the event for detailed interaction feedback.
    },
    handleDragEnter(event) {
      this.isDraggingOver = true; // Set a data property to true to indicate drag is active.
    },
    handleDragLeave(event) {
      this.isDraggingOver = false; // Reset the drag indication when leaving the element.
    },
    handleDrop(event) {
      this.isDraggingOver = false; // Reset the drag indication.
      this.uploadFont(event); // Call uploadFont with the event.
    },
    uploadFont(event) {
      const files = event.target.files || event.dataTransfer.files; // Support for drag-and-drop and traditional file input
      const uploadPromises = [];

      for (const file of files) {
        const promise = new Promise((resolve, reject) => {
          if (!file) {
            reject(new Error('No file provided'));
            return;
          }

          if (file.size >= 10000000) { // 10 MB limit per file
            alert('Font too big, must be under 10mb');
            reject(new Error('Font too big, must be under 10mb'));
            return;
          }

          const validExtensions = ['woff', 'woff2', 'ttf', 'otf', 'eot'];

          // Extract file extension
          const extension = file.name.split('.').pop().toLowerCase();

          if (!validExtensions.includes(extension) && !['application/font-woff', 'font/woff', 'font/otf', 'font/ttf', 'font/woff2', 'application/x-font-ttf', 'application/font-sfnt', 'application/vnd.ms-fontobject', 'application/octet-stream'].includes(file.type)) {
              alert('Invalid file type');
              reject(new Error('Invalid file type'));
              return;
          }

          const fontFamilyName = file.name.replace(/\.[^/.]+$/, ""); // Strip file extension
          const fontPath = `${UserStore.id}/custom_fonts/${fontFamilyName}/${file.name}`;
          const storageRef = FirebaseStore.storageRef(fontPath);
          const metadata = {
            cacheControl: 'public, max-age=31536000'
          };

          const uploadTask = uploadBytesResumable(storageRef, file, metadata);
          let task = {
            family: fontFamilyName,
            progress: 0
          };

          this.uploadTasks.push(task);

          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0);
              task.progress = progress + '%';
              console.log(`Upload is ${progress}% done`);
            },
            (error) => {
              console.error("Upload failed:", error);
              task.progress = 'Failed';
              alert('Upload failed: ' + error.message);
              reject(error);
            },
            async () => {
              console.log('Upload successful');

              const url = await getDownloadURL(storageRef);
              const fontData = {
                family: fontFamilyName,
                user_id: UserStore.id,
                files: {
                  'regular': url
                },
                fileNames: {
                  'regular': file.name
                },
                category: 'custom',
                variants: ['regular']
              };
              FirebaseStore.createCustomFont(fontData).then(resp => {
                console.log('Creation successful');
                FontsStore.state.customFonts[fontData.family] = fontData;

                let index = this.uploadTasks.indexOf(task);
                this.uploadTasks.splice(index, 1);

                resolve(fontData);
              });
            }
          );
        });
        uploadPromises.push(promise);
      }

      Promise.all(uploadPromises)
        .then((uploadedFonts) => {
          console.log('All fonts uploaded successfully:', uploadedFonts);
        })
        .catch((error) => {
          console.error('Error uploading one or more fonts:', error);
        });
    },
    deleteCustomFont(font) {
      FirebaseStore.deleteCustomFont(font.id).then(resp => {
        delete FontsStore.state.customFonts[font.family];
      });
    },
    handleCustomFontMenu(val, font) {
      if(val === 'delete') {
        this.deleteCustomFont(font)
      }
    }
  },
  computed: {
    isPro() {
      return UserStore.hasProAccess;
    },
    groupings() {
      return [
        {
          label: 'All',
          items: Object.values(this.fontData)
        },
        {
          label: 'Display',
          items: Object.values(this.fontData).filter(n => n.category === 'display')
        },
        {
          label: 'Sans serif',
          items: Object.values(this.fontData).filter(n => n.category === 'sans-serif')
        },
        {
          label: 'Serif',
          items: Object.values(this.fontData).filter(n => n.category === 'serif')
        },
        {
          label: 'Monospace',
          items: Object.values(this.fontData).filter(n => n.category === 'monospace')
        },
        {
          label: 'Studio picks',
          items: Object.values(this.fontData).filter(n => n.featured)
        },
        {
          label: 'Custom',
          icon: !this.isPro ? 'legend' : '',
          items: Object.values(this.fontData).filter(n => n.category === 'custom'),
          click: () => {
            if(!this.isPro) {
              StudioStore.state.signUpForPro = true;
            } else {
              this.handleAnchorClick('Custom')
            }
          }
        }
      ]
    },
    searchResults() {
      return this.partialStringSearch(this.searchQuery, Object.keys(this.fontData)).map(n => this.fontData[n])
    },
    visibleFonts() {
      return (this.searchQuery.length ? this.searchResults : this.activeGroupItems);
    },
    userEffects() {
      const userEffects = {}
      const keys = Object.keys(EFFECTS);
      keys.forEach(key => {
        if(!EFFECTS[key].params.hidden) {
          userEffects[key] = EFFECTS[key];
        }
      })
      return userEffects;
    },
    selectedItem() {
      return StudioStore.getSelectedItem() || this.item;
    },
    selectedFontFamily() {
      return this.selectedItem.fontFamily;
    },
    activeGroupItems() { return this.groupings.find(n => n.label === this.activeGroup).items},
    fontStyles() {return this.getFontStyles()},
    fontData() {return sortObjectKeys(FontsStore.state.fonts)}
  },
}
</script>

<template>
  <div id="size-browser" class="modal modal__fonts modal__pop-in">
    <div class="flex sizes-wrapper">
      <div class="size-groups mr-2">
        <a
          v-for="group in groupings"
          @click="group.click ? group.click() : handleAnchorClick(group.label)"
          class="context-menu-link flex"
          :class="{ 'context-menu-link__active': group.label === activeGroup }"
          href="javascript:void(0)"
          :key="group.label">
          {{ group.label }}
          <Icon class="inline-block ml-1" size="10" v-if="group.icon" style="color: var(--gold)" :icon="group.icon" />
        </a>
      </div>
      <div class="sizes relative" ref="dropdown_options">
        <div class="search">
          <Input :disabled="isDraggingOver" class="search-field" v-model="searchQuery" placeholder="Search fonts" />
        </div>
        <div v-if="activeGroup === 'Custom'"
          @dragover.prevent="handleDragOver"
          @dragenter.prevent="handleDragEnter"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop($event)"
        >
          <Button class="secondary small w-100 relative mb-2 flex direction-column"
            :class="{'drag-over': isDraggingOver}"
            >
              <div class="flex align-center font-weight-medium"><Icon icon="upload" size="14" class="mr-1" />  Upload font files</div>
              <p class="font-secondary-color mt-0 mb-0 flex">Browse or drag and drop</p>
              <input v-if="!isDraggingOver" class="upload-image" multiple accept=".woff,.woff2,.ttf,.otf,.eot" type="file" @change="uploadFont" />
          </Button>
        </div>
        <div v-if="uploadTasks.length" class="group-wrapper">
          <a class="size-container disabled flex align-center justify-between"
            v-for="task in uploadTasks"
            :key="task.family"
          >
            <div class="flex w-100 justify-between align-center">
              {{ task.family }} <span class="ml-auto">{{ task.progress }}</span>
            </div>
          </a>
        </div>
        <div v-if="fontData" class="group-wrapper">
          <div class="flex align-center w-100"
            v-for="font in visibleFonts"
            :key="font.family"
          >
            <a class="size-container flex align-center justify-between"
              :class="{'selected': font.family === chosenFont}"
              @click="handleFontChange(font.family)"
              @mouseenter="handleFontSelection(font.family)"
              @mouseleave="handleFontSelection(null)"
              :key="font.family"
            >
              <div class="flex justify-between align-center">
                {{ font.family }}<Icon v-if="font.featured" tooltip="Studio Pick" class="ml-1" icon="star" :size="12" />
              </div>
            </a>
            <ActionMenu v-if="font.category === 'custom'"  size="15" icon="ellipsis" @select-option="(val) => handleCustomFontMenu(val, font)" :options="[{label: 'Delete', value: 'delete'}]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.modal__fonts {
  width: 48rem;
  height: 60rem;
  top: 5.9rem;
  right: 0.5rem;
  left: unset;
  padding: 0 1.5rem;
  border-radius: 0.8rem;
  z-index: 99999;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    background: linear-gradient(to top, var(--bg-color), var(--bg-color-no-alpha))
  }
}

.sizes-wrapper {
  height: 100%;
}

.animate-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #FFFFFF75;
}

.sizes {
  overflow-y: auto;
  width: 100%;
  padding: 0 0.4rem 2rem;
}

.search {
  margin: 1.4rem 0rem 0.6rem;
  position: sticky;
  top: 1.5rem;
  z-index: 100;
  background-color: var(--bg-color);
}

.search-field {
  font-size: 1.4rem;
  padding: 1rem 1.2rem;
  color: var(--font-primary-color);
}

.size-groups {
  min-width: 15rem;
  margin-top: 1.5rem;
}

.size-group-label {
  color: var(--font-secondary-color);
  padding-top: var(--unit4);
  text-transform: uppercase;
}

.group-wrapper {
  display: flex;
  flex-wrap: wrap;
  
}

.context-menu-link {
  color: var(--font-secondary-color);

  &:hover {
    color: var(--font-primary-color);
  }

  &.context-menu-link__active {
    color: var(--font-primary-color);
    background-color: var(--accent-color);
  }
}

.size-container {
  position: relative;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;

  &:empty {
    display: none;
  }

  &.size-container__active {
    background-color: var(--primary);
    color: var(--selected-fill-color);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &.size-container__active:hover {
    //background-color: var(--primary-active-bg);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &:hover {
    background-color: var(--accent-color);
  }
  &.selected {
    background-color: var(--primary-color);
  }
  .parameter {
    padding-left: 0;
  }
  img {
    width: 100%;
    border-radius: 0.4rem;
  }
}

.size-icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
}

.size-icon {
  border: 1px solid var(--font-tertiary-color);
  border-radius: 0.1rem;
}

.drag-over {
  border: 1px dashed var(--primary-color);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

</style>