<script>
export default {
  props: ['value', 'placeholder'],
  data() {
    return {
      editing: false,
      localValue: ''
    };
  },
  methods: {
    focus() {
      // This is the method that will be called from parent components
      this.activateEditing();
    },
    activateEditing() {
      this.editing = true;
      this.localValue = this.value;  // Initialize localValue with prop value when editing starts
      this.$nextTick(() => {
        if (this.$refs.inputField) {
          this.$refs.inputField.focus();
        }
      });
    },
    updateLocalValue($event) {
      this.localValue = $event.target.value;
    },
    deactivateEditing() {
      this.editing = false;
      if (this.localValue !== this.value) {
        this.$emit('update:value', this.localValue);
      }
    }
  }
}
</script>

<template>
  <div @click="activateEditing" class="editable-text">
    <input
      v-if="editing"
      :value="value"
      class="subtle-input-field"
      :placeholder="placeholder"
      @input="updateLocalValue"
      @blur="deactivateEditing"
      @keyup.enter="deactivateEditing"
      ref="inputField"
    >
    <template v-else>{{ value }}</template>
  </div>
</template>


<style scoped>

.editable-text {
  display: inline-block;
}

.subtle-input-field {
  font: inherit;
  color: var(--font-color);
  background: var(--accent-color);
  outline: 0;
  border: 0;
  border-radius: 0.3rem;
  transform: translateX(-0.2rem);
}
</style>
