<script>
import Tooltip from './Tooltip.vue'

export default {
  components: {
    Tooltip
  },
  props: ['icon', 'size', 'tooltip'],
  data() {
    return {
      defaultSize: 16,
    };
  },
  computed: {
    computedSize() {
      return this.size || this.defaultSize;
    },
    icons() {
      return {
        alignLeft: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.499995 0.999995C0.223855 0.999995 -5.58458e-07 1.22385 -5.46388e-07 1.49999L-2.18554e-08 13.4999C-9.78492e-09 13.776 0.223855 13.9999 0.499995 13.9999C0.776136 13.9999 0.999991 13.776 0.999991 13.4999L0.999991 8.99993L12 8.99993C12.5523 8.99993 13 8.55222 13 7.99993L13 6.99994C13 6.44766 12.5523 5.99995 12 5.99995L0.999991 5.99995L0.999991 1.49999C0.999991 1.22385 0.776135 0.999995 0.499995 0.999995Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        alignCenterHorizontal: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.99988 6C1.44759 6 0.999877 6.44772 0.999877 7L0.999877 8C0.999877 8.55228 1.44759 9 1.99988 9L6.99988 9L6.99988 13.5C6.99988 13.7761 7.22374 14 7.49988 14C7.77602 14 7.99988 13.7761 7.99988 13.5L7.99988 9L12.9999 9C13.5522 9 13.9999 8.55228 13.9999 8L13.9999 7C13.9999 6.44772 13.5522 6 12.9999 6L7.99988 6L7.99988 1.5C7.99988 1.22386 7.77602 1 7.49988 1C7.22373 1 6.99988 1.22386 6.99988 1.5L6.99988 6L1.99988 6Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        alignRight: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4999 1C14.2237 1 13.9999 1.22386 13.9999 1.5L13.9999 6L2.99988 6C2.44759 6 1.99988 6.44772 1.99988 7L1.99988 8C1.99988 8.55228 2.44759 9 2.99988 9L13.9999 9L13.9999 13.5C13.9999 13.7761 14.2237 14 14.4999 14C14.776 14 14.9999 13.7761 14.9999 13.5L14.9999 9L14.9999 6L14.9999 1.5C14.9999 1.22386 14.776 1 14.4999 1Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        alignTop: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 0C1.22386 0 1 0.223858 1 0.5C1 0.776142 1.22386 1 1.5 1H6V12C6 12.5523 6.44772 13 7 13H8C8.55228 13 9 12.5523 9 12V1H13.5C13.7761 1 14 0.776142 14 0.5C14 0.223858 13.7761 0 13.5 0H9H6H1.5Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        alignCenterVertical: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.99988 1C6.44759 1 5.99988 1.44772 5.99988 2V7H1.49988C1.22374 7 0.999878 7.22386 0.999878 7.5C0.999878 7.77614 1.22374 8 1.49988 8H5.99988V13C5.99988 13.5523 6.44759 14 6.99988 14H7.99988C8.55216 14 8.99988 13.5523 8.99988 13V8H13.4999C13.776 8 13.9999 7.77614 13.9999 7.5C13.9999 7.22386 13.776 7 13.4999 7H8.99988V2C8.99988 1.44772 8.55216 1 7.99988 1L6.99988 1Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        alignBottom: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 3C9 2.44772 8.55229 2 8 2H7C6.44772 2 6 2.44772 6 3L6 14H1.5C1.22386 14 1 14.2239 1 14.5C1 14.7761 1.22386 15 1.5 15L6 15H9H13.5C13.7761 15 14 14.7761 14 14.5C14 14.2239 13.7761 14 13.5 14H9V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        check: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path></svg>`,
        checkSimple: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path></svg>`,
        checkBold: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path></svg>`,
        copy: `<svg xmlns="http://www.w3.org/2000/svg"height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M200,32H163.74a47.92,47.92,0,0,0-71.48,0H56A16,16,0,0,0,40,48V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm-72,0a32,32,0,0,1,32,32H96A32,32,0,0,1,128,32Zm72,184H56V48H82.75A47.93,47.93,0,0,0,80,64v8a8,8,0,0,0,8,8h80a8,8,0,0,0,8-8V64a47.93,47.93,0,0,0-2.75-16H200Z"></path></svg>`,
        discord: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" class="fill-[#878787]" height="${this.computedSize}" width="${this.computedSize}" xmlns="http://www.w3.org/2000/svg"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>`,
        ellipsis: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128ZM128,72a12,12,0,1,0-12-12A12,12,0,0,0,128,72Zm0,112a12,12,0,1,0,12,12A12,12,0,0,0,128,184Z"></path></svg>`,
        effect: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 14V18M10 17V18M10 2 10 6M10 2V3M7.875 12.1177 6.46079 13.5319M5.04688 14.946 4.33977 15.6532M13.5312 6.46082 12.117 7.87503M15.6523 4.33948 14.9452 5.04658M6 10 2 10M3 9.99951H2M18 10H14M18 9.99951H17M7.87891 7.87878 6.46469 6.46457M5.05078 5.05042 4.34367 4.34331M13.5352 13.5356 12.1209 12.1214M15.6562 15.657 14.9491 14.9499" stroke="currentColor" stroke-linecap="round"/><circle cx="10" cy="10" r="1" fill="currentColor"/></svg>`,
        info: `<svg height="${this.computedSize}" width="${this.computedSize}" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" ><circle cx="12" cy="12" r="10" stroke="currentColor" fill="transparent"></circle><path d="M12 16v-4" stroke="currentColor"></path><path d="M12 8h.01" stroke="currentColor"></path></svg>`,
        folder: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M216,72H131.31L104,44.69A15.86,15.86,0,0,0,92.69,40H40A16,16,0,0,0,24,56V200.62A15.4,15.4,0,0,0,39.38,216H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72ZM40,56H92.69l16,16H40ZM216,200H40V88H216Z"></path></svg>`,
        globe: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M128,24h0A104,104,0,1,0,232,128,104.12,104.12,0,0,0,128,24Zm88,104a87.61,87.61,0,0,1-3.33,24H174.16a157.44,157.44,0,0,0,0-48h38.51A87.61,87.61,0,0,1,216,128ZM102,168H154a115.11,115.11,0,0,1-26,45A115.27,115.27,0,0,1,102,168Zm-3.9-16a140.84,140.84,0,0,1,0-48h59.88a140.84,140.84,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.84a157.44,157.44,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154,88H102a115.11,115.11,0,0,1,26-45A115.27,115.27,0,0,1,154,88Zm52.33,0H170.71a135.28,135.28,0,0,0-22.3-45.6A88.29,88.29,0,0,1,206.37,88ZM107.59,42.4A135.28,135.28,0,0,0,85.29,88H49.63A88.29,88.29,0,0,1,107.59,42.4ZM49.63,168H85.29a135.28,135.28,0,0,0,22.3,45.6A88.29,88.29,0,0,1,49.63,168Zm98.78,45.6a135.28,135.28,0,0,0,22.3-45.6h35.66A88.29,88.29,0,0,1,148.41,213.6Z"></path></svg>`,
        help: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M192,96c0,28.51-24.47,52.11-56,55.56V160a8,8,0,0,1-16,0V144a8,8,0,0,1,8-8c26.47,0,48-17.94,48-40s-21.53-40-48-40S80,73.94,80,96a8,8,0,0,1-16,0c0-30.88,28.71-56,64-56S192,65.12,192,96Zm-64,96a16,16,0,1,0,16,16A16,16,0,0,0,128,192Z"></path></svg>`,
        home: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M219.31,108.68l-80-80a16,16,0,0,0-22.62,0l-80,80A15.87,15.87,0,0,0,32,120v96a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V160h32v56a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V120A15.87,15.87,0,0,0,219.31,108.68ZM208,208H160V152a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v56H48V120l80-80,80,80Z"></path></svg>`,
        // legend: `<svg height="${this.computedSize}" width="${this.computedSize}" viewbox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.385 0h33.468c-7.445 9.478-11.7 20.446-11.7 32.135 0 11.89 4.403 23.036 12.089 32.624.354-14.074 13.047-25.962 30.56-30.218C59.147 43.627 55.376 53.994 55.376 65H.803c7.805-9.643 12.281-20.875 12.281-32.865 0-11.69-4.253-22.657-11.7-32.135Z" fill="currentColor"/></svg>`,
        // legend: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="none" viewbox="0 0 277 372"><path fill-rule="evenodd" clip-rule="evenodd" d="M128 54.559v292.785c0 6.714 5.442 12.156 12.156 12.156 4.775 0 9.108-2.796 11.076-7.147L277 74.247V342c0 16.569-13.431 30-30 30H30c-16.569 0-30-13.431-30-30V30C0 13.431 13.431 0 30 0h188.656l-65.28 20.162C138.289 24.822 128 38.77 128 54.56Z" fill="currentColor"/></svg>`,
        legend: `<svg height="${this.computedSize * 0.8}" width="${this.computedSize * 0.8}" fill="none" viewbox="0 0 277 372" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M128 63.314v272.323c0 13.179 10.684 23.863 23.863 23.863a23.864 23.864 0 0 0 22.147-14.976L277 87.865V342c0 16.569-13.431 30-30 30H30c-16.569 0-30-13.431-30-30V30C0 13.431 13.431 0 30 0h203.704l-81.883 29.436A36 36 0 0 0 128 63.314Z" fill="#f1a22b"/></svg>`,
        linkLinked: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 10L17 22C17 22.2652 16.8946 22.5196 16.7071 22.7071C16.5196 22.8946 16.2652 23 16 23C15.7348 23 15.4804 22.8946 15.2929 22.7071C15.1054 22.5196 15 22.2652 15 22L15 10C15 9.73478 15.1054 9.48043 15.2929 9.29289C15.4804 9.10536 15.7348 9 16 9C16.2652 9 16.5196 9.10536 16.7071 9.29289C16.8946 9.48043 17 9.73478 17 10ZM11 13L11 8C11 6.67392 11.5268 5.40215 12.4645 4.46447C13.4021 3.52678 14.6739 3 16 3C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8L21 13C21 13.2652 21.1054 13.5196 21.2929 13.7071C21.4804 13.8946 21.7348 14 22 14C22.2652 14 22.5196 13.8946 22.7071 13.7071C22.8946 13.5196 23 13.2652 23 13L23 8C23 6.14348 22.2625 4.36301 20.9497 3.05025C19.637 1.7375 17.8565 1 16 1C14.1435 1 12.363 1.7375 11.0503 3.05025C9.7375 4.36301 9 6.14348 9 8L9 13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13ZM23 24L23 19C23 18.7348 22.8946 18.4804 22.7071 18.2929C22.5196 18.1054 22.2652 18 22 18C21.7348 18 21.4804 18.1054 21.2929 18.2929C21.1054 18.4804 21 18.7348 21 19L21 24C21 25.3261 20.4732 26.5979 19.5355 27.5355C18.5979 28.4732 17.3261 29 16 29C14.6739 29 13.4021 28.4732 12.4645 27.5355C11.5268 26.5979 11 25.3261 11 24L11 19C11 18.7348 10.8946 18.4804 10.7071 18.2929C10.5196 18.1054 10.2652 18 10 18C9.73478 18 9.48043 18.1054 9.29289 18.2929C9.10536 18.4804 9 18.7348 9 19L9 24C9 25.8565 9.7375 27.637 11.0503 28.9497C12.363 30.2625 14.1435 31 16 31C17.8565 31 19.637 30.2625 20.9497 28.9497C22.2625 27.637 23 25.8565 23 24Z" fill="currentColor"/></svg>`,
        linkUnlinked: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 13L11 8C11 6.67392 11.5268 5.40215 12.4645 4.46447C13.4021 3.52678 14.6739 3 16 3C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8L21 13C21 13.2652 21.1054 13.5196 21.2929 13.7071C21.4804 13.8946 21.7348 14 22 14C22.2652 14 22.5196 13.8946 22.7071 13.7071C22.8946 13.5196 23 13.2652 23 13L23 8C23 6.14348 22.2625 4.36301 20.9497 3.05025C19.637 1.7375 17.8565 1 16 1C14.1435 1 12.363 1.7375 11.0503 3.05025C9.7375 4.36301 9 6.14348 9 8L9 13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13ZM23 24L23 19C23 18.7348 22.8946 18.4804 22.7071 18.2929C22.5196 18.1054 22.2652 18 22 18C21.7348 18 21.4804 18.1054 21.2929 18.2929C21.1054 18.4804 21 18.7348 21 19L21 24C21 25.3261 20.4732 26.5979 19.5355 27.5355C18.5979 28.4732 17.3261 29 16 29C14.6739 29 13.4021 28.4732 12.4645 27.5355C11.5268 26.5979 11 25.3261 11 24L11 19C11 18.7348 10.8946 18.4804 10.7071 18.2929C10.5196 18.1054 10.2652 18 10 18C9.73478 18 9.48043 18.1054 9.29289 18.2929C9.10536 18.4804 9 18.7348 9 19L9 24C9 25.8565 9.7375 27.637 11.0503 28.9497C12.363 30.2625 14.1435 31 16 31C17.8565 31 19.637 30.2625 20.9497 28.9497C22.2625 27.637 23 25.8565 23 24Z" fill="currentColor"/></svg>`,
        logo: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="currentColor"/></svg>`,
        check: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Zm7.914-18.586a2 2 0 1 0-2.828-2.828L13.5 18.172l-3.086-3.086a2 2 0 1 0-2.828 2.828l4.5 4.5a2 2 0 0 0 2.828 0l9-9Z" fill="currentColor"/></svg>`,
        play: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.24182 2.32181C3.3919 2.23132 3.5784 2.22601 3.73338 2.30781L12.7334 7.05781C12.8974 7.14436 13 7.31457 13 7.5C13 7.68543 12.8974 7.85564 12.7334 7.94219L3.73338 12.6922C3.5784 12.774 3.3919 12.7687 3.24182 12.6782C3.09175 12.5877 3 12.4252 3 12.25V2.75C3 2.57476 3.09175 2.4123 3.24182 2.32181ZM4 3.57925V11.4207L11.4288 7.5L4 3.57925Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>`,
        star: `<svg height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 141 141"  fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M49.794 4.562c1.796-5.528 9.616-5.528 11.412 0l8.775 27.006a6 6 0 0 0 5.707 4.146h28.395c5.813 0 8.229 7.438 3.527 10.854L84.637 63.26a6 6 0 0 0-2.18 6.708l8.776 27.006c1.796 5.528-4.531 10.125-9.234 6.709L59.027 86.991a6 6 0 0 0-7.054 0L29 103.682c-4.702 3.416-11.029-1.181-9.233-6.709l8.775-27.006a6 6 0 0 0-2.18-6.708L3.39 46.57c-4.702-3.417-2.286-10.855 3.527-10.855h28.395a6 6 0 0 0 5.707-4.146l8.775-27.006Z" fill="#997DEC"/></svg>`,
        scroll: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M144,18H112A62.07,62.07,0,0,0,50,80v96a62.07,62.07,0,0,0,62,62h32a62.07,62.07,0,0,0,62-62V80A62.07,62.07,0,0,0,144,18Zm50,158a50.06,50.06,0,0,1-50,50H112a50.06,50.06,0,0,1-50-50V80a50.06,50.06,0,0,1,50-50h32a50.06,50.06,0,0,1,50,50ZM134,64v48a6,6,0,0,1-12,0V64a6,6,0,0,1,12,0Z"></path></svg>`,
        spinner: `<svg v-if="loading" class="mr-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="${this.computedSize}" width="${this.computedSize}" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.25s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>`,
        plus: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>`,
        time: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M128,40a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,40Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,216ZM173.66,90.34a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32l40-40A8,8,0,0,1,173.66,90.34ZM96,16a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,16Z"></path></svg>`,
        trash: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128Z"></path></svg>`,
        upload: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="86 81.989 128 40 170 81.989" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="128" y1="152" x2="128" y2="40.02943" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M216,152v56a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>`,
        x: `<svg height="${this.computedSize}" width="${this.computedSize}" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>`,
        lightning: `<svg xmlns="http://www.w3.org/2000/svg" height="${this.computedSize}" width="${this.computedSize}"fill="currentColor" viewBox="0 0 256 256"><path d="M213.84,118.63a6,6,0,0,0-3.73-4.25L150.88,92.17l15-75a6,6,0,0,0-10.27-5.27l-112,120a6,6,0,0,0,2.28,9.71l59.23,22.21-15,75a6,6,0,0,0,3.14,6.52A6.07,6.07,0,0,0,96,246a6,6,0,0,0,4.39-1.91l112-120A6,6,0,0,0,213.84,118.63ZM106,220.46l11.85-59.28a6,6,0,0,0-3.77-6.8l-55.6-20.85,91.46-98L138.12,94.82a6,6,0,0,0,3.77,6.8l55.6,20.85Z"></path></svg>`
      };
    },
  },
  methods: {
    getIcon() {
      return this.icons[this.icon]
    }
  }
};
</script>

<template v-if="tooltip">
   <Tooltip v-if="tooltip" :tooltip="tooltip">
    <div class="icon flex align-center" v-html="getIcon()"></div>
  </Tooltip>
  <div v-else class="icon flex align-center" v-html="getIcon()"></div>
</template>

<style lang="scss">
  
</style>
