<script>
import EditableText from './EditableText.vue';
import { DesignsStore } from "../stores/DesignsStore.js";

export default {
  props: ['folder'],
  components: {
    EditableText
  },
  data() {
    return {
      pendingDelete: null,
      renaming: false,
      contextMenuOpen: false,
      isDragOver: false
    }
  },
  computed: {
    designs() {
      return DesignsStore.state.designs.filter(n => !n.deleted && n.folderId === this.folder.id).sort((a, b) => b.updatedAt - a.updatedAt);
    }
  },
  methods: {
    editName() {
      this.renaming = true;
      this.contextMenuOpen = false;
      this.$nextTick(() => {
        this.$refs.rename.focus()
      })
    },
    renameDesign(val) {
      this.renaming = false;
      DesignsStore.renameFolder(this.folder.id, val);
    },
    deleteFolder(id) {
      DesignsStore.deleteFolder(id);
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
      this.isDragOver = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragOver = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragOver = false;
      
      const designId = event.dataTransfer.getData('design-id');
      if (designId) {
        const design = DesignsStore.state.designs.find(d => d.id === designId);
        if (design) {
          this.moveDesignToFolder(design, this.folder.id);
        }
      }
    },
    moveDesignToFolder(design, folderId) {
      DesignsStore.moveDesignToFolder(design, folderId);
    }
  }
}

</script>

<template>
    <div 
      :key="folder.id"
      class="folder-listing-item"
      :class="{
        'folder-listing-item__pending-delete': pendingDelete === folder.id,
        'folder-listing-item__drag-over': isDragOver
      }"
      :title="folder.name"
      @dragover="handleDragOver"
      @dragleave="handleDragLeave"
      @drop="handleDrop"
    >
      <RouterLink
        :to=" 'dashboard/' + folder.id"
        :event="renaming ? '' : 'click'"
        class="design-item-link"
        draggable="false"
      >
        <div class="folder-preview">
          <div class="folder-thumb"
            v-for="design in designs.slice(0, 4)"
            :key="design.id"
          >
            <img 
              :alt="design.name"
              :src="design.thumbnail"
            />
          </div>
          <div class="folder-thumb"
            v-for="design, index in (4 - designs.slice(0, 4).length)"
            :key="index"
          >
          </div>
        </div>
      </RouterLink>
      <div class="design-listing-info">
        <EditableText
          :value="folder.name"
          placeholder="Untitled project"
          @update:value="renameDesign"
        />
        <div class="design-listing-timestamp mt-1">{{designs.length}} project{{designs.length !== 1 ? 's' : ''}}</div>
      </div>
      <a @click="contextMenuOpen = true" href="javascript:void(0)" class="edit-design-button button button__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="16"></circle><circle cx="64" cy="128" r="16"></circle><circle cx="192" cy="128" r="16"></circle></svg>
      </a>
      <div aria-modal v-if="contextMenuOpen"  @mouseleave="contextMenuOpen = false" class="design-listing-item-context-menu">
        <RouterLink :to="'dashboard/' + folder.id" class="context-menu-link" title="Edit">Open</RouterLink>
        <hr>
        <a @click="deleteFolder(folder.id)" class="context-menu-link" href="javascript:void(0)" title="Delete">Delete</a>
      </div>
    </div>
</template>

<style lang="scss" scoped>

.folder-listing-item {
  position: relative;
  width: 16.66666666%;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: var(--bg-accent-color);

  &.folder-listing-item__pending-delete {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.1rem var(--border-color);
    //background-color: var(--accent-color);

    img {
      z-index: 0;
    }

    .edit-design-button.button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }

  &.folder-listing-item__drag-over {
    background-color: var(--accent-color);
    cursor: copy;
    
    // Optional: add a subtle transition
    transition: background-color 0.2s ease;
  }
}

.edit-design-button.button.button__icon {
  display: none;
  position: absolute;
  background-color: var(--bg-color);
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  box-shadow: 0 0.1rem 0.3rem rgba(#000, 0.2);
}

.context-menu-link {
  display: block;
  text-align: left;
  font-size: 1.4rem;
  text-decoration: none;
  color: inherit;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;

  &:hover {
    background-color: var(--accent-color);
  }
}

.new-item-content {
  text-align: center;
}

.design-listing-info {
  padding: 0 2rem 2rem;
  width: 100%;
  color: inherit;
}

.folder-thumb {
  display: flex;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
}


.folder-preview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  height: calc(16.666vw - 2rem);
  padding: 1.5rem;
}

.folder-preview img {
  width: 100%;
  object-fit: contain; /* ensures images fill the square without distortion */
}

.design-item-link {
  width: 100%;
  position: relative;
  height: calc(16.6666vw - 2rem);
  text-decoration: none;
  display: block;
  text-align: center;
}

.design-listing-name {
  text-decoration: none;
  color: var(--font-color);
  margin-bottom: 0.5em;
}

.design-listing-timestamp {
  font-size: 1.2rem;
  color: var(--font-secondary-color);
}

</style>