<script>
import { UserStore } from "../stores/UserStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import Icon from './Icon.vue';
import Button from './Button.vue';

export default {
  components: {
    Icon,
    Button
  },
  data() {
    return {
      UserStore,
      DesignsStore,
      activeTab: 'recents'
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.$emit('change-tab', tab);
    },
    createFolder() {
      if(!this.hasProAccess) {
        this.$emit('open-promodal');
        return;
      }
      this.$emit('create-folder');
    },
    createDesign() {
      this.$emit('create-design');
    },
    openFolder(folderId) {
      this.$emit('open-folder', folderId);
    }
  },
  computed: {
    hasProAccess() { 
      return UserStore.hasProAccess;
    },
    folders() {
      return DesignsStore.state.folders || [];
    }
  }
}
</script>

<template>
  <div class="sidebar">
    <!-- Logo -->
    <div class="logo-container">
      <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="var(--primary-color)"/>
        <!-- Add your logo path here -->
      </svg>
    </div>
    
    <!-- Tabs -->
    <div class="tabs">
      <div 
        class="tab" 
        :class="{ 'active': activeTab === 'recents' }"
        @click="setActiveTab('recents')"
      >
        <Icon icon="clock" class="tab-icon" />
        <span>Recents</span>
      </div>
      <div 
        class="tab" 
        :class="{ 'active': activeTab === 'all' }"
        @click="setActiveTab('all')"
      >
        <Icon icon="grid" class="tab-icon" />
        <span>All Projects</span>
      </div>
      
      <!-- Folder Tabs -->
      <div 
        v-for="folder in folders" 
        :key="folder.id"
        class="tab" 
        :class="{ 'active': activeTab === 'folder-' + folder.id }"
        @click="setActiveTab('folder-' + folder.id); openFolder(folder.id)"
      >
        <Icon icon="folder" class="tab-icon" />
        <span>{{ folder.name }}</span>
      </div>
    </div>
    
    <!-- Action Buttons -->
    <div class="action-buttons">
      <Button class="sidebar-button secondary" @click="createFolder">
        <Icon icon="plus" class="mr-1 font-secondary-color" /> New folder
        <Icon v-if="!hasProAccess" class="ml-1" :size="10" icon="legend" style="color: var(--gold)" />
      </Button>
      <Button class="sidebar-button primary" @click="createDesign">
        <Icon icon="plus" class="mr-1" /> New project
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  width: 240px;
  height: 100vh;
  background-color: var(--background-color);
  border-right: 1px solid var(--accent-color);
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
}

.logo-container {
  width: 3rem;
  margin: 0 auto 2rem;
  
  svg {
    object-fit: contain;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.tab {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--accent-color-light);
  }
  
  &.active {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
  
  .tab-icon {
    margin-right: 0.75rem;
  }
}

.folders-section {
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  padding-left: 1rem;
  color: var(--font-secondary-color);
}

.folders-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-height: 40vh;
  overflow-y: auto;
}

.folder-item {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--accent-color-light);
  }
  
  &.active {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
  
  .folder-icon {
    margin-right: 0.75rem;
    font-size: 0.9rem;
  }
  
  .folder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
  }
}

.empty-folders {
  padding: 0.75rem 1rem;
  color: var(--font-secondary-color);
  font-size: 0.9rem;
  font-style: italic;
}

.action-buttons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sidebar-button {
  width: 100%;
  justify-content: center;
}
</style> 