import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  BLEND,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../Constants.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uScale;
  uniform float uTime;
  uniform float uAngle;
  uniform float uShimmer;
  uniform float uSkew;
  uniform float uMovement;
  uniform float uBrightness;
  uniform float uThreshold;
  uniform float uWander;
  uniform vec2 uPos;
  uniform vec3 uColor;
  uniform int uBlendMode;
  ${UNIVERSAL_UNIFORMS}
  ${BLEND}

  out vec4 fragColor;
  
  const float PI = 3.14159265359;
  mat2 rot(float a) {
    return mat2(cos(a), -sin(a), sin(a), cos(a));
  }

  vec2 hash(vec2 p) {
      p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
      return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
  }

  vec3 voronoi_static(vec2 st) {
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 1.0;
    float wander = uWander * uTime * 0.2;
    vec2 m_point;

    for (int y = -1; y <= 1; y++) {
        for (int x = -1; x <= 1; x++) {
            vec2 neighbor = vec2(float(x), float(y));
            vec2 point = hash(i_st + neighbor);
            point = 0.5 + 0.5 * sin(5. + wander + 6.2831 * point);
            vec2 diff = neighbor + point - f_st;
            float dist = length(diff);
            if (dist < m_dist) {
                m_dist = dist;
                m_point = point;
            }
        }
    }

    return vec3(m_dist, m_point);
  }

  void main() {
    vec2 uv = vTextureCoord;

    vec4 bg = texture(uTexture, uv);

    vec4 color = vec4(0,0,0,1);
    vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1);

    vec2 mPos = mix(vec2(0), (uMousePos-0.5), uTrackMouse);

    uv -= uPos;
    uv *= aspectRatio;
    uv = uv * rot(uAngle * 2. * PI);
    uv *= 40. * uScale; // Apply scale
    uv *= mix(vec2(1), vec2(1, 0), uSkew);
    uv /= aspectRatio;

    mPos = mPos * rot(uAngle * -2. * PI);

    vec2 st1 = uv - (mPos * 30. * uScale);
    vec2 st2 = uv - (mPos * 40. * uScale);

    vec2 mouse1 = st1 + vec2(0, uTime * uMovement * -0.05);
    vec2 mouse2 = st2 + vec2(0, uTime * uMovement * -0.05);

    vec3 voro_static = voronoi_static(mouse1 * aspectRatio);
    vec3 voro_static2 = voronoi_static(mouse2 * aspectRatio + vec2(10));
    float dist = smoothstep(0., 0.8, voro_static.x);
    float dist2 = smoothstep(0., 0.8, voro_static2.x);

    float shimmer1 = mix(1., (sin(voro_static.z * voro_static.y * uTime * 0.5) + 1.), uShimmer);
    float shimmer2 = mix(1., (sin(voro_static2.z * voro_static2.y * uTime * 0.5) + 1.), uShimmer);
    
    float radius1 = 0.02 * uBrightness * shimmer1;
    float radius2 = 0.04 * uBrightness * shimmer2;
    vec3 pass1 = vec3(radius1/dist);
    vec3 pass2 = vec3(radius2/dist2);
    color.rgb = (pass1 + pass2) * uColor * mix(1., bg.r, uThreshold);

    // #ifelseopen
    if(uBlendMode > 0) {
      color.rgb = blend(uBlendMode, bg.rgb, color.rgb);
    }
    // #ifelseclose

    ${computeFragColor('color')}
  }

`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0,
    },

    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    shimmer: {
      name: 'uShimmer',
      type: '1f',
      value: 0.5,
    },
    brightness: {
      name: 'uBrightness',
      type: '1f',
      value: 0.5,
    },
    wander: {
      name: 'uWander',
      type: '1f',
      value: 0,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0,
    },
    movement: {
      name: 'uMovement',
      type: '1f',
      value: 0.5,
    },
    threshold: {
      name: 'uThreshold',
      type: '1f',
      value: 0,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(1),
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const WISPS = {
  id: 'wisps',
  label: 'Wisps',
  params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    brightness: {
      label: 'Brightness',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    shimmer: {
      label: 'Shimmer',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    skew: {
      label: 'Skew',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    threshold: {
      label: 'Threshold',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    color: {
      label: 'Color',
      value: new Vec3(1),
      output: 'color',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'ADD',
      options: BLEND_MODES,
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    wander: {
      label: 'Wander',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    movement: {
      label: 'Movement',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
