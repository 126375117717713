<script>
import DropdownMenu from './DropdownMenu.vue';
import RadioToggle from './RadioToggle.vue';
import SliderParam from './SliderParam.vue';
import InputField from './InputField.vue';
import CodeViewer from './CodeViewer.vue';
import Button from './Button.vue';
import ClassicToggle from './ClassicToggle.vue';
import Icon from './Icon.vue';
import { StudioStore } from '../stores/StudioStore';
import { FontsStore } from '../stores/FontsStore';
import { DesignsStore } from '../stores/DesignsStore';
import { UserStore } from '../stores/UserStore.js';

export default {
  components: {
    DropdownMenu,
    RadioToggle,
    Button,
    CodeViewer,
    ClassicToggle,
    Icon,
    SliderParam,
    InputField,
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      exportTypes: ['image', 'video', 'embed', 'export'],
      uploadingEmbed: false,
      publishSuccessful: false,
      outOfDateLibModal: false,
      preparingEmbed: false,
      processingVideo: false,
      tagName: 'script',
      exportedCode: '',
      fpsOptions: [
        { value: 15, label: '15' },
        { value: 24, label: '24' },
        { value: 30, label: '30' },
        { value: 60, label: '60' },
      ],
      framerComponent: 'https://framer.com/m/UnicornStudioEmbed-wWy9.js',
      embedQuality: 0.5,
      embedOptions: {
        name: StudioStore.state.design.name,
        fps: 60,
        dpi: 1.5,
        scale: 1,
        includeLogo: !UserStore.hasProAccess,
        isProduction: false,
      },
    };
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    FontsStore.loadFonts();
    if (this.state.design.hasEmbed) {
      this.updateVersionOptions();
    }
  },
  beforeUnmount() {
    // Vue 3 uses beforeUnmount instead of beforeDestroy
    document.removeEventListener('mousedown', this.handleClickOutside);
  },
  watch: {
    'state.design.hasEmbed'() {
      if (this.state.design.hasEmbed) {
        this.updateVersionOptions();
      }
    },
    'state.export.type'() {
      if (this.state.export.type === 'embed') {
        if (!this.state.design.hasEmbed) {
          this.saveEmbed();
        } else {
          this.updateVersionOptions();
        }
      } else if (this.state.export.type === 'export') {
        this.updateVersionOptions()
          .then(() => {
            this.exportCode();
          })
          .catch(err => {
            this.exportCode();
          });
      }
    },
  },
  methods: {
    handleClickOutside(event) {
      // Check if the click was outside the component's element
      if (!this.$el.contains(event.target) && !this.state.signUpForPro && !this.state.promptModal.open) {
        this.state.export.active = false;
      }
    },
    copyImageToClipboard() {
      this.copying = true;
      let self = this;
      StudioStore.renderFrame();
      this.state.curtain.canvas.toBlob(function (blob) {
        let data = [new ClipboardItem({ [blob.type]: blob })];

        navigator.clipboard.write(data).then(
          function () {
            self.state.copied = true;
            self.state.copying = false;

            setTimeout(() => {
              self.state.copied = false;
            }, 2_500);
          },
          function (err) {
            console.log(err);
            self.state.copying = false;
          }
        );
      });
    },
    copyJSON() {
      this.copying = true;

      const jsonString = this.exportedCode;

      const blob = new Blob([jsonString], { type: 'text/plain' });

      const data = [new ClipboardItem({ 'text/plain': blob })];

      navigator.clipboard.write(data).then(
        () => {
          console.log('Copied');
          this.state.copied = true;
          this.state.copying = false;

          setTimeout(() => {
            this.state.copied = false;
          }, 2_500);
        },
        err => {
          console.log(err);
          this.state.copying = false;
        }
      );
    },

    exportCode() {
      if (UserStore.id !== StudioStore.state.design.creatorId) {
        return;
      }
      this.preparingEmbed = true;
      DesignsStore.prepareEmbed(this.state.history, this.embedOptions, this.state.design.versionId)
        .then(resp => {
          this.exportedCode = JSON.stringify(resp);
          this.preparingEmbed = false;
        })
        .catch(err => {
          console.log(err);
          this.preparingEmbed = false;
        });
    },
    saveEmbed() {
      if (UserStore.id !== StudioStore.state.design.creatorId) {
        return;
      }
      if (this.state.design.hasEmbed && this.embedOptions.version && this.embedOptions.version !== DesignsStore.state.version) {
        StudioStore.state.promptModal.open = true;
        StudioStore.state.promptModal.breakingChange = this.isOlderVersion;
        StudioStore.state.promptModal.title = 'New version: ' + DesignsStore.state.version;
        StudioStore.state.promptModal.confirmText = 'Publish';
        StudioStore.state.promptModal.message =
          `Your published embed is version ${this.embedOptions.version}.
          <br><br>${DesignsStore.state.changelog}
          `;
        StudioStore.state.promptModal.callback = result => {
          StudioStore.state.promptModal = {};
          if (result) {
            this.createEmbed();
          }
        };
        return;
      }

      this.createEmbed();
    },
    async createEmbed() {
      if (!UserStore.hasProAccess) {
        this.embedOptions.freePlan = true;
      } else {
        this.embedOptions.freePlan = false;
      }

      this.uploadingEmbed = true;

      try {
        const result = await DesignsStore.createEmbed(
          this.state.history,
          this.state.design.versionId,
          this.embedOptions
        );
        this.uploadingEmbed = false;
        this.publishSuccessful = true;
      } catch (error) {
        alert(error);
        this.uploadingEmbed = false;
      }
    },
    toggleEmbedLogo() {
      if (!UserStore.hasProAccess) {
        this.embedOptions.includeLogo = true;
        this.state.signUpForPro = true;
      }
    },
    toggleProductionMode() {
      if (!UserStore.hasProAccess) {
        this.state.signUpForPro = true;
        this.embedOptions.isProduction = false;
      }
    },
    updateVersionOptions() {
      return new Promise((resolve, reject) => {
        fetch(
          `https://storage.googleapis.com/unicornstudio-production/embeds/${
            this.state.design.versionId
          }?update=${Date.now()}}`
        )
          .then(resp => resp.json())
          .then(resp => {
            if (resp.options) {
              this.state.design.hasEmbed = true;
              this.embedOptions.fps = resp.options.fps;
              this.embedOptions.dpi = resp.options.dpi;
              this.embedOptions.scale = resp.options.scale;
              this.embedOptions.version = resp.version;
              this.embedOptions.includeLogo = !UserStore.hasProAccess ? true : resp.options.includeLogo;
              this.embedOptions.isProduction = resp.options.isProduction;
            }
            resolve(); // Resolve the promise when the fetch and processing are complete
          })
          .catch(error => reject(error)); // Reject the promise if there is an error in the fetch or processing
      });
    },

    downloadExportedCode() {
      const blob = new Blob([this.exportedCode], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = this.state.design.name;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    handleExportClick() {
      if (UserStore.hasProAccess) {
        this.state.export.type = 'export';
      } else {
        this.state.signUpForPro = true;
      }
    },
  },
  computed: {
    iFrameEmbed() {
      return `<iframe src="https://unicorn.studio/embed/${this.state.design.versionId}" width="${this.state.canvasWidth}px" height="${this.state.canvasHeight}px" loading="lazy"></iframe>`;
    },
    embedCode() {
      return `<div data-us-project="${this.state.design.versionId}" style="width:${this.state.canvasWidth}px; height: ${this.state.canvasHeight}px"></div><script type="text/javascript">!function(){if(!window.UnicornStudio){window.UnicornStudio={isInitialized:!1};var i=document.createElement("${this.tagName}");i.src="https://cdn.jsdelivr.net/gh/hiunicornstudio/unicornstudio.js@v${this.latestVersion}/dist/unicornStudio.umd.js",i.onload=function(){window.UnicornStudio.isInitialized||(UnicornStudio.init(),window.UnicornStudio.isInitialized=!0)},(document.head || document.body).appendChild(i)}}();</${this.tagName}>`;
    },

    embedLink() {
      return `${window.location.origin}/embed/${this.state.design.versionId}`;
    },
    downloadableAsset() {
      return (
        this.state.export.type !== 'embed' && this.state.export.type !== 'iframe' && this.state.export.type !== 'export'
      );
    },
    framerTooltip() {
      return `Paste this component into any Framer project. It has a property "Project ID" which you will set to the "data-us-project" value in the embed code above.`;
    },
    libraryOutOfDate() {
      return !this.embedOptions.version || !this.embedOptions.version !== DesignsStore.state.version;
    },
    isOlderVersion() {
      if (!this.embedOptions.version) return false;
      const versionPrefix = this.embedOptions.version.substring(0, 3);
      return ['1.1', '1.2', '1.3'].includes(versionPrefix);
    },
    latestVersion() {
      return DesignsStore.state.version;
    },
    libVersion() {
      return DesignsStore.state.version;
    },
    hasProAccess() {
      return UserStore.hasProAccess;
    },
    videoCodecOptions() {
      const allCodecs = [
        { value: 'video/webm', label: 'Default' },
        { value: 'video/webm;codecs=vp9', label: 'VP9' },
        { value: 'video/webm;codecs=vp8', label: 'VP8' },
        { value: 'video/webm;codecs=h264', label: 'H264' },
        { value: 'video/webm;codecs=av1', label: 'AV1' },
      ];
      
      // Filter supported codecs and transform to object format required by DropdownMenu
      return allCodecs
        .filter(codec => MediaRecorder.isTypeSupported(codec.value))
        .reduce((acc, codec) => {
          acc[codec.value] = codec.label;
          return acc;
        }, {});
    },
  },
};
</script>

<template>
  <div class="export-scroll-modal right">
    <div class="modal w-100 export-modal modal__pop-in flex">
      <div class="export-header">
        <a
          class="context-menu-link"
          href="javascript:void(0)"
          :class="{ 'context-menu-link__active': state.export.type === 'image' }"
          @click="state.export.type = 'image'"
          >Image</a
        >
        <a
          class="context-menu-link"
          href="javascript:void(0)"
          :class="{ 'context-menu-link__active': state.export.type === 'video' }"
          @click="state.export.type = 'video'"
          >Video</a
        >
        <a
          class="context-menu-link"
          href="javascript:void(0)"
          :class="{ 'context-menu-link__active': state.export.type === 'embed' }"
          @click="state.export.type = 'embed'"
          >Embed
        </a>
        <a
          class="context-menu-link flex align-center"
          href="javascript:void(0)"
          :class="{ 'context-menu-link__active': state.export.type === 'export' }"
          @click="handleExportClick"
          >Code
          <Icon v-if="!hasProAccess" class="ml-1" :size="12" icon="legend" style="color: var(--gold)" />
        </a>
      </div>
      <div class="export-container">
        <template v-if="state.export.type === 'embed' && state.design.hasEmbed">
          <label class="parameter-label parameter mb-1">Project ID</label>
          <CodeViewer
            :copyable="true"
            class="mb-3"
            :class="{ 'code-disabled': !state.design.hasEmbed }"
            :value="this.state.design.versionId"
          />
          <label class="parameter-label parameter mb-1">iFrame</label>
          <div class="parameter parameter__block">
            <CodeViewer :class="{ 'code-disabled': !state.design.hasEmbed }" :value="iFrameEmbed" />
          </div>
          <div class="flex justify-between mt-3 mb-1">
            <label class="parameter-label parameter">Embed code</label>
            <a
              class="font-secondary-color"
              :href="`https://github.com/hiunicornstudio/unicornstudio.js/tree/v${latestVersion}`"
              target="blank"
              >Javascript SDK ↗</a>
          </div>
          <div class="parameter parameter__block">
            <CodeViewer :class="{ 'code-disabled': !state.design.hasEmbed }" :value="embedCode" />
          </div>
          <label class="parameter-label mt-3 mb-1 block relative flex align-center justify-between">
            <span class="flex align-center parameter-label"
              >Framer component <Icon icon="info" class="gray ml-1" size="14" :tooltip="framerTooltip"
            /></span>
            <a
              href="https://youtu.be/eUcbPbnvnrU?si=DylPK9ZIgIFCR385"
              class="flex align-center font-secondary-color"
              target="_blank"
              >Watch tutortial</a
            >
          </label>
          <div class="parameter parameter__block">
            <CodeViewer :class="{ 'code-disabled': !state.design.hasEmbed }" :value="framerComponent" />
          </div>
        </template>

        <div v-if="downloadableAsset" class="parameter parameter__block">
          <label class="icon-label slider-label">Size</label>
          <div class="coords-input-group">
            <InputField label="W" :value="state.currentSize.realDimensions[0] * state.export.resolution" output="px">
            </InputField>
            <InputField label="H" :value="state.currentSize.realDimensions[1] * state.export.resolution" output="px">
            </InputField>
          </div>
        </div>

        <template v-if="state.export.type === 'image'">
          <div class="parameter parameter__block">
            <RadioToggle
              label="Scale"
              v-model.number="state.export.resolution"
              :options="[
                { value: 0.5, label: '0.5x' },
                { value: 1, label: '1x' },
                { value: 2, label: '2x' },
                { value: 3, label: '3x' },
                { value: 4, label: '4x' },
              ]"
            ></RadioToggle>
          </div>
          <div class="parameter parameter__block">
            <RadioToggle
              label="Format"
              v-model="state.export.imageType"
              :options="[
                { value: 'png', label: 'PNG' },
                { value: 'jpeg', label: 'JPG' },
                { value: 'webp', label: 'WEBP' },
              ]"
            ></RadioToggle>
          </div>
        </template>

        <SliderParam
          v-if="state.export.imageType === 'jpeg' || state.export.imageType === 'webp'"
          v-model.number="state.export.quality"
          :min="0"
          :max="1"
          :step="0.01"
          label="Quality"
          output="percent"
        ></SliderParam>

        <template v-if="state.export.type === 'video'">
          <div class="parameter parameter__block">
            <RadioToggle
              label="Format"
              v-model="state.export.videoType"
              :options="[
                { value: 'webm', label: 'WebM' },
                { value: 'mp4', label: 'MP4' },
              ]"
            ></RadioToggle>
          </div>
          <div class="parameter parameter__block">
            <span class="param-label font-secondary-color">Codec</span>
            <DropdownMenu
              label="Codec"
              v-model="state.export.videoCodec"
              :options="videoCodecOptions"
            ></DropdownMenu>
          </div>
          <div class="parameter parameter__block">
            <RadioToggle
              label="Scale"
              v-model="state.export.resolution"
              :options="[
                { value: 0.5, label: '0.5x' },
                { value: 1, label: '1x' },
                { value: 1.5, label: '1.5x' },
                { value: 2, label: '2x' },
              ]"
            ></RadioToggle>
          </div>
        </template>
        <div class="parameter parameter__block" v-if="state.export.type === 'video' || state.export.type === 'gif'">
          <RadioToggle label="Frame rate (fps)" v-model="state.export.fps" :options="fpsOptions"></RadioToggle>
        </div>
        <div class="parameter parameter__block" v-if="state.export.type === 'video' || state.export.type === 'gif'">
          <SliderParam
            v-model.number="state.export.duration"
            :min="1"
            :max="30"
            :step="1"
            label="Duration"
            output="seconds"
          ></SliderParam>
        </div>

        <template v-if="state.design.hasEmbed && (state.export.type === 'embed' || state.export.type === 'iframe')">
          <div class="parameter mb-2" :class="{ 'mt-4': state.design.hasEmbed }">
            <label class="parameter-label">Settings</label>
          </div>
          <SliderParam
            v-model.number="embedOptions.scale"
            :min="0.25"
            :max="1"
            :step="0.01"
            label="Scale"
            output="percent"
            tooltip="Reduces quality but improves performance"
          ></SliderParam>
          <div class="parameter parameter__block">
            <RadioToggle label="Frame rate (fps)" v-model="embedOptions.fps" :options="[...fpsOptions, {value: 120, label: '120'}]"></RadioToggle>
          </div>
          <ClassicToggle
            label="Logo"
            v-model="embedOptions.includeLogo"
            tooltip="Include or disable the 'Made in unicorn.studio' badge"
            @change="toggleEmbedLogo"
          ></ClassicToggle>
          <ClassicToggle
            label="Production"
            class="mb-1"
            v-model="embedOptions.isProduction"
            tooltip="Publishing in production mode will clear the edge CDN cache. Updates can take 4-5 minutes to propagate. Learn more in the help docs."
            @change="toggleProductionMode"
          ></ClassicToggle>
          <div class="mb-3 font-secondary-color" v-if="embedOptions.isProduction">
            Make sure to include the
            <a
              href="https://www.unicorn.studio/docs/embed/#production-mode"
              target="_blank"
              >production flag</a
            >.
          </div>
        </template>

        <template v-if="state.export.type === 'export'">
          <label class="parameter-label parameter mb-1">JSON</label>
          <CodeViewer v-if="preparingEmbed" value="Preparing..." :maxHeight="true" />
          <CodeViewer v-else-if="exportedCode" :value="exportedCode" :maxHeight="true" />
          <p class="mb-4 mt-5">
            Host the project data yourself by downloading the JSON file and using the JS library.
            <a
              class="font-secondary-color"
              :href="`https://github.com/hiunicornstudio/unicornstudio.js/tree/v${latestVersion}`"
              target="blank"
              >Javascript SDK ↗</a
            >
          </p>
          <div class="parameter parameter__block">
            <Button
              @click="downloadExportedCode"
              :loading="preparingEmbed"
              :disabled="preparingEmbed"
              class="primary w-50 small"
            >
              <template v-if="preparingEmbed"> Preparing code... </template>
              <template v-else> Download JSON </template>
            </Button>

            <Button v-if="!preparingEmbed" @click="copyJSON" class="secondary w-50 ml-2 small">
              <template v-if="!state.copying && !state.copied">Copy to clipboard</template>
              <template v-if="state.copying">Copying...</template>
              <template v-if="state.copied">Copied!</template>
            </Button>
          </div>
        </template>

        <div v-if="downloadableAsset" class="mt-4 flex">
          <Button
            v-if="state.export.downloading"
            class="primary small"
            :loading="state.export.downloading"
            @click="$emit('download')"
          >
            <template v-if="state.export.progress >= 100">Processing...</template>
            <template v-else-if="state.export.downloading">Exporting... {{ state.export.progress }}%</template>
            <template v-else>Export video</template>
          </Button>
          <Button v-else @click="$emit('download')" class="primary w-50 mr-2 small"> Export </Button>
          <Button
            v-if="state.export.type === 'image' && !state.export.downloading"
            @click="copyImageToClipboard"
            class="secondary w-50 small"
          >
            <template v-if="!state.copying && !state.copied">Copy to clipboard</template>
            <template v-if="state.copying">Copying...</template>
            <template v-if="state.copied">Copied!</template>
          </Button>
        </div>
        <div v-else-if="state.export.type === 'embed'" class="parameter parameter__block">
          <Button v-if="state.design.hasEmbed" @click="saveEmbed" :loading="uploadingEmbed" class="primary w-50 mr-2 small">
            <Icon v-if="publishSuccessful && !uploadingEmbed" class="mr-1" icon="check" />
            <template v-if="publishSuccessful && !uploadingEmbed"> Published </template>
            <template v-else>{{uploadingEmbed ? 'Publishing...' : 'Publish'}}</template>
          </Button>
          <Button v-if="!state.design.hasEmbed" @click="saveEmbed" :loading="uploadingEmbed" class="primary w-50 small">
            Publish
          </Button>
          <Button v-if="state.design.hasEmbed" :href="embedLink" target="_blank" class="secondary w-50 small">
            Preview URL ↗
          </Button>
        </div>
        <a
          class="a font-secondary-color mt-2 block"
          v-if="state.export.type === 'embed'"
          href="https://www.unicorn.studio/docs/embed/"
          >Need help? Check out the embed guide ↗</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.export-scroll-modal {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  width: 46.5rem;
  top: 5.9rem;
  z-index: 99999;
}

.export-modal {
  width: 46.5rem;
  top: 0;
}

.export-container {
  width: 100%;
}

.export-header {
  text-transform: capitalize;
  margin-right: 1.5rem;
  width: 12rem;
}

.download-sizes {
  display: flex;
  justify-content: space-between;
  .parameter.parameter__block {
    margin-top: 0;
    width: auto;
  }
  .radio-group {
    max-width: none;
    background-color: transparent;
  }
}

.right {
  right: 0.5rem;
  left: unset;
}

.code-disabled {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000060;
    z-index: 1;
    top: 0;
    left: 0;
  }
  &:after {
    content: 'No embed';
    position: absolute;
    z-index: 3;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 1rem 2rem;
    background-color: var(--bg-color);
  }
}
</style>
