import { Vec2 } from 'curtainsjs';
import {
  vertexShader,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform vec2 uResolution;
uniform float uScale;
uniform float uSmooth;
uniform float uQuantize;
uniform float uCurvature;
uniform float uGamma;
uniform float uTime;
uniform float uFlicker;
uniform int uStyle;

out vec4 fragColor;

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 gammaCorrect(vec3 color, float gamma) {
    return pow(color, vec3(1.0 / gamma));
}

vec3 styleOne(vec2 curvedUV) {
  float size = max(3.0 / 1080.0, 0.028 * (1.0 - uScale));
  float aspectRatio = uResolution.x / uResolution.y;
  float aspectCorrection = mix(aspectRatio, 1./aspectRatio, 0.5);
  vec2 cellSize = vec2(size / aspectRatio, size) * aspectCorrection;
  cellSize = floor(cellSize * vec2(1080)) / vec2(1080);

  vec2 staggeredUV = curvedUV;
  if (mod(floor(curvedUV.x / cellSize.x), 2.0) > 0.5) {
      staggeredUV.y += 0.5 * cellSize.y;
  }

  vec2 cellCoords = floor(staggeredUV / cellSize) * cellSize;

  vec2 unstaggerOffset = vec2(0.0);
  if (mod(floor(curvedUV.x / cellSize.x), 2.0) > 0.5) {
      unstaggerOffset.y = -0.5 * cellSize.y;
  }

  vec2 sampleCoord = cellCoords + 0.5 * cellSize + unstaggerOffset;
  vec4 texColor = texture(uTexture, sampleCoord);

  vec2 staggeredCellPos = mod(staggeredUV, cellSize) / cellSize;

  float segmentWidth = 0.5;
  vec3 finalColor = vec3(0.0);

  float distCoord = staggeredCellPos.x;

  float distRed = abs(distCoord - segmentWidth * 0.5);
  float distGreen = abs(distCoord - segmentWidth * 1.);
  float distBlue = abs(distCoord - segmentWidth * 1.5);

  distRed = min(distRed, 1.0 - distRed);
  distGreen = min(distGreen, 1.0 - distGreen);
  distBlue = min(distBlue, 1.0 - distBlue);

  float softness = 0.5 * segmentWidth;
  float redFactor = smoothstep(softness, 0.0, distRed * 1.05);
  float greenFactor = smoothstep(softness, 0.0, distGreen * 1.1);
  float blueFactor = smoothstep(softness, 0.0, distBlue * 0.9);

  vec3 blurColor = vec3(0.0);
  float blurFactor = 1.0 / 9.0;
  for (int dx = -1; dx <= 1; dx++) {
      for (int dy = -1; dy <= 1; dy++) {
          vec2 offset = vec2(dx, dy) * cellSize * uSmooth;
          
          blurColor += texture(uTexture, sampleCoord + offset).rgb * blurFactor;
      }
  }
  
  finalColor.r = redFactor * blurColor.r * (3. * uGamma);
  finalColor.g = greenFactor * blurColor.g * (3. * uGamma);
  finalColor.b = blueFactor * blurColor.b * (3. * uGamma);

  float edgeWidth = 0.05;
  vec2 edgeDistance = abs(staggeredCellPos - 0.5);
  float edgeFactor = smoothstep(0.45 - edgeWidth, 0.5, max(edgeDistance.x, edgeDistance.y));
  edgeFactor = ((1.0 - edgeFactor) + 0.2);
  finalColor = finalColor * edgeFactor;
  
  finalColor = floor(finalColor * uQuantize) / uQuantize;

  float flicker = 1.0+0.03*cos(sampleCoord.x/6e1 + uTime*2e1);
  finalColor *= mix(1., flicker, uFlicker);

  return finalColor;
}

vec3 styleTwo(vec2 curvedUV) {
    float cellPadding = 0.0;
    float pixelHeight = 1.5;
    float aspectRatio = uResolution.x / uResolution.y;
    float aspectCorrection = mix(aspectRatio, 1./aspectRatio, 0.5);
    float size = max(3.0 / 1080.0, 0.015 * (1.0 - uScale));

    vec2 cellSize = vec2(size * 0.75 / aspectRatio, size * 1.75) * aspectCorrection;
    cellSize = floor(cellSize * vec2(1080)) / vec2(1080);

    vec2 cellCoords = floor(curvedUV / cellSize) * cellSize;
    vec2 sampleCoord = cellCoords + 0.5 * cellSize;
    vec4 texColor = texture(uTexture, sampleCoord);
    vec2 cellPos = mod(curvedUV, cellSize) / cellSize;
    vec3 finalColor = vec3(0.0);
    vec3 blurColor = vec3(0.0);
    float blurFactor = 1.0 / 9.0;

    for (int dx = -1; dx <= 1; dx++) {
        for (int dy = -1; dy <= 1; dy++) {
            vec2 offset = vec2(dx, dy) * cellSize * uSmooth;
            blurColor += texture(uTexture, sampleCoord + offset).rgb * blurFactor;
        }
    }

    float distFromCenter = length(cellPos - 0.5);
    float noise = fract(sin(dot(curvedUV, vec2(12.9898, 78.233))) * 43758.5453);
    float pixelFactor = smoothstep(pixelHeight * 0.5, 0.0, distFromCenter + noise * 0.05);

    vec3 gradientColor = vec3(0.0);
    gradientColor.r = smoothstep(0.0, 0.5, cellPos.y) * smoothstep(1.0, 0.5, cellPos.y);
    gradientColor.g = smoothstep(0.25, 0.75, cellPos.y) * smoothstep(1.5, 0.5, cellPos.y);
    gradientColor.b = smoothstep(0.5, 1.0, cellPos.y) * smoothstep(1.5, 1.0, cellPos.y);

    finalColor = mix(blurColor, gradientColor * blurColor, 0.4) * pixelFactor * 1.5;
    finalColor = floor(finalColor * uQuantize) / uQuantize;
    finalColor = gammaCorrect(finalColor, 3.0 * uGamma);

    float flicker = 1.0 + 0.03 * cos(sampleCoord.x / 60.0 + uTime * 20.0);
    finalColor *= mix(1.0, flicker, uFlicker);

    return finalColor;
}

vec3 styleThree(vec2 curvedUV) {
  float cellPadding = 0.0;
  float pixelHeight = 1.5;

  float aspectRatio = uResolution.x / uResolution.y;
  float aspectCorrection = mix(aspectRatio, 1./aspectRatio, 0.5);

  float size = max(3.0 / 1080.0, 0.015 * (1.0 - uScale));
  vec2 cellSize = vec2(size * 0.75 / aspectRatio, size * 1.75) * aspectCorrection;
  cellSize = floor(cellSize * vec2(1080)) / vec2(1080);

  vec2 staggeredUV = curvedUV;
  if (mod(floor(curvedUV.x / cellSize.x), 2.0) > 0.5) {
      staggeredUV.y += 0.5 * cellSize.y;
  }

  vec2 cellCoords = floor(staggeredUV / cellSize) * cellSize;

  vec2 unstaggerOffset = vec2(0.0);
  if (mod(floor(curvedUV.x / cellSize.x), 2.0) > 0.5) {
      unstaggerOffset.y = -0.5 * cellSize.y;
  }

  vec2 sampleCoord = cellCoords + 0.5 * cellSize + unstaggerOffset;
  vec2 cellPos = mod(staggeredUV, cellSize) / cellSize;

  vec3 finalColor = vec3(0.0);
  vec3 blurColor = vec3(0.0);
  float blurFactor = 1.0 / 9.0;
  
  for (int dx = -1; dx <= 1; dx++) {
      for (int dy = -1; dy <= 1; dy++) {
          vec2 offset = vec2(dx, dy) * cellSize * aspectRatio * uSmooth;
          blurColor += texture(uTexture, sampleCoord + offset).rgb * blurFactor;
      }
  }

  float distFromCenter = length(cellPos - 0.5);
  float noise = random(curvedUV);
  float pixelFactor = smoothstep(pixelHeight * 0.5, 0.0, distFromCenter + noise * 0.05);

  vec3 gradientColor = vec3(0.0);
  gradientColor.r = smoothstep(0.0, 0.5, cellPos.y) * smoothstep(1.0, 0.5, cellPos.y);
  gradientColor.g = smoothstep(0.25, 0.75, cellPos.y) * smoothstep(1.5, 0.5, cellPos.y);
  gradientColor.b = smoothstep(0.5, 1.0, cellPos.y) * smoothstep(1.5, 1.0, cellPos.y);

  finalColor = mix(blurColor, gradientColor * blurColor, 0.4) * pixelFactor * 1.5;
  finalColor = floor(finalColor * uQuantize) / uQuantize;
  finalColor = gammaCorrect(finalColor, 3.0 * uGamma);

  float flicker = 1.0 + 0.03 * cos(sampleCoord.x / 60.0 + uTime * 20.0);
  finalColor *= mix(1.0, flicker, uFlicker);

  return finalColor;
}

void main() {
    vec3 finalColor;

    vec4 color = texture(uTexture, vTextureCoord);

    if(color.a == 0.) {
      fragColor = vec4(0);
      return;
    }

    switch(uStyle) {
        case 1: finalColor = styleOne(vTextureCoord); break;
        case 2: finalColor = styleTwo(vTextureCoord); break;
        case 3: finalColor = styleThree(vTextureCoord); break;
        default: finalColor = texture(uTexture, vTextureCoord).rgb; break;
    }

    fragColor = vec4(finalColor, texture(uTexture, vTextureCoord).a);
}

`;

export const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },

  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    smooth: {
      name: 'uSmooth',
      type: '1f',
      value: 1,
    },
    gamma: {
      name: 'uGamma',
      type: '1f',
      value: 0.5,
    },
    flicker: {
      name: 'uFlicker',
      type: '1f',
      value: 0.5,
    },
    style: {
      name: 'uStyle',
      type: '1i',
      value: 1,
    },
    quantize: {
      name: 'uQuantize',
      type: '1f',
      value: 16,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const RETRO_SCREEN = {
  id: 'retro_screen',
  label: 'Retro screen',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    style: {
      label: 'Style',
      value: 1,
      options: {
        1: 'CRT-1',
        2: 'CRT-2',
        3: 'CRT-3',
      },
      tooltip: 'Screen style',
      responsiveDisabled: true,
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Resolution of the cells',
    },
    gamma: {
      label: 'Gamma',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Gamma correction',
    },
    smooth: {
      label: 'Fuziness',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Fuziness of the picture',
    },
    quantize: {
      label: 'Quantize',
      value: 16,
      options: {
        4: '4-bit',
        8: '8-bit',
        16: '16-bit',
        32: '32-bit',
        64: '64-bit',
      },
      tooltip: 'Color quantization',
    },
    flicker: {
      label: 'Flicker',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Animated screen flicker',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
