<script>
import HistoryListItem from './HistoryListItem.vue';
import VersionItem from './VersionItem.vue';
import RadioToggle from './RadioToggle.vue';
import ActionFlyout from './ActionFlyout.vue';
import { TextBox } from '../scripts/layerTypes/Text.js';
import { Effect } from '../scripts/layerTypes/Effect.js';
import { EFFECTS } from '../scripts/Shaders.js';
import { StudioStore } from '../stores/StudioStore.js';
import { Vec3 } from 'curtainsjs';

export default {
  components: {
    HistoryListItem,
    VersionItem,
    ActionFlyout,
    RadioToggle
  },
  props: ['loading', 'editing', 'historyColors', 'versions'],
  emits: ['updateHistoryState', 'update-history-state', 'clear', 'editItem', 'deleteItem', 'updateValue', 'addEffect', 'editCode', 'saveSnapshot', 'selectVersion', 'viewVersions', 'replaceImage', 'edit-item'],
  data() {
    return {
      draggingHistoryItem: null,
      draggingOverHistoryItem: null,
      draggingOverTop: false,
      draggingOverBottom: false,
      contextItem: null,
      contextPos: {},
      historyItemFill: null,
      editingEffect: null,
      effects: EFFECTS,
      dragTimeout: false,
      view: 'layers',
      viewOptions: [
        {
          label: 'Layers',
          value: 'layers'
        },
        {
          label: 'Versions',
          value: 'versions'
        },
      ]
    }
  },
  watch: {
    view() {
      if(this.view === 'versions') {
        this.$emit('view-versions');
      }
    }
  },
  mounted() {
    this.draggingImage = new Image(0, 0);
    this.draggingImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

    document.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('click', this.handleContextMenuClose);
    //document.addEventListener('contextmenu', this.handleContextMenu);
  },
  unmounted() {
    document.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('click', this.handleContextMenuClose);
  },
  computed: {
    editingItemProperties() {
      return this.history.find(n => n.local.id === this.editing);
    },
    items() {
      return this.getItemList();
    },
    historyOrdered() {
      return this.getItemList()
    },
    history() {
      return StudioStore.state.history;
    },
    initialized() {
      return StudioStore.state.initialized
    },
    clonedTemplate() {
      return this.$route.query.from === 'template';
    }
  },
  methods: {
    handleDragEnd() {
      this.draggingHistoryItem = null;
      this.draggingOverHistoryItem = null;
      clearInterval(this.dragTimeout);
    },
    handleMouseUp() {
      this.draggingHistoryItem = null;
      this.draggingOverHistoryItem = null;
    },
    getItemList() {
      let result = [];
      const parentLayers = this.history.filter(n => !n.parentLayer);
      const effects = this.history.filter(n => n.parentLayer);
      parentLayers.forEach(item => {
        if(item.effects && item.effects.length) {
          const fx = item.effects.map(id => effects.find(n => n.parentLayer === id));
          if(fx.length) {
            result.push(...fx);
          }
        }
        result.push(item);
      });
      return result;
    },
    getFillStyle(fill) {
      return getFillStyle(fill);
    },
    updateItemState(prop) {
      this.$forceUpdate();
      this.$emit('update-history-state', prop);
    },
    getItemName(item) {
      if(item) {
        if(this.effects[item.type]) {
          return this.effects[item.type].label;
        } else {
          return item.layerType.charAt(0).toUpperCase() + item.layerType.slice(1);
        }
      } else {
        return '';
      }
    },
    handleDragStart(item, e) {
      const dragIcon = document.createElement('img');
      StudioStore.state.mouse.dragging = false;
      dragIcon.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // A tiny, transparent GIF
      e.dataTransfer.setDragImage(dragIcon, 0, 0);
      this.dragTimeout = setTimeout(() => {
        this.draggingHistoryItem = item;
      }, 1);
      if(this.editing) {
        StudioStore.setSelectedItem('');
      }
    },
    dropHistoryItem(destination, e) {
      let currentIndex = this.draggingHistoryItem.getIndex();
      let destinationIndex = destination.getIndex();
      const topLevelToTopLevel = !this.draggingHistoryItem.parentLayer && !destination.parentLayer;
      const childToTopLevel = this.draggingHistoryItem.parentLayer && !destination.parentLayer && destination.effects.includes(this.draggingHistoryItem.parentLayer);

      if(topLevelToTopLevel) {
        if (currentIndex < destinationIndex) {
          if (this.draggingOverBottom) {
            destinationIndex--;
          }
        }
        else if (currentIndex > destinationIndex) {
          if(this.draggingOverTop) {
            destinationIndex++;
          }
        }
      }

      this.draggingHistoryItem.moveToPosition(destinationIndex, currentIndex, this.draggingOverBottom, this.draggingOverTop);
      this.draggingHistoryItem = null;
      this.draggingOverHistoryItem = null;
    },
    allowDrop(item, e) {
      let height = e.target.offsetHeight;
      let offset = e.pageY - e.target.getBoundingClientRect().top;

      let bottom = offset >= height/2;
      let top = offset < height/2;

      if(!this.draggingHistoryItem) {
        return;
      }
      
      if(this.draggingHistoryItem?.layerType === 'effect' && item.isElement) {
        bottom = offset >= height - height/3;
        top = offset < height/3;
      }

      this.draggingOverTop = top;
      this.draggingOverBottom = bottom;

      e.preventDefault();
      this.draggingOverHistoryItem = item;
    },
    updateEffectValue(id, prop, value) {
      this.$emit('update-value', id, prop, value);
    },

    handleContextMenuClose() {
      if(document.querySelector('.flyout-options')) {
        this.contextItem = null;
      }
    },
    handleContextMenu(e, item, prevent) {
      if(prevent) {
        e.preventDefault();
      }
      requestAnimationFrame(() => {
        this.contextItem = item;
        this.contextPos = {x: e.pageX - 10, y: e.pageY - 15};
      })
    },
    toggleProp(item, prop) {
      item.toggleProp(prop);
      this.$emit('update-item-state');
    },
    addStarterItem(type) {
      if(type === 'shape') {
        const effect = new Effect({
          type: 'sdf_shape',
          tint: new Vec3(0.58, 0.3333, 1),
          fillOpacity: 1,
          shape: 1,
          scale: 0.4,
          trackMouse: 0.5,
          mouseMomentum: 0.5,
          specular: 1,
          axis: new Vec3(0.4, 0.2, 0.5)
        });
        StudioStore.addItem(effect);
      } else {
        const text = new TextBox({
          fontSize: 218,
          fontStyle: '600',
          fontWeight: '400',
          fontFamily: 'Humane',
          fill: ['#ffffff'],
          fontCSS: {
              "src": "https://firebasestorage.googleapis.com/v0/b/unicorn-studio-fonts/o/Humane%202.0%2FHumane-SemiBold.ttf?alt=media&token=0585dab8-534a-462f-bfb8-5f924c455c92",
              "family": "Humane"
          },
          lineHeight: 211,
          textAlign: 'center',
          textContent: 'ADD AN EFFECT',
          left: 0.5,
          top: 0.5,
          leftMode: 'relative',
          topMode: 'relative',
          anchorPoint: 'center',
          width: 610,
          height: 211
        });
        StudioStore.addItem(text);
      }
      StudioStore.state.history.find(n => n.isBackground).fill = ['#000000'];
    }
  }
}
</script>

<template>
  <div class="history-section control-section-wrapper ml-1">
      <label class="parameter-label parameter-label__margin-bottom mt-3 ml-2">Layers</label>
      <div  class="history">
        <div class="history-scroll-wrap">
          <div class="history-scroll">
            <HistoryListItem
              v-for="(item, index) in historyOrdered" 
              :key="'item_' + index"
              :item="item"
              :index="index"
              :draggingHistoryItem="draggingHistoryItem"
              :draggingOverHistoryItem="draggingOverHistoryItem"
              :draggingOverTop="draggingOverTop"
              :draggingOverBottom="draggingOverBottom"
              :editing="editing"
              :label="getItemName(item)"
              :style="{'order': index}"
              @contextmenu="handleContextMenu($event, item, true)"
              @drag-start="handleDragStart"
              @drag-end="handleDragEnd"
              @drop-history-item="dropHistoryItem"
              @allow-drop="allowDrop"
              @edit-item="$emit('edit-item', item)"
              @update-item-state="updateItemState"
              @update-value="updateEffectValue"
              @open-context-menu="handleContextMenu($event, item)"
              @replace-image="$emit('replace-image', item.local.id)"
            ></HistoryListItem>
          </div>
        </div>
      </div>
      <ActionFlyout
        v-if="contextItem"
        :pos="contextPos"
        :item="contextItem"
        @delete-item="$emit('delete-item', $event)"
        @add-effect="$emit('add-effect', $event)"
      ></ActionFlyout>
    </div>
    <template v-if="!history.filter(n => !n.isBackground).length && initialized">
      <div class="grid control-section-wrapper mt-0 ml-1">
        <p class="font-secondary-color mb-1 ml-2 mt-2 inline-block">Don't know where to start?</p>
        <div class="inline-flex align-center suggested-add" @click="addStarterItem('shape')">
          <img src="/images/sdf_shape.png">
          <div class="ml-2">
            <p class="mt-0 mb-0">Add a 3d shape</p>
          </div>
        </div>
        <div class="inline-flex align-center suggested-add" @click="addStarterItem('text')">
          <img src="/images/ui/add_text.png">
          <div class="ml-2">
            <p class="mt-0 mb-0">Add some text</p>
          </div>
        </div>
      </div>
      <div class="grid control-section-wrapper mt-0 ml-1">
        <a class="inline-flex suggested-add tutorial" href="https://youtu.be/3h4YGu_w35E" target="_blank">
          <img src="/images/ui/watch_tut_2.png">
          <div class="mt-2">
            <p class="mt-0 mb-0">Watch a tutorial ↗</p>
          </div>
        </a>
      </div>
    </template>
    <template v-if="clonedTemplate">
      <div class="grid control-section-wrapper mt-0 ml-1">
        <a class="inline-flex suggested-add tutorial" href="https://youtu.be/3h4YGu_w35E" target="_blank">
          <img src="/images/ui/watch_tut_2.png">
          <div class="mt-2">
            <p class="mt-0 mb-0">Watch a tutorial ↗</p>
          </div>
        </a>
      </div>
    </template>
</template>

<style lang="scss">

  .history-section {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.control-section-wrapper {
      max-height: 93.5vh;
    }

    .color-container {
      transform-origin: 100% 100%;
    }
  }

  .history {
    height: 100%;
    overflow: auto;
    .brush-container,
    .pattern-container {
      left: 22rem;
    }
  }

  .history-scroll-wrap {
    height: 100%;
  }

  .history-scroll {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0.5rem;
    justify-content: flex-end;
  }

  .small-pad {
    padding: 0.5rem 0;
  }

  .suggested-add {
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.4rem;

    img {
      width: 4rem;
      border-radius: 0.4rem;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--accent-color);
    }
  }

  .suggested-add.tutorial {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    img {
      width: 100%;
      border-radius: 0.4rem;
    }
  }
</style>
