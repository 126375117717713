<script>
import InputField from './InputField.vue';
import Button from './Button.vue';
import { UserStore } from '../stores/UserStore.js';

export default {
  components: {
    InputField,
    Button
  },
  data() {
    return {
      UserStore,
      usernameInput: '',
      usernameStatus: null,
      usernameMessage: '',
      isCheckingUsername: false,
      usernameSet: false,
      isEditing: false,
      isUpdating: false
    }
  },
  emits: ['update:username'],
  mounted() {
    this.usernameInput = UserStore.username || UserStore.displayUsername || '';
    
    if (this.usernameInput) {
      this.usernameStatus = 'success';
      this.usernameSet = true;
    }
    
  },
  watch: {
    'UserStore.username': {
      handler(newVal) {
        if (newVal) {
          this.usernameInput = newVal;
          this.usernameSet = true;
          this.isEditing = false;
        }
      }
    }
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.usernameInput = UserStore.username || UserStore.displayUsername || '';
        this.usernameStatus = 'success';
        this.usernameMessage = 'Current username';
      }
    },
    async checkUsername(value) {
      console.log('Checking username:', value);
      if(!value) {
        this.usernameStatus = '';
        this.usernameMessage = '';
        return;
      };
      this.usernameInput = value;
      
      if (!this.usernameInput || this.usernameInput.trim() === '') {
        this.usernameStatus = null;
        this.usernameMessage = '';
        return;
      }
      
      if (this.usernameInput.includes(' ')) {
        this.usernameStatus = 'error';
        this.usernameMessage = 'Username cannot contain spaces';
        return;
      }
      
      if (this.usernameInput.length < 3) {
        this.usernameStatus = 'error';
        this.usernameMessage = 'Username must be at least 3 characters';
        return;
      }
      
      const usernameRegex = /^[a-zA-Z0-9_-]{3,20}$/;
      if (!usernameRegex.test(this.usernameInput)) {
        this.usernameStatus = 'error';
        this.usernameMessage = 'Username can only contain letters, numbers, underscores, and hyphens';
        return;
      }
      
      if (this.usernameInput.toLowerCase() === UserStore.username) {
        this.usernameStatus = 'success';
        this.usernameMessage = 'Current username';
        return;
      }
      
      this.isCheckingUsername = true;
      this.usernameStatus = 'loading';
      this.usernameMessage = 'Checking availability...';
      
      try {
        const result = await UserStore.checkUsernameAvailability(this.usernameInput);
        console.log('Username availability result:', result);
        
        if (result && result.success && result.available) {
          this.usernameStatus = 'success';
          this.usernameMessage = 'Username is available';
        } else if (result && result.success === false) {
          this.usernameStatus = 'error';
          this.usernameMessage = result.message || 'Username is not available';
        } else {
          this.usernameStatus = 'error';
          this.usernameMessage = 'Error checking username';
        }
      } catch (error) {
        console.error('Error checking username:', error);
        this.usernameStatus = 'error';
        this.usernameMessage = 'Error checking username';
      } finally {
        this.isCheckingUsername = false;
      }
    },
    async updateUsername() {
      console.log('Updating username:', {
        input: this.usernameInput,
        current: UserStore.username,
        status: this.usernameStatus
      });
      
      if (this.usernameInput.toLowerCase() === UserStore.username) {
        this.isEditing = false;
        return;
      }
      
      if (this.usernameStatus !== 'success') {
        console.log('Username not valid, status:', this.usernameStatus);
        return;
      }
      
      this.isUpdating = true;
      
      try {
        console.log('Calling UserStore.updateUsername with:', this.usernameInput);
        const result = await UserStore.updateUsername(this.usernameInput);
        console.log('Update username result:', result);
        
        if (result && result.success) {
          this.usernameStatus = 'success';
          this.usernameMessage = 'Username updated successfully';
          this.usernameSet = true;
          this.isEditing = false;
          this.$emit('update:username', this.usernameInput);
          
          this.usernameInput = UserStore.username || UserStore.displayUsername || '';
        } else {
          this.usernameStatus = 'error';
          this.usernameMessage = result?.message || 'Failed to update username';
        }
      } catch (error) {
        console.error('Error updating username:', error);
        this.usernameStatus = 'error';
        this.usernameMessage = 'Error updating username';
      } finally {
        this.isUpdating = false;
      }
    }
  }
}
</script>

<template>
  <div class="username-editor">
    <div v-if="usernameSet && !isEditing" class="flex w-100 mt-1">
      <InputField
        v-model="usernameInput"
        :value="usernameInput"
        :disabled="true"
        output="string"
        class="w-100"
      />
      <Button 
        class="secondary font-secondary-color small ml-2" 
        @click="toggleEdit"
      >
        Change
      </Button>
    </div>
    
    <div v-else class="flex w-100 mt-1">
      <InputField
        v-model="usernameInput"
        :value="usernameInput"
        placeholder="Choose a unique username"
        output="string"
        class="w-100"
        @change="checkUsername"
      />
      <Button 
        class="secondary font-secondary-color small ml-2" 
        @click="updateUsername"
        :disabled="usernameStatus !== 'success' || UserStore.username === usernameInput.toLowerCase()"
        :loading="isUpdating"
      >
        <span v-if="UserStore.username">Update</span>
        <span v-else>Save</span>
      </Button>
    </div>
    
    <p v-if="usernameMessage" class="username-message mt-1" :class="usernameStatus">
      {{ usernameMessage }}
    </p>
    <p v-if="!usernameMessage" class="p mt-1 font-secondary-color" style="font-size: 1.2rem;">
      Your username will be visible to others when you share projects.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.username-editor {
  font-size: 1.4rem;
}

.button-group {
  display: flex;
}

.username-message {
  margin-bottom: 0;
  
  &.success {
    color: var(--success-color, #4CAF50);
  }
  
  &.error {
    color: var(--error-color, #F44336);
  }
  
  &.loading {
    color: var(--font-secondary-color);
  }
}
</style> 