import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform sampler2D uCustomTexture;

uniform vec2 uPos;
uniform vec3 uAxis;
uniform vec3 uLightPosition;
uniform float uWidth;
uniform float uRefraction;
uniform float uVariation;
uniform vec2 uTwist;
uniform float uReflect;
uniform float uSpecular;
uniform float uFresnel;
uniform float uDispersion;
uniform float uRoughness;
uniform float uRounding;
uniform float uOpacity;
uniform float uFrost;
uniform float uThickness;
uniform float uTrackMouseMove;
uniform float uTextureAmount;
uniform int uShowBg;
uniform vec3 uTint;
uniform float uTime;
uniform float uFrequency;
uniform float uAmplitude;
${UNIVERSAL_UNIFORMS}

const float PI = 3.141592653;
const float PI2 = 6.283185306;
const float DISP_STEPS = 12.;
const vec3 viewDir = vec3(0,0, -4.25);

ivec2 customTexSize;
float customTexAspect;

const mat3 ROT_Y_90 = mat3(
  0.0, 0.0, 1.0,
  0.0, 1.0, 0.0,
  -1.0, 0.0, 0.0
);

const mat3 ROT_Z_90 = mat3(
  0.0, -1.0, 0.0,
  1.0,  0.0, 0.0,
  0.0,  0.0, 1.0 
);

const mat3 ROT_X_90 = mat3(
  1.0,  0.0,  0.0,
  0.0,  0.0, -1.0,
  0.0,  1.0,  0.0 
);

float rand(vec2 co) {
  return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 rgb(float r, float g, float b) {
    return 1.0 - vec3(r / 255.0, g / 255.0, b / 255.0);
}

mat3 rotY(float ang) {
    float c = cos(ang), s = sin(ang);
    return mat3(c, 0.0, s, 0.0, 1.0, 0.0, -s, 0.0, c);
}

mat3 rotX(float ang) {
    float c = cos(ang), s = sin(ang);
    return mat3(1.0, 0.0, 0.0, 0.0, c, -s, 0.0, s, c);
}

mat3 rotZ(float ang) {
  float c = cos(ang), s = sin(ang);
  return mat3(c, -s, 0.0, s, c, 0.0, 0.0, 0.0, 1.0);
}


mat2 rot(float a) {
  float s = sin(a);
  float c = cos(a);
  return mat2(c, -s, s, c);
}

vec3 twistY(vec3 p, float amount) {
    float c = cos(amount * p.y);
    float s = sin(amount * p.y);
    mat2 m = mat2(c, -s, s, c);
    return vec3(m * p.xz, p.y);
}

vec3 twistX(vec3 p, float amount) {
  float c = cos(amount * p.x);
  float s = sin(amount * p.x);
  mat2 m = mat2(c, -s, s, c);
  return vec3(p.x, m * p.yz);
}

float unionSdf(float a, float b) {
    return min(a, b);
}

float subtractSdf( float d1, float d2 ) {
    return max(-d1,d2);
}

float sdf_blend(float d1, float d2, float a) {
    return a * d1 + (1. - a) * d2;
}

float opSmoothSubtraction( float d1, float d2, float k ) {
    float h = clamp( 0.5 - 0.5*(d2+d1)/k, 0.0, 1.0 );
    return mix( d2, -d1, h ) + k*h*(1.0-h);
}

float opSmoothUnion( float d1, float d2, float k ) {
    float h = clamp( 0.5 + 0.5*(d2-d1)/k, 0.0, 1.0 );
    return mix( d2, d1, h ) - k*h*(1.0-h);
}

float smin(float a, float b, float k) {
  float h = max(k - abs(a - b), 0.0);
  return min(a, b) - h * h * 0.25 / k;
}

float opExtrusion(vec3 p, float d, float h) {
  vec2 w = vec2( d, abs(p.z) - h );
  return min(max(w.x,w.y),0.0) + length(max(w,0.0));
}

vec3 getAdjustedP(vec3 p) {
  vec2 twist = uTwist;

  p.xy *= vec2(uResolution.x / uResolution.y, 1);

  p *= (1. + (uRounding + 0.01) * 0.5);

  vec2 mousePos = mix(vec2(0), uMousePos - 0.5, uTrackMouse);
  vec2 axis = vec2(-1. * uAxis.y - 1. + mousePos.y/PI, uAxis.x + mousePos.x/PI) * 2.;

  float baseTime = uTime * 0.02;

  mat3 rotYMat = rotY(axis.y * PI);
  mat3 rotXMat = rotX(axis.x * PI);
  mat3 rotZMat = rotZ(uAxis.z * 2.0 * PI);

  mat3 combinedRotation = rotZMat * rotYMat * rotXMat;

  p = combinedRotation * p;
    
  p = mix(p, twistY(p, -1.0 * twist.y), step(0.0, abs(twist.y)));
  p = mix(p, twistX(p, -1.0 * twist.x), step(0.0, abs(twist.x)));

  return p;
}

float sdfBox(vec3 p, vec3 b) {
  vec3 d = abs(p) - b;
  return length(max(d,0.0)) + min(max(d.x,max(d.y,d.z)),0.0);
}

float sdfStrip(vec3 p) {
  vec3 origP = p;
  
  p *= ROT_Z_90;
  p *= ROT_Y_90;

  float frequency = uFrequency * 10.0;
  float amplitude = uAmplitude * 1.;

  float wave1 = sin(origP.x * frequency + uTime * 0.05) * amplitude;
  float wave2 = cos((origP.x + uVariation) * frequency * 0.75 + uTime * 0.075) * amplitude;
  
  p.x += (wave1 + wave2) * 0.2;
  p.y += (wave1 + wave2) * 0.3;
  
  float d = sdfBox(p, vec3(uThickness * 0.1, 20., uWidth));
  
  return d;
}

float getMergedSDF(vec3 p) {
  return sdfStrip(getAdjustedP(p));
}

float fresnel(vec3 eyeVector, vec3 worldNormal, float power) {
  float fresnelFactor = 1.0 - abs(dot(eyeVector, worldNormal));
  return pow(fresnelFactor, power);
}

float specular(vec3 viewDir, vec3 lightDir, vec3 normal, float shininess, float diffuseness) {
    vec3 halfVector = normalize(viewDir + lightDir);
    return pow(max(0.0, dot(normal, halfVector)), shininess) * diffuseness;
}

vec3 noFrostOrDispersion(vec3 rd, vec3 normal) {
  float ior = 1.0 / (1.0 + uRefraction * 0.25);
  vec3 refractedRay = refract(rd, normal, ior);
  return texture(uTexture, vTextureCoord - refractedRay.xy).rgb;
}

vec3 frostOrDispersion(vec3 rd, vec3 normal) {
  vec3 refractionColor = vec3(0);
  float iorBase = 1. + uRefraction * 0.25;

  vec3 dispCoefficients = vec3(0.03, 0.06, 0.1) * uDispersion * 1.2;

  for(float i = 0.; i < DISP_STEPS; i++) {
    float step = i / DISP_STEPS;
    
    vec3 disp = step * dispCoefficients;
    vec3 ior = 1.0 / (iorBase + disp);

    vec3 refractedRayR = refract(rd, normal, ior.r);
    vec3 refractedRayG = refract(rd, normal, ior.g);
    vec3 refractedRayB = refract(rd, normal, ior.b);

    // #ifelseopen
    if (uFrost > 0.0) {
      vec2 rayDirOffset = vec2(
        rand(rd.xy + step) - 0.5,
        rand(rd.xy + step + 2.) - 0.5
      ) * 0.5;
      
      refractedRayR.xy += rayDirOffset * (0.1 + disp.r) * uFrost;
      refractedRayG.xy += rayDirOffset * (0.1 + disp.g) * uFrost;
      refractedRayB.xy += rayDirOffset * (0.1 + disp.b) * uFrost;
    }
    // #ifelseclose

    refractionColor.r += texture(uTexture, vTextureCoord - refractedRayR.xy).r;
    refractionColor.g += texture(uTexture, vTextureCoord - refractedRayG.xy).g;
    refractionColor.b += texture(uTexture, vTextureCoord - refractedRayB.xy).b;
  }

  return clamp(refractionColor / DISP_STEPS, 0.0, 1.0);
}

vec3 calculateNormal(vec3 p, float eps) {
  const vec3 k0 = vec3(1.0, -1.0, -1.0);
  const vec3 k1 = vec3(-1.0, -1.0, 1.0);
  const vec3 k2 = vec3(-1.0, 1.0, -1.0);
  const vec3 k3 = vec3(1.0, 1.0, 1.0);
  
  return normalize(
      k0 * getMergedSDF(p + k0 * eps) +
      k1 * getMergedSDF(p + k1 * eps) +
      k2 * getMergedSDF(p + k2 * eps) +
      k3 * getMergedSDF(p + k3 * eps)
  );
}

vec3 sampleTexture(vec3 rd, vec3 normal) {
  // #ifelseopen
  if(uOpacity == 1.0) {
    return vec3(0);
  }
  // #ifelseclose

  // #ifelseopen
  if(uDispersion > 0.0) {
    return frostOrDispersion(rd, normal);
  }
  // #ifelseclose

  // #ifelseopen
  if(uFrost > 0.0) {
    return frostOrDispersion(rd, normal);
  }
  // #ifelseclose
  
  return noFrostOrDispersion(rd, normal);
}

float scene(vec3 p) {
  return max(0.0000000001, getMergedSDF(p) - (uRounding * 0.5 + 0.01));
}

const int STEPS = 128;
const float MAX_DISTANCE = 100.0;

vec4 rayMarch(vec3 ro, vec3 rd) {
  float pixelSize = 0.005;
  float traveled = 0.;
  vec3 entryPoint = vec3(0.0);
  vec3 entryNormal = vec3(0.0);
  float partialAlpha = 0.0;

  for (int i = 0; i < STEPS; ++i) {
    vec3 currentPos = ro + rd * traveled;
    float distance = scene(currentPos);
    float progress = float(i)/float(STEPS);
        
    float step = distance * mix(1., 2., progress);

    if (distance > MAX_DISTANCE) break;

    if (distance < pixelSize) {
      partialAlpha = 1.;
      entryPoint = currentPos;
      entryNormal = calculateNormal(entryPoint, pixelSize * 0.5);
      break;
    }

    traveled += max(step, pixelSize);
    
    if (traveled > MAX_DISTANCE) break;
  }

  if (partialAlpha == 0.0) {
    return uShowBg == 1 ? texture(uTexture, vTextureCoord) : vec4(0);
  }

  vec4 bg = texture(uTexture, vTextureCoord);

  vec3 samplePosition = mix(rd, entryPoint, uReflect);
  vec3 refractionColor = sampleTexture(samplePosition, entryNormal);
  
  vec3 lightDir = vec3(((vec2(uLightPosition.x, 1.-uLightPosition.y) - 0.333) * 3.) - uPos, uLightPosition.z);
  vec3 normLightDir = normalize(lightDir);

  float lightAndShadow = dot(entryNormal, normLightDir);
  vec3 lightColor = mix(vec3(1), uTint, 1. - uOpacity);

  vec3 fresnelEffect = fresnel(rd, entryNormal, 8.0) * uFresnel * uTint;

  vec3 halfwayDir = normalize(lightDir + rd);
  float specFactor = pow(max(dot(entryNormal, halfwayDir), 0.0), 64.0 * uSpecular + 0.01);
  vec3 specularEffect = specFactor * uSpecular * lightColor;

  vec3 combinedEffects = fresnelEffect + specularEffect;
  vec3 finalColor = mix(refractionColor, uTint * lightAndShadow, uOpacity);
  finalColor += combinedEffects;

  vec4 outputColor = mix(bg, vec4(finalColor, 1.), partialAlpha);

  return outputColor;
}

out vec4 fragColor;

void main() {
  vec4 col = vec4(0);

  if(uWidth <= 0.0001) {
    col = vec4(0);
    if(uShowBg == 1) {
      col = texture(uTexture, vTextureCoord);
    }
    fragColor = col;
    return;
  }

  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouseMove);
  vec2 uv = vTextureCoord - pos;
  float fovFactor = tan(radians(20.) * 0.5);
  vec3 rd = vec3(uv * fovFactor, 0.5);
  col = rayMarch(viewDir, rd);
  float dither = (rand(vTextureCoord.xy) - 0.5) / 255.0;
  col += dither;
  ${computeFragColor('col')}
}
`;

const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: 'float',
    premultiplyAlpha: true,
  },
  uniforms: {
    refraction: {
      name: 'uRefraction',
      type: '1f',
      value: 0.5,
    },
    width: {
      name: 'uWidth',
      type: '1f',
      value: 0.5,
    },
    variation: {
      name: 'uVariation',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    twist: {
      name: 'uTwist',
      type: '2f',
      value: new Vec2(0.5, 0),
    },
    lightPosition: {
      name: 'uLightPosition',
      type: '3f',
      value: new Vec3(0.25, 0.25, -3),
    },
    dispersion: {   
      name: 'uDispersion',
      type: '1f',
      value: 0.25,
    },
    specular: {
      name: 'uSpecular',
      type: '1f',
      value: 0.5,
    },
    fresnel: {
      name: 'uFresnel',
      type: '1f',
      value: 0.5,
    },
    frost: {
      name: 'uFrost',
      type: '1f',
      value: 0,
    },
    reflect: {
      name: 'uReflect',
      type: '1f',
      value: 0,
    },
    fillOpacity: {
      name: 'uOpacity',
      type: '1f',
      value: 0,
    },
    rounding: {
      name: 'uRounding',
      type: '1f',
      value: 0.0,
    },
    thickness: {
      name: 'uThickness',
      type: '1f',
      value: 0.1,
    },
    distort: {
      name: 'uDistort',
      type: '1f',
      value: 0,
    },
    showBg: {
      name: 'uShowBg',
      type: '1i',
      value: 1,
    },
    trackMouseMove: {
      name: 'uTrackMouseMove',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    twist: {
      name: 'uTwist',
      type: '2f',
      value: new Vec2(0.5, 0),
    },
    axis: {
      name: 'uAxis',
      type: '3f',
      control: 'rotation',
      value: new Vec3(0.55),
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(1),
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 1.0,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    ...universalUniformParams,
  },
};

export const SDF_STRIP = {
  id: 'sdf_strip',
  label: '3D Strip',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    axis: {
      label: 'Axis',
      value: new Vec3(0.55),
      min: 0,
      max: 1,
      step: 0.0027,
      control: 'rotation',
      output: 'degrees',
    },
    width: {
      label: 'Width',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    thickness: {
      label: 'Thickness',
      value: 0.1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the depth of 2D shapes',
    },
    twist: {
      label: 'Twist',
      value: new Vec2(0),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    rounding: {
      label: 'Rounding',
      value: 0.0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Subtracts distance from the sdf to give a rounder and puffier appearance.',
    },
    variation: {
      label: 'Variation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: "Modifies the phase of the wave",
    },
    showBg: {
      label: 'Show background',
      value: 1,
      classic: true,
      options: {
        1: 'On',
        0: 'Off',
      },
      tooltip: 'Include or hide the underlying layers',
      eventDisabled: true,
    },
    frequency: {
      header: 'Wave',
      label: 'Frequency',
      value: 0.5,
      min: 0.01,
      max: 1.0,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls how many waves appear along the strip',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.5,
      min: 0.01,
      max: 1.0,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the height of the waves',
    },
    refraction: {
      label: 'Amount',
      specificLabel: 'Refract. amount',
      header: 'Refraction / Reflection',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    reflect: {
      label: 'Behavior',
      value: 0,
      radio: true,
      options: [
        { value: 0, label: 'Refract' },
        { value: 1, label: 'Reflect' },
      ],
      tooltip: 'Refract for a glass material, reflect for a mirror material.',
      responsiveDisabled: true,
      eventDisabled: true,
    },
    dispersion: {
      label: 'Dispersion',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of chromatic dispersion. Does not scale with amount of refraction.',
    },
    frost: {
      label: 'Roughness',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the graininess of light passing through the object.',
    },
    specular: {
      label: 'Specular',
      header: 'Light',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount and diffusion of specular light, or the "shiniess" of the object.',
    },
    fresnel: {
      label: 'Fresnel',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the ambient reflectivity of the object',
    },
    lightPosition: {
      label: 'Position',
      value: new Vec3(0.25, 0.25, -3),
      specificLabel: 'Light position',
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      control: 'rotation',
    },
    tint: {
      label: 'Color',
      value: new Vec3(1),
      output: 'color',
      tooltip:
        'Controls the tint of the lighting effects, as well as the color of the shape if opaqueness is greater than 0.',
      responsiveDisabled: true,
    },
    fillOpacity: {
      label: 'Opaqueness',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Fills the object with a solid color',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    trackMouseMove: {
      label: 'Track mouse',
      header: 'Interactivity',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount to which the mouse cursor controls the position of the object',
    },
    mouseMomentum: {
      label: 'Momentum',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount of drag or delay of the track mouse effect',
    },
    trackMouse: {
      label: 'Axis tilt',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount to which the mouse cursor controls the position of the axis',
    }
  },
};
