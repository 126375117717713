<script>
import { PRIMARY_COLOR, PRIMARY_COLOR_ALPHA_25 } from '../scripts/Constants.js';
import { rotate, getShapeBoundingBox } from '../scripts/Draw.js';
import { EFFECTS } from '../scripts/Shaders.js';
import { getPointDistToLine, throttle } from '../scripts/Helpers.js';
import { getAnchorOffsets } from '../scripts/layerTypes/Element.js';
import { StudioStore } from '../stores/StudioStore.js';
import ActionFlyout from './ActionFlyout.vue';
import { Vec2, Vec3 } from 'curtainsjs';

const EFFECT_COLOR = PRIMARY_COLOR;
const RAIL_WIDTH = 0;
const EDITOR_WIDTH = 700;

function getDimensionValue(item, dimension, rawValue) {
  if (item[dimension + 'Mode'] === 'fixed') {
    return rawValue;
  } else {
    // Convert raw pixels to relative value (0-1)
    const containerSize = dimension === 'height' ? StudioStore.state.canvasHeight : StudioStore.state.canvasWidth;
    return rawValue / containerSize;
  }
}

function dragEdgeOrHandle(event, mouse) {
  const item = event.item;
  const isTextBox = item.layerType === 'text';
  const isShiftPressed = StudioStore.state.hotkeys.isPressed('shift');

  if(isShiftPressed) {
    item.setDimensionMode('height', 'auto');
  }

  let deltaX = Math.round(mouse.downPos.x + mouse.delta.x);
  let deltaY = Math.round(mouse.downPos.y + mouse.delta.y);

  if(!event.box) {
    return;
  }

  const originalWidth = event.box.width;
  const originalHeight = event.box.height;
  const originalLineHeight = item.fontSizeMode === 'fixed' ? event.lineHeightStart : event.lineHeightStart * (item.fontSize * StudioStore.state.canvasWidth);
  const originalFontSize = item.fontSizeMode === 'fixed' ? event.fontSizeStart : event.fontSizeStart * StudioStore.state.canvasWidth;

  // Get anchor offsets
  const anchorOffsets = getAnchorOffsets(item.anchorPoint);
  const anchorOffsetX = anchorOffsets.x * originalWidth;
  const anchorOffsetY = anchorOffsets.y * originalHeight;

  const [mouseX, mouseY] = rotate(event.box.anchor.x, event.box.anchor.y, deltaX, deltaY, item.rotation * 360);


  // Calculate actual position taking into account anchor point
  const actualX = event.box.left - anchorOffsetX;
  const actualY = event.box.top - anchorOffsetY;

  let newWidth,
    newHeight,
    newX = event.box.left / StudioStore.state.canvasWidth,
    newY = event.box.top / StudioStore.state.canvasHeight;

  // Helper function to calculate new position based on resize
  const calculateTopEdgePosition = newHeight => {
    if (item.anchorPoint.toLowerCase().includes('center') && !item.anchorPoint.toLowerCase().includes('bottom')) {
      return (
        (event.box.top - newHeight / 2 + event.box.height / 2) /
        StudioStore.state.canvasHeight
      );
    }
    if (item.anchorPoint.toLowerCase().includes('top')) {
      return (
        (event.box.top - newHeight + event.box.height) /
        StudioStore.state.canvasHeight
      );
    }
    return event.box.top / StudioStore.state.canvasHeight;
  };

  const calculateBottomEdgePosition = newHeight => {
    if (item.anchorPoint.toLowerCase().includes('center') && !item.anchorPoint.toLowerCase().includes('top')) {
      return (
        (event.box.top + newHeight / 2 - event.box.height / 2) /
        StudioStore.state.canvasHeight
      );
    }
    if (item.anchorPoint.toLowerCase().includes('bottom')) {
      return (
        (event.box.top + newHeight - event.box.height) /
        StudioStore.state.canvasHeight
      );
    }
    return event.box.top / StudioStore.state.canvasHeight;
  };

  const calculateRightEdgePosition = newWidth => {
    if (item.anchorPoint.toLowerCase().includes('center') && !item.anchorPoint.toLowerCase().includes('left')) {
      return (
        (event.box.left + newWidth / 2 - event.box.width / 2) /
        StudioStore.state.canvasWidth
      );
    }
    if (item.anchorPoint.toLowerCase().includes('right')) {
      return (
        (event.box.left + newWidth - event.box.width) / StudioStore.state.canvasWidth
      );
    }
    return event.box.left / StudioStore.state.canvasWidth;
  };

  const calculateLeftEdgePosition = newWidth => {
    if (item.anchorPoint.toLowerCase().includes('center') && !item.anchorPoint.toLowerCase().includes('right')) {
      const divisor = item.anchorPoint.toLowerCase().includes('left') ? 1 : 2;
      return (
        (event.box.left - newWidth / divisor + event.box.width / divisor) /
        StudioStore.state.canvasWidth
      );
    }
    if (item.anchorPoint.toLowerCase().includes('left')) {
      return (
        (event.box.left - newWidth + event.box.width) / StudioStore.state.canvasWidth
      );
    }
    return event.box.left / StudioStore.state.canvasWidth;
  };

  if ('corner' in event) {
    // Calculate positions using anchor-adjusted values
    const startX = actualX;
    const startY = actualY;
    const endX = startX + originalWidth;
    const endY = startY + originalHeight;

    if (event.corner === 0) {
      // Top-left
      const anchorX = endX;
      const anchorY = endY;
      newWidth = anchorX - mouseX;
      newHeight = anchorY - mouseY;
      newY = calculateTopEdgePosition(newHeight);
      newX = calculateLeftEdgePosition(newWidth);
    } else if (event.corner === 1) {
      // Top-right
      const anchorX = startX;
      const anchorY = endY;
      newWidth = mouseX - anchorX;
      newHeight = anchorY - mouseY;
      newY = calculateTopEdgePosition(newHeight);
      newX = calculateRightEdgePosition(newWidth);
    } else if (event.corner === 2) {
      // Bottom-right
      const anchorX = startX;
      const anchorY = startY;
      newWidth = mouseX - anchorX;
      newHeight = mouseY - anchorY;
      newY = calculateBottomEdgePosition(newHeight);
      newX = calculateRightEdgePosition(newWidth);

      let newFontSize = originalFontSize + (newHeight - originalHeight)/2;
      if(item.fontSizeMode === 'relative') {
        newFontSize = newFontSize / StudioStore.state.canvasWidth;
      }
      item.fontSize = newFontSize;
    } else if (event.corner === 3) {
      // Bottom-left
      const anchorX = endX;
      const anchorY = startY;
      newWidth = anchorX - mouseX;
      newHeight = mouseY - anchorY;
      newY = calculateBottomEdgePosition(newHeight);
      newX = calculateLeftEdgePosition(newWidth);
    }
  } else {
    // Edge dragging
    if (event.edge === 0) {
      // Top edge
      newHeight = originalHeight - (mouseY - actualY);
      newY = calculateTopEdgePosition(newHeight);
    } else if (event.edge === 1) {
      // Right edge
      newWidth = mouseX - actualX;
      newX = calculateRightEdgePosition(newWidth);
    } else if (event.edge === 2) {
      // Bottom edge
      newHeight = mouseY - actualY;
      newY = calculateBottomEdgePosition(newHeight);
    } else if (event.edge === 3) {
      // Left edge
      newWidth = originalWidth - (mouseX - actualX);
      newX = calculateLeftEdgePosition(newWidth);
    }
  }

  // Update item properties at the end
  item.left = newX;
  item.top = newY;

  if (newWidth !== undefined) {
    if(item.widthMode === 'auto') {
      let { width, height } = item.getAbsoluteDimensions();
      let aspect = width / height;
      item.height = getDimensionValue(item, 'height', newWidth * aspect);
    } else {
      item.width = getDimensionValue(item, 'width', newWidth);
    }
  }
  if (newHeight !== undefined) {
    if(isTextBox) {
      let newLineHeight = originalLineHeight + (newHeight - originalHeight)/2;
      if(item.fontSizeMode === 'relative') {
        newLineHeight = newLineHeight / (item.fontSize * StudioStore.state.canvasWidth);
      }
      item.lineHeight = newLineHeight;
    } else {
      if(item.heightMode === 'auto') {
        let { width, height } = item.getAbsoluteDimensions();
        let aspect = width / height;
        item.width = getDimensionValue(item, 'width', newHeight * aspect);
      } else {
        item.height = getDimensionValue(item, 'height', newHeight);
      }
    }
  }
}

function calculateCursorIndex(factor, rot, offset) {
  return Math.round((factor * 90 + rot + offset) / 45) % 8;
}

function roundXToNearestY(x, y) {
  return Math.ceil(x / y) * y;
}

function dotProduct(ax, ay, bx, by) {
  return ax * bx + ay * by;
}

function normalize(ax, ay) {
  const length = Math.sqrt(ax * ax + ay * ay);
  return { x: ax / length, y: ay / length };
}

function projectPolygon(axis, polygon) {
  let min = dotProduct(axis.x, axis.y, polygon[0][0], polygon[0][1]);
  let max = min;
  for (let i = 1; i < polygon.length; i++) {
    let p = dotProduct(axis.x, axis.y, polygon[i][0], polygon[i][1]);
    if (p < min) {
      min = p;
    } else if (p > max) {
      max = p;
    }
  }
  return { min, max };
}

function polygonsOverlap(polygon1, polygon2) {
  const polygons = [polygon1, polygon2];
  for (let i = 0; i < polygons.length; i++) {
    for (let j = 0; j < polygons[i].length; j++) {
      let next = j + 1 === polygons[i].length ? 0 : j + 1;
      let edge = { x: polygons[i][j][0] - polygons[i][next][0], y: polygons[i][j][1] - polygons[i][next][1] };
      let axis = normalize(-edge.y, edge.x);
      let projection1 = projectPolygon(axis, polygon1);
      let projection2 = projectPolygon(axis, polygon2);
      if (projection1.max < projection2.min || projection2.max < projection1.min) {
        return false; // No overlap on this axis
      }
    }
  }
  return true; // Overlap on all axes
}

function calculateTotalBoundingBox(rects) {
  let minX = rects[0][0][0];
  let minY = rects[0][0][1];
  let maxX = rects[0][0][0];
  let maxY = rects[0][0][1];

  rects.forEach(rect => {
    rect.forEach(([x, y]) => {
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
    });
  });

  // Return the bounding box as four points: top-left, top-right, bottom-right, bottom-left
  return [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
  ];
}

export default {
  props: ['updater', 'cW', 'cH'],
  components: {
    ActionFlyout,
  },
  data() {
    return {
      ctx: '',
      event: {},
      contextItem: null,
      contextPos: null,
      canvasWidth: (window.innerWidth - RAIL_WIDTH * 2) * 2,
      canvasHeight: window.innerHeight * 2,
      corners: [
        [-1, -1],
        [1, -1],
        [1, 1],
        [-1, 1],
      ],
      resizeCursors: [
        'nwse-resize',
        'ns-resize',
        'nesw-resize',
        'ew-resize',
        'nwse-resize',
        'ns-resize',
        'nesw-resize',
        'ew-resize',
      ],
      mouseDownX: null,
      mouseDownY: null,
      isMultiSelecting: false,
      selectionBoxStart: { x: 0, y: 0 },
      selectionBoxEnd: { x: 0, y: 0 },
      selectionBoundingBox: [],
      selectedItemStartPositions: [],
      textEditWindow: null,
      clickToEditText: false,
      sizeKeys: {
        image: 'size',
        ripple: 'frequency',
        blinds: 'frequency',
        chromab: 'amount',
        beam: 'radius',
        bokeh: 'radius',
        blur: 'amount',
        bloom: 'amount',
        caustics: 'scale',
        polar: 'scale',
        sdf_strip: 'width',
        mouseDraw: 'radius',
        projection: 'scale',
        bulge: 'scale',
        reflectiveSurface: 'amount',
        pixelate: 'amount',
        scan: 'amount',
        ascii: 'scale',
        godrays: 'amount',
        neon: 'amount',
        custom: 'scale',
        extend: 'scale',
        landscape: 'scale',
        stars: 'scale',
        texturize: 'amount',
        doublepointlight: 'scale',
        spotlight: 'scale',
        noiseBlur: 'scale',
        waterRipple: 'scale',
        halftone: 'amount',
        noise: 'frequency',
        hologram: 'frequency',
        liquify: 'frequency',
        diffuse: 'amount',
        pattern: 'scale',
        wisps: 'scale',
        guilloche: 'scale',
        gradient: 'scale',
        gradientFill: 'scale',
        twodlight: 'scale',
        gradientMap: 'scale',
        mouse: 'radius',
        marquee: 'scale',
        retro_screen: 'scale',
        flowField: 'spread',
        replicate: 'amount',
        sphere: 'radius',
        sine: 'frequency',
        sdf_shape: 'scale',
        voronoi: 'amount',
        progressiveBlur: 'amount',
        angle: 'angle',
        posterize: 'smoothness',
        fbm: 'frequency',
        swirl: 'radius',
        noiseField: 'spread',
        vignette: 'radius',
        zoomBlur: 'amount',
        video: 'scale',
      },
      angleKeys: {
        chromab: 'angle',
        pattern: 'angle',
        custom: 'angle',
        stars: 'angle',
        extend: 'angle',
        beam: 'angle',
        fbm: 'angle',
        bulge: 'angle',
        doublepointlight: 'angle',
        caustics: 'angle',
        replicate: 'angle',
        hologram: 'angle',
        noiseBlur: 'angle',
        noise: 'angle',
        guilloche: 'angle',
        gradient: 'gradientAngle',
        gradientFill: 'gradientAngle',
        blinds: 'angle',
        marquee: 'angle',
        swirl: 'phase',
        sdf_shape: 'axis.z',
        scan: 'angle',
        halftone: 'rotation',
        progressiveBlur: 'angle',
        wisps: 'angle',
        polar: 'angle',
        neon: 'angle',
        voronoi: 'angle',
        liquify: 'angle',
        video: 'angle',
      },
      snapGuides: {
        vertical: [],
        horizontal: []
      },
      SNAP_THRESHOLD: 5, // pixels
      GUIDE_COLOR: '#F62A59',
      cachedPaths: new Map()
    };
  },
  computed: {
    offsetLeft() {
      return this.customCodeItemId
        ? StudioStore.state.panLeft
        : (window.innerWidth - this.width) / 2 + StudioStore.state.panLeft;
    },
    offsetTop() {
      return (window.innerHeight - this.height) / 2 + StudioStore.state.panTop;
    },
    dragging() {
      return StudioStore.state.mouse.dragging;
    },
    width() {
      return this.cW * this.zoom || 1;
    },
    height() {
      return this.cH * this.zoom || 1;
    },
    items() {
      return StudioStore.getCanvasUIItems();
    },
    selectedItems() {
      return this.items.filter(n => StudioStore.isSelected(n));
    },
    zoom() {
      return StudioStore.state.userZoom;
    },
    scale() {
      return StudioStore.state.scale;
    },
    editingItem() {
      return StudioStore.getSelectedItem();
    },
    customCodeItemId() {
      return StudioStore.state.customCodeItemId;
    },
    mouse() {
      return this.items?.find(n => n?.layerType === 'effect' && n?.type === 'mouse');
    },
    canvasDpi() {
      return 2 || 1;
    },
    textBoxStyles() {
      // Get absolute font values based on fontSizeMode
      const { fontSize, lineHeight, letterSpacing } = this.editingItem.getAbsoluteFontValues();
      
      // Get absolute dimensions
      const { width, height } = this.editingItem.getAbsoluteDimensions();
      
      return {
        width: width * this.zoom + 'px',
        height: height * this.zoom + 'px',
        top: this.editingItem.local.textBoxPos.y * this.zoom + this.offsetTop + 'px',
        left: this.editingItem.local.textBoxPos.x * this.zoom + this.offsetLeft + 'px',
        fontSize: fontSize * this.zoom + 'px',
        lineHeight: lineHeight * this.zoom + 'px',
        letterSpacing: letterSpacing * this.zoom + 'px',
        fontFamily: this.editingItem.fontFamily,
        fontStyle: this.editingItem.fontStyle,
        fontWeight: this.editingItem.fontStyle,
        textAlign: this.editingItem.textAlign,
        wordBreak: 'break-word',
        transform: `rotateZ(${Math.round(this.editingItem.rotation * 360)}deg)`,
      };
    },
    dpiScale() {
      return 2 / 2;
    },
  },
  created() {
    this.throttledMouseMove = throttle(this.handleMouseMove, 16);
  },
  mounted() {
    this.$refs.drawUICanvas.width = this.canvasWidth;
    this.$refs.drawUICanvas.height = this.canvasHeight;
    this.ctx = this.$refs.drawUICanvas.getContext('2d');
    this.ctx.scale(this.canvasDpi, this.canvasDpi);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = PRIMARY_COLOR;
    this.renderItems();
    window.addEventListener('resize', this.handleWindowResize);
    this.$refs.drawUICanvas.addEventListener('contextmenu', this.handleContextMenu);
    window.addEventListener('click', this.handleContextMenuClose);
    window.addEventListener('mousemove', this.handleMouseMoveThrottled);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('click', this.handleContextMenuClose);
    window.removeEventListener('mousemove', this.handleMouseMoveThrottled);
    if (this.$refs.drawUICanvas) {
      this.$refs.drawUICanvas.removeEventListener('contextmenu', this.handleContextMenu);
    }
    clearTimeout(this.textEditWindow);
    this.ctx = null;
    if (this.throttledMouseMove && this.throttledMouseMove.cancel) {
      this.throttledMouseMove.cancel();
    }
    this.cachedPaths.clear();
  },
  watch: {
    updater() {
      if (!this.mouse) {
        this.renderItems();
      }
    },
    zoom() {
      this.renderItems();
    },
    customCodeItemId() {
      this.$nextTick(() => {
        this.handleWindowResize();
      });
    },
    editingItem() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      if (this.editingItem) {
        this.items.forEach(item => {
          if (item.local.editing) {
            item.local.editing = false;
          }
        });

        if (this.editingItem.layerType === 'text') {
          if (this.editingItem.justCreated) {
            this.editingItem.local.editing = true;
            this.$nextTick(() => {
              this.$refs.textBox.focus();
            });
          }
        }
        this.renderItems();
      } else {
        this.selectionBoundingBox = [];
        this.selectedItemStartPositions = [];
      }
    },
  },
  methods: {
    renderItems() {
      if (!this.ctx) return;
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.ctx.strokeStyle = PRIMARY_COLOR;

      if (this.isMultiSelecting) {
        const startX = Math.min(this.selectionBoxStart.x, this.selectionBoxEnd.x);
        const startY = Math.min(this.selectionBoxStart.y, this.selectionBoxEnd.y);
        const width = Math.abs(this.selectionBoxStart.x - this.selectionBoxEnd.x);
        const height = Math.abs(this.selectionBoxStart.y - this.selectionBoxEnd.y);

        this.ctx.strokeStyle = PRIMARY_COLOR; // Example color
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(startX, startY, width, height);
        this.ctx.setLineDash([]); // Reset dash to solid
        this.ctx.fillStyle = PRIMARY_COLOR_ALPHA_25;
        this.ctx.fillRect(startX, startY, width, height);

        this.selectItemsInBox({
          x: startX,
          y: startY,
          width,
          height,
        });
      }

      if (StudioStore.state.selectedItems.length > 1) {
        this.ctx.lineWidth = 1.5;
        this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
        this.renderShapeBoundingBox(null, this.selectionBoundingBox);
        StudioStore.state.selectedItems.forEach(item => {
          this.renderShapeBoundingBox(item);
        });
      }

      this.items.forEach(item => {
        let selected = StudioStore.isSelected(item);
        if (item && (selected || item.local.isBeingHovered)) {
          this.ctx.lineWidth = selected && !item.local.isBeingHovered ? 1.5 : 2;
          if (item.layerType === 'effect') {
            this.renderEffectUI(item);
          } else {
            const coords = this.getDrawnCoords(item);
            this.renderShapeBoundingBox(item, coords);
            this.renderAnchorPoint(item, coords);
          }
        }
      });

      // Add snap guide rendering at the end
      this.renderSnapGuides();
    },
    handleWindowResize() {
      if (this.$refs.drawUICanvas) {
        this.$nextTick(() => {
          this.canvasWidth = (window.innerWidth - (this.customCodeItemId ? 980 : 0)) * 2;
          this.canvasHeight = window.innerHeight * 2;
          this.$refs.drawUICanvas.width = this.canvasWidth;
          this.$refs.drawUICanvas.height = this.canvasHeight;
          this.ctx.scale(this.canvasDpi, this.canvasDpi);
          this.ctx.lineWidth = 2;
          this.renderItems();
        });
      }
    },
    handleTextBoxInput(e) {
      this.editingItem.textContent = this.$refs.textBox.value;
      this.$emit('update');
    },
    handleContextMenu(e) {
      e.preventDefault();
      const item = StudioStore.getSelectedItem();
      requestAnimationFrame(() => {
        this.contextItem = item;
        this.contextPos = {
          x: e.pageX - 10 - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH),
          y: e.pageY + 45,
        };
      });
    },
    handleContextMenuClose() {
      if (document.querySelector('.flyout-options')) {
        this.contextItem = null;
      }
    },
    calculateSelectionBoundingBox(items) {
      let boxes = [];
      items.forEach(item => {
        if (item && item.coords) {
          const box = this.getDrawnCoords(item);
          boxes.push(box);
        }
      });
      if (boxes.length) {
        this.selectionBoundingBox = calculateTotalBoundingBox(boxes);
      }
    },
    handleDragMultiSelection(e) {
      StudioStore.state.selectedItems.forEach((item, index) => {
        const startState = this.selectedItemStartPositions[index];
        item.left = startState.x + StudioStore.state.mouse.delta.x/StudioStore.state.canvasWidth;
        item.top = startState.y + StudioStore.state.mouse.delta.y/StudioStore.state.canvasHeight;
      });
      this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
      this.$emit('update');
    },
    handleItemEvent(e) {
      if (this.event.type === 'is-on-angle-handle') {
        this.handleAngleEvent();
      } else if ('edge' in this.event || 'corner' in this.event) {
        this.handleEdgeOrCornerEvent();
      } else if (this.event.item.coords && !this.event.item.local.editing) {
        this.handleCoordsEvent();
      }
    },

    handleAngleEvent() {
      const delta_x = StudioStore.state.mouse.movePos.x - (this.event.box.anchor.x + this.event.left);
      const delta_y = StudioStore.state.mouse.movePos.y - (this.event.box.anchor.y + this.event.top);
      this.event.item.rotation = roundXToNearestY(
        Math.atan2(delta_y, delta_x) / (Math.PI * 2) - this.event.rotPos + this.event.rot,
        0.0027
      );
      this.$emit('update');
    },

    handleEdgeOrCornerEvent() {
      dragEdgeOrHandle(this.event, StudioStore.state.mouse);
      this.$emit('update');
    },

    handleImageEvent(point, centerX, centerY) {
      const imgAspectRatio = this.event.item.width / this.event.item.height;
      const canvasAspectRatio = StudioStore.state.currentSize.aspectRatio;
      const widthDist = Math.abs(centerX - point[0]) * (imgAspectRatio > 1 ? 1 : 1 / imgAspectRatio);
      const heightDist = Math.abs(centerY - point[1]) * (imgAspectRatio > 1 ? imgAspectRatio : 1);

      const dist = (Math.max(widthDist, heightDist) * (1 + canvasAspectRatio)) / 2 / this.cW;

      this.event.item.size = roundXToNearestY(dist, 0.001);
    },

    handleCoordsEvent() {
      if (this.event.item.left !== undefined && this.event.item.top !== undefined) {
        let newLeft = this.event.pos.x + Math.round(StudioStore.state.mouse.delta.x) / StudioStore.state.canvasWidth;
        let newTop = this.event.pos.y + Math.round(StudioStore.state.mouse.delta.y) / StudioStore.state.canvasHeight;

        // Get current item bounds
        const itemBounds = this.getItemBounds(this.event.item);
        const itemWidth = (itemBounds.right - itemBounds.left) / this.zoom;
        const itemHeight = (itemBounds.bottom - itemBounds.top) / this.zoom;
        
        // Calculate center positions
        const itemCenterX = newLeft + (itemWidth / 2) / StudioStore.state.canvasWidth;
        const itemCenterY = newTop + (itemHeight / 2) / StudioStore.state.canvasHeight;
        const rightEdge = newLeft + itemWidth / StudioStore.state.canvasWidth;
        const bottomEdge = newTop + itemHeight / StudioStore.state.canvasHeight;

        // Check vertical guides for snapping
        this.snapGuides.vertical.forEach(guide => {
          const snapX = guide.x / StudioStore.state.canvasWidth;
          const threshold = this.SNAP_THRESHOLD / StudioStore.state.canvasWidth;

          // Snap left edge
          if (Math.abs(newLeft - snapX) < threshold) {
            newLeft = snapX;
          }
          // Snap right edge
          if (Math.abs(rightEdge - snapX) < threshold) {
            newLeft = snapX - itemWidth / StudioStore.state.canvasWidth;
          }
          // Snap center
          if (Math.abs(itemCenterX - snapX) < threshold) {
            newLeft = snapX - (itemWidth / 2) / StudioStore.state.canvasWidth;
          }
        });

        // Check horizontal guides for snapping
        this.snapGuides.horizontal.forEach(guide => {
          const snapY = guide.y / StudioStore.state.canvasHeight;
          const threshold = this.SNAP_THRESHOLD / StudioStore.state.canvasHeight;

          // Snap top edge
          if (Math.abs(newTop - snapY) < threshold) {
            newTop = snapY;
          }
          // Snap bottom edge
          if (Math.abs(bottomEdge - snapY) < threshold) {
            newTop = snapY - itemHeight / StudioStore.state.canvasHeight;
          }
          // Snap center
          if (Math.abs(itemCenterY - snapY) < threshold) {
            newTop = snapY - (itemHeight / 2) / StudioStore.state.canvasHeight;
          }
        });

        this.event.item.left = newLeft;
        this.event.item.top = newTop;
      }

      if (this.event.maskedPos) {
        const maskedItem = this.getMaskedItem(this.event.item);
        maskedItem.left = Math.min(Math.max(
          roundXToNearestY(this.event.maskedPos.x + StudioStore.state.mouse.delta.x, 1),
          0
        ), 1);
        maskedItem.top = Math.min(Math.max(
          roundXToNearestY(this.event.maskedPos.y + StudioStore.state.mouse.delta.y, 1),
          0
        ), 1);
        maskedItem.render();
      }

      this.$emit('update');
    },

    getMaskedItem(item) {
      return StudioStore.state.history[item.getIndex() - 1];
    },

    handleHoverStates(e) {
      this.$refs.drawUIContainer.style.cursor = 'auto';
      this.event = {};

      if (this.selectionBoundingBox.length) {
        let onPath = this.isOnPath(this.getShapeBoundingBoxPath(null, this.selectionBoundingBox), e);
        if (onPath) {
          this.$refs.drawUIContainer.style.cursor = 'grab';
        }
      }

      let items =
        StudioStore.state.selectedItems.length > 1 ? this.items.filter(n => !StudioStore.isSelected(n)) : this.items;
      items.forEach(item => {
        item.local.isBeingHovered = false; // reset hover state
        const { layerType, rotation, left: x, top: y } = item;
        const rot = Math.abs(rotation * 360);

        if (layerType === 'effect') {
          item.pos = item.pos || new Vec2(0.5);
          this.event = this.isOnPath(this.getEffectHandlePath(item), e) ? { type: 'grab', item } : this.event;
        } else {
          const angle = this.isOnAngleHandle(item, e);
          const handle = this.isOnHandle(item, e);
          const boundingBox = this.isOnPath(this.getShapeBoundingBoxPath(item), e);
          const edge = this.isOnEdgePath(item, e);

          if (handle) {
            const index = calculateCursorIndex(handle, rot, layerType === 'shape' ? 0 : 90);
            this.event = {
              type: this.resizeCursors[item.width > 0 ? index + 2 : index],
              item,
              pos: { x, y },
            };
          } else if (edge) {
            const index = calculateCursorIndex(edge, rot, -45);
            this.event = { type: this.resizeCursors[index], item, edge };
          } else if (angle && !boundingBox) {
            this.event = { type: 'alias', item, pos: { x, y } };
          } else if (boundingBox) {
            this.event = { type: 'grab', item, pos: { x, y } };
          } else {
            const hit = item.coords?.some(coord => {
              const dist = Math.hypot(
                coord[0] + x - StudioStore.state.mouse.movePos.x,
                coord[1] + y - StudioStore.state.mouse.movePos.y
              );
              return dist < item.size / 2;
            });
            if (hit) {
              this.event = {
                type: 'grab',
                item,
                pos: { x, y },
              };
            }
          }
        }
      });
      if (this.event.type) {
        this.event.item.local.isBeingHovered = true;
        this.$refs.drawUIContainer.style.cursor = this.event.type;
      }
    },
    handleEffectMouseMove(e) {
      if (this.event.type === 'is-on-path') {
        if (this.event.prop === 'pos') {
          this.event.item[this.event.prop] = new Vec2(
            this.event.pos._x + (e.pageX - this.mouseDownX) / this.width,
            this.event.pos._y + (e.pageY - this.mouseDownY) / this.height
          );
        } else if (this.event.prop === 'axis') {
          this.event.item[this.event.prop] = new Vec3(
            this.event.pos._x + (e.pageX - this.mouseDownX) / this.width,
            this.event.pos._y + (e.pageY - this.mouseDownY) / this.height,
            this.event.pos.z
          );
        }
      } else if (this.event.type === 'is-on-angle-handle') {
        if (this.sizeKeys[this.event.item.type]) {
          this.event.item[this.sizeKeys[this.event.item.type]] = this.getDist(e);
        }
        if (this.angleKeys[this.event.item.type]) {
          if (this.angleKeys[this.event.item.type].split('.').length > 1) {
            let path = this.angleKeys[this.event.item.type].split('.');
            this.event.item[path[0]][path[1]] = this.getAngle(e);
          } else {
            this.event.item[this.angleKeys[this.event.item.type]] = this.getAngle(e);
          }
        }
      }
      this.$emit('render');
    },

    handleMouseMoveThrottled(e) {
      if (!['INPUT', 'TEXTAREA', 'SELECT'].includes(e.target.tagName)) {
        return this.throttledMouseMove(e);
      }
    },
    handleMouseMove(e) {
      if (StudioStore.state.tool === 'selector' && !this.contextItem) {
        if (this.selectionBoundingBox.length && this.selectedItemStartPositions.length) {
          this.handleDragMultiSelection(e);
        }
        if (this.isMultiSelecting) {
          this.selectionBoxEnd = { x: e.pageX, y: e.pageY };
          this.handleHoverStates(e);
        } else if (this.dragging) {
          if (this.event.item) {
            if (this.event.item.layerType === 'effect' && this.event.item[this.event.prop || 'pos']) {
              this.handleEffectMouseMove(e);
            } else {
              this.handleItemEvent(e);
              // Add snap guide check when dragging
              this.checkForSnapGuides(this.event.item);
            }
          }
        } else {
          this.handleHoverStates(e);
        }
      }
      if (StudioStore.state.tool === 'shape') {
        this.$refs.drawUIContainer.style.cursor = 'crosshair';
      }
      this.renderItems();
    },
    handleMouseDownEffect(item, e) {
      if (item.pos) {
        if (this.isOnPath(this.getEffectAngleHandlePath(item), e)) {
          this.event = {
            type: 'is-on-angle-handle',
            item: item,
            pos: item.pos,
          };
        } else if (item.axis && this.isOnPath(this.getMoveHandlePath(item, 'axis'), e)) {
          this.event = { type: 'is-on-path', item: item, pos: item.axis, prop: 'axis' };
        } else if (this.isOnPath(this.getMoveHandlePath(item, 'pos'), e)) {
          this.event = { type: 'is-on-path', item: item, pos: item.pos, prop: 'pos' };
        }
      }
    },
    handleMouseDown(e) {
      if (StudioStore.state.tool !== 'selector') {
        return false;
      }

      this.mouseDownX = e.pageX;
      this.mouseDownY = e.pageY;

      if (this.selectionBoundingBox.length && !StudioStore.state.hotkeys.isPressed('cmd')) {
        let onPath = this.isOnPath(this.getShapeBoundingBoxPath(null, this.selectionBoundingBox), e);
        if (onPath) {
          this.selectedItemStartPositions = StudioStore.state.selectedItems.map(n => {
            return {
              x: n.left,
              y: n.top,
            };
          });
          this.items.forEach(n => {
            n.local.isBeingHovered = false;
          });
          return;
        }
      }

      this.event = {};
      this.adjustItemsForSelected().forEach(item => {
        this.handleItemOnMouseDown(item, e);
        item.local.isBeingHovered = false;
      });

      if (!this.event.item) {
        this.isMultiSelecting = true;
        this.selectionBoxStart = { x: e.pageX, y: e.pageY };
        this.selectionBoxEnd = { x: e.pageX, y: e.pageY };
        this.selectionBoundingBox.length = 0;
        StudioStore.setSelectedItem('');
      } else {
        if (StudioStore.state.hotkeys.isPressed('shift')) {
          // Ensure no duplicate addition and reactive update
          if (!StudioStore.state.selectedItems.includes(this.event.item)) {
            StudioStore.state.selectedItems.push(this.event.item);
          }
        } else {
          StudioStore.setSelectedItem(this.event.item);
        }
      }

      this.renderItems();
    },

    deselectItems() {
      this.items.forEach(item => {
        item.local.isSelected = false;
      });
    },

    handleTextBoxSelection() {
      clearTimeout(this.textEditWindow);
      if (this.clickToEditText) {
        this.event.item.local.editing = true;
        this.$nextTick(() => {
          if (this.$refs.textBox) {
            this.$refs.textBox.focus();
          }
        });
      }
      this.clickToEditText = true;
      this.textEditWindow = setTimeout(() => {
        this.clickToEditText = false;
      }, 250);
    },

    adjustItemsForSelected() {
      let items = this.items;

      if (StudioStore.state.selectedItems.length) {
        items = [...items.filter(n => !StudioStore.isSelected(n)), ...items.filter(n => StudioStore.isSelected(n))];
      }

      return items;
    },

    handleItemOnMouseDown(item, e) {
      if (item.layerType === 'effect') {
        this.handleMouseDownEffect(item, e);
      } else {
        this.handleMouseOnItem(item, e);
      }
    },

    handleMouseOnItem(item, e) {
      if (StudioStore.state.reorderingItem) {
        return false;
      }

      let bbox = item.box();
      if (item.left !== undefined && item.top !== undefined) {
        if (this.isOnHandle(item, e)) {
          this.handleMouseOnItemHandle(item, e, bbox);
        } else if (this.isOnEdgePath(item, e)) {
          this.handleMouseOnItemEdge(item, e, bbox);
        } else if (this.isOnPath(this.getShapeBoundingBoxPath(item), e)) {
          this.handleMouseOnItemPath(item, e, bbox);
        } else if (this.isOnAngleHandle(item, e)) {
          this.handleMouseOnAngleHandle(item, e, bbox);
        }
      }
    },

    handleMouseOnItemHandle(item, e, bbox) {
      const handle = this.isOnHandle(item, e);
      let { left, top } = item.getAbsolutePosition();
      this.event = {
        type: 'is-on-handle',
        item: item,
        corner: handle - 1,
        pos: { x: item.left, y: item.top },
        rot: item.rotation,
        box: bbox,
        left,
        top,
        aspectRatioOffset: StudioStore.state.aspectRatioOffset,
        coords: item.coords,
        heightMode: item.heightMode,
      };
      item.local.boxStart = bbox;
      if (item.layerType === 'text') {
        item.local.editing = false;
        this.event.lineHeightStart = item.lineHeight;
        this.event.heightStart = item.height;
        this.event.fontSizeStart = item.fontSize;
      }
    },

    handleMouseOnItemEdge(item, e, bbox) {
      let { left, top } = item.getAbsolutePosition();
      this.event = {
        type: 'is-on-edge',
        item: item,
        edge: this.getEdgeIndex(
          [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
          this.getDrawnCoords(item)
        ),
        aspectRatioOffset: StudioStore.state.aspectRatioOffset,
        left,
        top,
        rot: item.rotation,
        box: bbox,
        coords: item.coords,
        heightMode: item.heightMode,
      };
      item.local.boxStart = bbox;
      if (item.layerType === 'text') {
        item.local.editing = false;
        this.event.lineHeightStart = item.lineHeight;
        this.event.heightStart = item.height;
        this.event.fontSizeStart = item.fontSize;
      }
    },

    handleMouseOnItemPath(item, e, bbox) {
      this.event = {
        type: 'is-on-path',
        item: item,
        pos: {
          x: item.left,
          y: item.top
        },
        box: bbox,
      };
      if (item.mask) {
        const maskedItem = this.getMaskedItem(item);
        if (maskedItem && maskedItem.isElement) {
          this.event.maskedPos = {
            x: maskedItem.left,
            y: maskedItem.top,
          };
        }
      }
      if (item.layerType === 'text') {
        this.handleTextBoxSelection();
      }
    },

    handleMouseOnAngleHandle(item, e, bbox) {

      const delta_x = StudioStore.state.mouse.movePos.x - (bbox.anchor.x + item.left);
      const delta_y = StudioStore.state.mouse.movePos.y - (bbox.anchor.y + item.top);
      this.event = {
        type: 'is-on-angle-handle',
        item: item,
        box: bbox,
        pos: { x: item.left, y: item.top },
        rot: item.rotation,
        rotPos: roundXToNearestY(Math.atan2(delta_y, delta_x) / (Math.PI * 2), 0.0027),
      };
    },

    handleMouseUp() {
      if (this.isMultiSelecting) {
        this.isMultiSelecting = false;
        this.renderItems(); // Final render to clear selection box
      }

      if (this.event.item?.local.boxStart) {
        const item = this.event.item;

        // Clear boxStart
        item.local.boxStart = undefined;
      }

      if(this.event.heightMode) {
        this.event.item.setDimensionMode('height', this.event.heightMode);
      }

      // Reset event and selection states
      this.event = {};
      this.selectedItemStartPositions = [];
      this.snapGuides.vertical = [];
      this.snapGuides.horizontal = [];
    },
    selectItemsInBox({ x, y, width, height }) {
      const selectionBounds = getShapeBoundingBox([
        [x, y],
        [x + width, y],
        [x + width, y + height],
        [x, y + height],
      ]);
      this.items
        .filter(n => n.coords)
        .forEach(item => {
          const box = this.getDrawnCoords(item);
          if (box) {
            const isOverlapping = polygonsOverlap(box, selectionBounds.corners);
            if (isOverlapping && !StudioStore.isSelected(item)) {
              StudioStore.state.selectedItems.push(item);
            }
          }
        });
      this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
    },

    getEdgeIndex(c, corners, padding) {
      let sides = corners.map((n, index) => (index < 3 ? [n, corners[index + 1]] : [n, corners[0]]));
      let sideIndex;
      let dist;
      sides.forEach((side, index) => {
        //TRBL
        let d = getPointDistToLine(c[0], c[1], side[0][0], side[0][1], side[1][0], side[1][1]);
        if (index === 0 || d < dist) {
          dist = d;
          sideIndex = index;
        }
      });
      if (padding) {
        return dist < padding ? sideIndex + 1 : false;
      } else {
        return sideIndex;
      }
    },
    getCornerIndex(c, corners, padding) {
      let sideIndex;
      let dist;
      corners.forEach((corner, index) => {
        //TRBL
        let d = getPointDistToLine(c[0], c[1], corner[0] - 1, corner[1] - 1, corner[0] + 1, corner[1] + 1);
        if (index === 0 || d < dist) {
          dist = d;
          sideIndex = index;
        }
      });
      if (padding) {
        return dist < padding ? sideIndex + 1 : false;
      } else {
        return sideIndex;
      }
    },
    isOnHandle(item, e) {
      return this.getCornerIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        this.getDrawnCoords(item),
        6
      );
    },
    isOnAngleHandle(item, e) {
      return this.getCornerIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        this.getDrawnCoords(item),
        24
      );
    },
    isOnPath(path, e) {
      return this.ctx.isPointInPath(path, e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY);
    },
    isOnEdgePath(item, e) {
      return this.getEdgeIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        this.getDrawnCoords(item),
        12
      );
    },
    getMoveHandlePath(effect, prop) {
      const path = new Path2D();
      let x = effect[prop || 'pos']._x * this.width + this.offsetLeft;
      let y = effect[prop || 'pos']._y * this.height + this.offsetTop;
      let dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      if (prop === 'axis') {
        dist = 50;
      }
      path.arc(x / 2, y / 2, Math.abs(dist) / 4, 0, 2 * Math.PI);
      return path;
    },
    getShapeBoundingBoxPath(item, coords) {
      const cacheKey = JSON.stringify(coords || this.getDrawnCoords(item));
      if (this.cachedPaths.has(cacheKey)) {
        return this.cachedPaths.get(cacheKey);
      }
      
      const path = new Path2D();
      const corners = coords || this.getDrawnCoords(item);
      for (let i = 0; i < corners.length; i++) {
        path.lineTo(corners[i][0] / 2, corners[i][1] / 2);
      }
      path.closePath();
      
      this.cachedPaths.set(cacheKey, path);
      return path;
    },
    getSquareHandlePath(x, y, angle, size) {
      const path = new Path2D();
      path.moveTo(...rotate(x, y, x - size, y - size, -angle * 360));
      path.lineTo(...rotate(x, y, x + size, y - size, -angle * 360));
      path.lineTo(...rotate(x, y, x + size, y + size, -angle * 360));
      path.lineTo(...rotate(x, y, x - size, y + size, -angle * 360));
      return path;
    },
    getEffectAngleHandlePath(effect) {
      const path = new Path2D();
      const dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      let angle = 0;
      if (this.angleKeys[effect.type]) {
        if (this.angleKeys[effect.type].split('.').length > 1) {
          let path = this.angleKeys[effect.type].split('.');
          angle = effect[path[0]][path[1]];
        } else {
          angle = effect[this.angleKeys[effect.type]];
        }
      }
      const x =
        effect.pos._x * this.width +
        this.offsetLeft +
        (Math.cos((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
      const y =
        effect.pos._y * this.height +
        this.offsetTop +
        (Math.sin((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
      path.arc(x / 2, y / 2, 8, 0, 2 * Math.PI);
      return path;
    },
    getEffectHandlePath(effect) {
      const path = new Path2D();
      const x = effect.pos._x * this.width + this.offsetLeft;
      const y = effect.pos._y * this.height + this.offsetTop;
      let dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      dist = Math.max(dist, 50);
      path.arc(x / 2, y / 2, dist / 4, 0, 2 * Math.PI);
      return path;
    },
    getBoxCoords(item, box) {
      return (box || item.coords).map(coord => {
        return [Math.round(coord[0] * this.zoom + this.offsetLeft), Math.round(coord[1] * this.zoom + this.offsetTop)];
      });
    },
    getDist(e) {
      const x = this.event.item.pos._x * this.width + this.offsetLeft;
      const y = this.event.item.pos._y * this.height + this.offsetTop;
      const a = x - e.pageX + (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH);
      const b = y - e.pageY;
      return roundXToNearestY((Math.sqrt(a * a + b * b) / this.width) * 2, 0.001) * 2;
    },
    getAngle(e) {
      const x = this.event.item.pos._x * this.width + this.offsetLeft;
      const y = this.event.item.pos._y * this.height + this.offsetTop;
      const delta_x = e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH) - x;
      const delta_y = e.pageY - y;
      return roundXToNearestY(Math.atan2(delta_y, delta_x) / (Math.PI * 2) + 0.25, 0.0027);
    },
    getDrawnCoords(item) {
      if (item.coords) {
        // Calculate the absolute width and height of the item
        let { width, height } = item.getAbsoluteDimensions();

        if(this.layerType === 'text') {
          height = item.height;
        }

        const box = item.box();

        const anchorOffsets = getAnchorOffsets(item.anchorPoint);
        
        let x = box.left - width * anchorOffsets.x;
        let y = box.top - height * anchorOffsets.y;

        // Calculate the new center based on anchor point
        let centerX = box.anchor.x;
        let centerY = box.anchor.y;

        // Define the unrotated bounding box coordinates
        let coords = [
          [x, y],
          [x + width, y],
          [x + width, y + height],
          [x, y + height],
        ];

        // Rotate each corner point around the center
        coords = coords.map(([coordX, coordY]) => {
          return rotate(centerX, centerY, coordX, coordY, -item.rotation * 360);
        });

        // Apply zoom and offsets to the coordinates for rendering
        return coords.map(([coordX, coordY]) => {
          return [Math.round(coordX * this.zoom + this.offsetLeft), Math.round(coordY * this.zoom + this.offsetTop)];
        });
      } else {
        return [];
      }
    },
    renderMoveHandle(effect, prop) {
      const x = effect[prop || 'pos']._x * this.width + this.offsetLeft;
      const y = effect[prop || 'pos']._y * this.height + this.offsetTop;

      const dist =
        !prop || (prop === 'pos' && this.sizeKeys[effect.type])
          ? (effect[this.sizeKeys[effect.type]] * this.width) / 2
          : 50;
      this.ctx.beginPath();
      this.ctx.arc(x, y, Math.max(dist / 2, 30), 0, 2 * Math.PI);
      this.ctx.closePath();

      if (EFFECTS[effect.type]) {
        if (!this.dragging) {
          this.ctx.setLineDash([1, 3]);
        }

        let label = prop && prop !== 'pos' ? prop : effect.isBackground ? 'Background' : EFFECTS[effect.type].label;

        this.ctx.stroke();
        this.ctx.setLineDash([]);
        let textWidth = this.ctx.measureText(label).width;
        this.ctx.fillStyle = EFFECT_COLOR;
        if (this.ctx.roundRect) {
          this.ctx.beginPath();
          this.ctx.roundRect(x - textWidth / 2 - 5, y - 8, textWidth + 10, 18, 4);
          this.ctx.fill();
        } else {
          this.ctx.fillRect(x - textWidth / 2 - 5, y - 8, textWidth + 10, 18);
        }
        this.ctx.font = '14px Arial';
        this.ctx.fillStyle = '#FFFFFF';
        this.ctx.fillText(label, x - textWidth / 2, y + 6);
      }
    },
    renderSquareHandle(x, y, angle, size) {
      this.ctx.fillStyle = '#fff';
      this.ctx.beginPath();
      this.ctx.moveTo(...rotate(x, y, x - size, y - size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x + size, y - size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x + size, y + size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x - size, y + size, -angle * 360));
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    },
    renderAngleHandle(x, y) {
      this.ctx.beginPath();
      this.ctx.fillStyle = '#fff';
      this.ctx.arc(x, y, 8, 0, 2 * Math.PI);
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    },
    renderAnchorPoint(item, corners) {
      if (!item?.anchorPoint) return;

      const PADDING = 16;
      const LINE_LENGTH = 16;

      const anchorPositions = {
        topLeft: [0, 0],
        topCenter: [0.5, 0],
        topRight: [1, 0],
        rightCenter: [1, 0.5],
        bottomRight: [1, 1],
        bottomCenter: [0.5, 1],
        bottomLeft: [0, 1],
        leftCenter: [0, 0.5],
        center: [0.5, 0.5],
      };

      const anchorPadding = {
        topLeft: [PADDING, PADDING],
        topCenter: [0, PADDING],
        topRight: [-PADDING, PADDING],
        rightCenter: [-PADDING, 0],
        bottomRight: [-PADDING, -PADDING],
        bottomCenter: [0, -PADDING],
        bottomLeft: [PADDING, -PADDING],
        leftCenter: [PADDING, 0],
        center: [0, 0],
      };

      const position = anchorPositions[item.anchorPoint];
      if (!position) return;

      const [ANCHOR_PADDING_X, ANCHOR_PADDING_Y] = anchorPadding[item.anchorPoint];

      // Get base position
      const topX = corners[0][0] + (corners[1][0] - corners[0][0]) * position[0];
      const topY = corners[0][1] + (corners[1][1] - corners[0][1]) * position[0];
      const bottomX = corners[3][0] + (corners[2][0] - corners[3][0]) * position[0];
      const bottomY = corners[3][1] + (corners[2][1] - corners[3][1]) * position[0];
      const x = topX + (bottomX - topX) * position[1];
      const y = topY + (bottomY - topY) * position[1];

      const [padX, padY] = rotate(0, 0, ANCHOR_PADDING_X, ANCHOR_PADDING_Y, -item.rotation * 360);

      this.ctx.beginPath();
      this.ctx.fillStyle = EFFECT_COLOR;
      this.ctx.arc(x + padX, y + padY, 4, 0, 2 * Math.PI);
      this.ctx.fill();
    },

    renderEffectUI(item) {
      this.ctx.strokeStyle = EFFECT_COLOR;
      if (item.pos) {
        this.renderMoveHandle(item, 'pos');
      } else {
        return;
      }
      if (item.axis) {
        this.renderMoveHandle(item, 'axis');
      }

      if (this.angleKeys[item.type] || this.sizeKeys[item.type]) {
        const dist = this.sizeKeys[item.type] ? (item[this.sizeKeys[item.type]] * this.width) / 2 : 50;

        let angle = 0;
        if (this.angleKeys[item.type]) {
          if (this.angleKeys[item.type].split('.').length > 1) {
            let path = this.angleKeys[item.type].split('.');
            angle = item[path[0]][path[1]];
          } else {
            angle = item[this.angleKeys[item.type]];
          }
        }

        const aX =
          item.pos._x * this.width +
          this.offsetLeft +
          (Math.cos((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
        const aY =
          item.pos._y * this.height +
          this.offsetTop +
          (Math.sin((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
        this.renderAngleHandle(aX, aY);

        if (this.dragging) {
          const x = item.pos._x * this.width + this.offsetLeft;
          const y = item.pos._y * this.height + this.offsetTop;
          this.ctx.moveTo(aX, aY);
          this.ctx.lineTo(x, y);
          this.ctx.setLineDash([1, 3]);
          this.ctx.stroke();
          this.ctx.setLineDash([]);
        }
      }
      this.ctx.strokeStyle = PRIMARY_COLOR;
    },
    renderShapeBoundingBox(item, coords) {
      const corners = coords || this.getDrawnCoords(item);
      this.ctx.beginPath();
      for (let i = 0; i < corners.length; i++) {
        this.ctx.lineTo(...corners[i]);
      }
      this.ctx.closePath();
      this.ctx.stroke();

      if (item) {
        item.local.drawnCoords = corners;
        if (StudioStore.isSelected(item) && StudioStore.state.selectedItems.length === 1) {
          for (let i = 0; i < corners.length; i++) {
            this.renderSquareHandle(...corners[i], item?.rotation || 0, 5);
          }

          this.renderAnchorPoint(item, corners);
        }
      }
    },
    handleMouseLeave() {
      // Similar cleanup to handleMouseUp
      this.isMultiSelecting = false;
      this.event = {};
      this.selectedItemStartPositions = [];
      this.renderItems();
    },
    checkForSnapGuides(item) {
      if (!item || !item.coords) return;
      
      this.snapGuides.vertical = [];
      this.snapGuides.horizontal = [];
      
      const itemBounds = this.getItemBounds(item);
      
      // Convert screen coordinates to canvas coordinates
      const itemLeft = (itemBounds.left - this.offsetLeft) / this.zoom;
      const itemRight = (itemBounds.right - this.offsetLeft) / this.zoom;
      const itemTop = (itemBounds.top - this.offsetTop) / this.zoom;
      const itemBottom = (itemBounds.bottom - this.offsetTop) / this.zoom;
      const itemCenterX = (itemLeft + itemRight) / 2;
      const itemCenterY = (itemTop + itemBottom) / 2;

      // Check against other items
      this.items.forEach(otherItem => {
        if (otherItem === item || !otherItem.coords) return;
        
        const otherBounds = this.getItemBounds(otherItem);
        const otherLeft = (otherBounds.left - this.offsetLeft) / this.zoom;
        const otherRight = (otherBounds.right - this.offsetLeft) / this.zoom;
        const otherTop = (otherBounds.top - this.offsetTop) / this.zoom;
        const otherBottom = (otherBounds.bottom - this.offsetTop) / this.zoom;
        const otherCenterX = (otherLeft + otherRight) / 2;
        const otherCenterY = (otherTop + otherBottom) / 2;

        // Check all horizontal alignments
        const horizontalAlignments = [
          { y1: itemTop, y2: otherTop, type: 'top-to-top' },
          { y1: itemTop, y2: otherBottom, type: 'top-to-bottom' },
          { y1: itemBottom, y2: otherTop, type: 'bottom-to-top' },
          { y1: itemBottom, y2: otherBottom, type: 'bottom-to-bottom' },
          { y1: itemCenterY, y2: otherCenterY, type: 'center-to-center' },
          { y1: itemTop, y2: otherCenterY, type: 'top-to-center' },
          { y1: itemBottom, y2: otherCenterY, type: 'bottom-to-center' },
          { y1: itemCenterY, y2: otherTop, type: 'center-to-top' },
          { y1: itemCenterY, y2: otherBottom, type: 'center-to-bottom' }
        ];

        // Check all vertical alignments
        const verticalAlignments = [
          { x1: itemLeft, x2: otherLeft, type: 'left-to-left' },
          { x1: itemLeft, x2: otherRight, type: 'left-to-right' },
          { x1: itemRight, x2: otherLeft, type: 'right-to-left' },
          { x1: itemRight, x2: otherRight, type: 'right-to-right' },
          { x1: itemCenterX, x2: otherCenterX, type: 'center-to-center' },
          { x1: itemLeft, x2: otherCenterX, type: 'left-to-center' },
          { x1: itemRight, x2: otherCenterX, type: 'right-to-center' },
          { x1: itemCenterX, x2: otherLeft, type: 'center-to-left' },
          { x1: itemCenterX, x2: otherRight, type: 'center-to-right' }
        ];

        // Check horizontal alignments
        horizontalAlignments.forEach(({ y1, y2, type }) => {
          if (Math.abs(y1 - y2) < this.SNAP_THRESHOLD) {
            this.snapGuides.horizontal.push({
              y: y2,
              start: Math.min(itemLeft, otherLeft),
              end: Math.max(itemRight, otherRight),
              type // for debugging
            });
          }
        });

        // Check vertical alignments
        verticalAlignments.forEach(({ x1, x2, type }) => {
          if (Math.abs(x1 - x2) < this.SNAP_THRESHOLD) {
            this.snapGuides.vertical.push({
              x: x2,
              start: Math.min(itemTop, otherTop),
              end: Math.max(itemBottom, otherBottom),
              type // for debugging
            });
          }
        });
      });

      // Check canvas center lines (keep existing code)
      const canvasCenterX = StudioStore.state.canvasWidth / 2;
      const canvasCenterY = StudioStore.state.canvasHeight / 2;
      
      [itemLeft, itemCenterX, itemRight].forEach(x => {
        if (Math.abs(x - canvasCenterX) < this.SNAP_THRESHOLD) {
          this.snapGuides.vertical.push({ 
            x: canvasCenterX, 
            start: 0, 
            end: StudioStore.state.canvasHeight,
            type: 'canvas-center'
          });
        }
      });

      [itemTop, itemCenterY, itemBottom].forEach(y => {
        if (Math.abs(y - canvasCenterY) < this.SNAP_THRESHOLD) {
          this.snapGuides.horizontal.push({ 
            y: canvasCenterY, 
            start: 0, 
            end: StudioStore.state.canvasWidth,
            type: 'canvas-center'
          });
        }
      });

      // Remove duplicate guides
      this.snapGuides.horizontal = this.deduplicateGuides(this.snapGuides.horizontal, 'y');
      this.snapGuides.vertical = this.deduplicateGuides(this.snapGuides.vertical, 'x');
    },

    deduplicateGuides(guides, prop) {
      const seen = new Set();
      return guides.filter(guide => {
        const value = Math.round(guide[prop] * 100) / 100; // Round to 2 decimal places
        if (seen.has(value)) {
          return false;
        }
        seen.add(value);
        return true;
      });
    },

    getItemBounds(item) {
      const coords = this.getDrawnCoords(item);
      if (!coords.length) return null;
      
      return {
        left: Math.min(...coords.map(c => c[0])),
        right: Math.max(...coords.map(c => c[0])),
        top: Math.min(...coords.map(c => c[1])),
        bottom: Math.max(...coords.map(c => c[1]))
      };
    },

    renderSnapGuides() {
      if (!this.ctx) return;
      
      this.ctx.save();
      this.ctx.strokeStyle = this.GUIDE_COLOR;
      this.ctx.lineWidth = 1;
      
      // Draw vertical guides
      this.snapGuides.vertical.forEach(guide => {
        const x = guide.x * this.zoom + this.offsetLeft;
        this.ctx.beginPath();
        this.ctx.moveTo(x, guide.start * this.zoom + this.offsetTop);
        this.ctx.lineTo(x, guide.end * this.zoom + this.offsetTop);
        this.ctx.stroke();
        
        // Draw 'x' marker
        const centerY = (guide.start + guide.end) / 2 * this.zoom + this.offsetTop;
        const markerSize = 3;
        this.ctx.beginPath();
        this.ctx.moveTo(x - markerSize, centerY - markerSize);
        this.ctx.lineTo(x + markerSize, centerY + markerSize);
        this.ctx.moveTo(x + markerSize, centerY - markerSize);
        this.ctx.lineTo(x - markerSize, centerY + markerSize);
        this.ctx.stroke();
      });

      // Draw horizontal guides
      this.snapGuides.horizontal.forEach(guide => {
        const y = guide.y * this.zoom + this.offsetTop;
        this.ctx.beginPath();
        this.ctx.moveTo(guide.start * this.zoom + this.offsetLeft, y);
        this.ctx.lineTo(guide.end * this.zoom + this.offsetLeft, y);
        this.ctx.stroke();
        
        // Draw 'x' marker
        const centerX = (guide.start + guide.end) / 2 * this.zoom + this.offsetLeft;
        const markerSize = 3;
        this.ctx.beginPath();
        this.ctx.moveTo(centerX - markerSize, y - markerSize);
        this.ctx.lineTo(centerX + markerSize, y + markerSize);
        this.ctx.moveTo(centerX + markerSize, y - markerSize);
        this.ctx.lineTo(centerX - markerSize, y + markerSize);
        this.ctx.stroke();
      });
      
      this.ctx.restore();
    },
  },
  handleTextBoxEscape() {
    if (e.key === 'Escape') {
      this.$refs.textBox.blur();
      StudioStore.setSelectedItem('');
    }
  },
};
</script>

<template>
  <div
    style="position: absolute; width: 100%; height: 100%"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @mouseleave="handleMouseLeave"
    ref="drawUIContainer"
  >
    <ActionFlyout
      v-if="contextItem"
      :pos="contextPos"
      :item="contextItem"
      @delete-item="$emit('delete-item', $event)"
      @add-effect="$emit('add-effect', $event)"
    ></ActionFlyout>
    <textarea
      v-if="editingItem && editingItem.local.editing"
      ref="textBox"
      id="textBoxInput"
      v-model="editingItem.textContent"
      class="text-box-editor"
      :style="textBoxStyles"
      @input="handleTextBoxInput"
      @contextmenu="handleContextMenu"
    ></textarea>
    <canvas class="position-adjuster-container" ref="drawUICanvas"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.position-adjuster-container {
  position: absolute;
  height: 100%;
  z-index: 1;
}

.text-box-editor {
  position: absolute;
  padding: 0;
  color: transparent;
  caret-color: var(--primary-color);
  z-index: 2;
  outline: none;
  background-color: transparent;
  border: none;
  resize: none;
  overflow: hidden;
  //white-space: nowrap;

  &::selection {
    background: rgba(30, 144, 255, 0.45);
  }
}
</style>
