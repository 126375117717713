import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import StudioView from '../views/StudioView.vue';
import EmbedView from '../views/EmbedView.vue';
import AccountView from '../views/AccountView.vue';
import DashboardView from '../views/DashboardView.vue';
import FolderView from '../views/FolderView.vue';
import HomeView from '../views/HomeView.vue';
import TermsView from '../views/TermsView.vue';
import CommunityView from '../views/CommunityView.vue';

let prod = window.location.hostname.includes('unicorn.studio');

const ROUTES = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/reset-password',
    name: 'reset password',
    component: ResetPasswordView,
  },
  {
    path: '/dashboard',
    component: () => import('../layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'account',
        name: 'dashboard-account',
        component: AccountView,
      },
      {
        path: 'community',
        name: 'dashboard-community',
        component: CommunityView,
      },
      {
        path: ':folderId',
        name: 'folder',
        component: FolderView,
      }
    ]
  },
  {
    path: '/edit/:designId',
    name: 'studio',
    component: StudioView,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
  },
];

if (!prod) {
  ROUTES.push({
    path: '/embed/:designId',
    name: 'embed',
    component: EmbedView,
  });
}

const routes = [
  ...ROUTES,
  {
    path: '/docs/:pathMatch(.*)*',
    name: 'docs',
    beforeEnter: (to) => {
      // Redirect to the docs
      window.location.href = `/docs/${to.params.pathMatch?.join('/') || ''}`
    }
  },
  {
    path: '/remix/:id',
    component: () => import('../layouts/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'remix',
        component: () => import('../views/RemixView.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

export default router;
