<script>
import PlayButton from "./PlayButton.vue";
import EditableText from "./EditableText.vue";
import { StudioStore } from '../stores/StudioStore.js';

function getFillStyle(fill, ang) {
  let angle = ang || 45;
  let isWhite = fill[0] === "#FFFFFF";
  const isTransparent = fill[0] === "transparent";
  if (fill.length > 1) {
    return {
      background: `linear-gradient(${angle}deg, ${fill})`,
    };
  } else {
    return {
      background: isTransparent
        ? `linear-gradient(45deg, #fff, #fff 47%, red 50%, #fff 53%, #fff)`
        : fill[0],
      border: isWhite || isTransparent ? "1px solid #eaeaea" : "none",
    };
  }
}

export default {
  components: {
    PlayButton,
    EditableText
  },
  props: ['item', 'label', 'index', 'draggingHistoryItem', 'draggingOverHistoryItem', 'editing', 'draggingOverBottom', 'draggingOverTop'],
  emits: [
    'edit-item', 
    'update-item-state', 
    'update-value',
    'drop-history-item',
    'drag-start',
    'drag-end',
    'allow-drop',
    'open-context-menu'
  ],
  methods: {
    toggleProp(prop) {
      this.item.toggleProp(prop);
      this.$emit('update-item-state');
    },
    getFillStyle(fill) {
      return getFillStyle(fill);
    },
    updateEffectValue(id, prop, value) {
      this.$emit('update-value', id, prop, value);
    },
    setLayerName(e) {
      this.item.layerName = e;
      this.$emit('update-item-state');
    }
  },
  computed: {
    labelFormatted() {
      if(this.item.layerName) {
        return this.item.layerName
      } else if(this.item.isBackground) {
        return 'Background'
      } else {
        return this.label;
      }
    },
    itemActions() {
      return this.getItemActions();
    },
    isVisible() {
      return this.item.isBackground ? this.item?.opacity > 0 : this.item.visible;
    },
    selected() {
      return StudioStore.state.selectedItems.includes(this.item);
    },
    handleMouseUp() {
      this.$emit('edit-item', this.item.local.id);
    }
  }
}
</script>

<template>
  <div class="history-item-container" v-if="item"
    :class="{
      'history-item-drag-target__top': draggingOverHistoryItem?.local.id === item.local.id && draggingOverTop,
      'history-item-drag-target__bottom': draggingOverHistoryItem?.local.id === item.local.id && draggingOverBottom,
      'history-item-drag-target__middle': draggingOverHistoryItem?.local.id === item.local.id && !draggingOverTop && !draggingOverBottom
    }"
  >
    <div
      class="history-item"
      draggable="true"
      :class="{
        'history-item-child': item.parentLayer,
        'history-item-editing': selected,
        'history-item-drag-target': draggingOverHistoryItem?.local.id === item.local.id,
        'history-item__dragging': draggingHistoryItem?.local.id === item.local.id,
      }"
      :key="'history-item_' + item.local.index"
      @dragstart="$emit('drag-start', item, $event)"
      @dragend="$emit('drag-end', item, $event)"
      @dragover="$emit('allow-drop', item, $event)"
      @mouseup="handleMouseUp"
    >
      <div class="history-dropzone"
        v-if="draggingHistoryItem"
        @drop="$emit('drop-history-item', item, $event)"
      ></div>
      <div class="history-item-content" :class="{'history-item__transition': draggingHistoryItem, 'history-item__offset-before': draggingHistoryItem > item.local.index && draggingOverHistoryItem <= item.local.index, 'history-item__offset-after': draggingHistoryItem < item.local.index && draggingOverHistoryItem >= item.local.index}">
        <div v-if="item.mask" class="gray flex align-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 256 256"><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm6,12.2a89.86,89.86,0,0,1,20,3.63V214.17a89.86,89.86,0,0,1-20,3.63Zm32,8.23a90.48,90.48,0,0,1,20,12.81V196.76a90.48,90.48,0,0,1-20,12.81ZM38,128a90.12,90.12,0,0,1,84-89.8V217.8A90.12,90.12,0,0,1,38,128Zm160,56.5V71.5a89.81,89.81,0,0,1,0,113Z"></path></svg>
        </div>
        <div v-else-if="item.fill && (item.isBackground || item.layerType !== 'effect')">
          <div class="selected-color"
            :style="getFillStyle(item.fill)"
          ></div>
        </div>
        <div v-else class="history-effect-icon flex align-center">
          <div v-if="'thumb' in item" class="mini-thumb">
            <img :src="item.thumb">
          </div>
          <PlayButton
            v-if="item.layerType !== 'image' && !item.isBackground"
            :animating="item.animating"
            :animation="'speed' in item || item.getParams().animation"
            :visible="isVisible"
            @toggle-animation="toggleProp('animating')"
          />
        </div>
        <EditableText v-if="selected" class="ml-2 w-100"
          :value="labelFormatted" 
          @update:value="setLayerName"
        />
        <span v-else class="history-item-name">{{ labelFormatted }}</span>
      </div>
      <div class="history-item-actions">
        <div class="hide-history-item button button__icon button__icon-auto-width" :class="{'item__hidden': !isVisible}"
          @click="item.toggleVisibility">
          <svg v-if="isVisible" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,55.99219C48,55.99219,16,128,16,128s32,71.99219,112,71.99219S240,128,240,128,208,55.99219,128,55.99219Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><circle cx="128" cy="128.00061" r="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle></svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><line x1="48" y1="40.00122" x2="208" y2="216.00122" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M154.9071,157.599a40.00028,40.00028,0,0,1-53.8142-59.19562" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M73.99446,68.59479C33.22519,89.24034,16,128.00122,16,128.00122s32,71.99219,112,71.99219a118.0238,118.0238,0,0,0,53.99756-12.59461" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M208.60953,169.10059C230.41132,149.57342,240,128.00122,240,128.00122S208,55.99341,128,55.99341a125.31923,125.31923,0,0,0-20.68221,1.68414" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M135.52737,88.709a40.02411,40.02411,0,0,1,32.29785,35.52874" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
        </div>
        <div class="lock-history-item button button__icon button__icon-auto-width" :class="{'item__locked': item.locked}"
          @click="toggleProp('locked')"
          >
          <svg v-if="item.locked" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="39.99414" y="88" width="176" height="128" rx="8" stroke-width="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect><path d="M91.99414,88V52a36,36,0,1,1,72,0V88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="39.99414" y="88" width="176" height="128" rx="8" stroke-width="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect><path d="M91.99414,88V52a36,36,0,1,1,72,0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
        </div>
        <div class="hide-history-item button button__icon button__icon-auto-width"
          @click="$emit('open-context-menu', $event, item)">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><path d="M140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128ZM128,72a12,12,0,1,0-12-12A12,12,0,0,0,128,72Zm0,112a12,12,0,1,0,12,12A12,12,0,0,0,128,184Z"></path></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.history-item-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
}

.history-dropzone {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 4.4rem;
  z-index: 0;
}

.history-item-drag-target__top .history-dropzone {
  border-top: 2px solid var(--primary-color);
}

.history-item-drag-target__bottom .history-dropzone {
  border-bottom: 2px solid var(--primary-color);
}

.history-item-drag-target__middle {
  box-shadow: inset 0 0 0 0.2rem var(--primary-color);
}

.history-item-name {
  margin-left: 1rem;
  width: 100%;
  pointer-events: none;
}

.history-item {
  position: relative;
  display: flex;
  margin: 0 0.5rem;
  border-radius: 0.4rem;

  &:hover {
    background-color: var(--accent-color);
    .history-item-actions .button {
      visibility: visible;
    }
  }

  &.history-item-editing {
    background-color: var(--primary-color);
    color: var(--white);

    .button,
    .history-effect-icon {
      color: var(--font-color);
    }
    .button:hover {
      background-color: var(--primary-color);
      color: var(--white);
    }
    .effect-animate-button {
      border: 1px solid var(--font-color);
    }
    .mask-arrow, 
    .mask-arrow-child-effect-of-mask {
      border-color: #ffffff60;
    }
    .gray {
      color: var(--font-color);
    }
  }

  &.history-item-child {
    .history-item-content {
      padding-left: 2.5rem;
    }
  }

  &.history-item__dragging {
    cursor: auto;
    background-color: transparent;
    width: calc(100% + 0.5rem);
    border-bottom: none;
    
    & > * {
      opacity: 0.15;
    }
  }

  &.history-item-drag-target {
    cursor: auto;
    .history-dropzone {
      opacity: 1;
      cursor: auto;
    }
  }
  &.history-item-being-dragged {
    cursor: auto;
    pointer-events: none;
  }
}

.history-item-content {
  display: flex;
  width: 100%;
  padding: 1rem 0.5rem 1rem 0.75rem;
  align-items: center;
  cursor: pointer;
  &.history-item__transition {
    transition: transform 0.2s ease;
  }
}

.history-effect-icon {
  color: var(--font-secondary-color);
}

.history-item-actions {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  
  .button {
    visibility: hidden;
  }

  .button.item__locked {
    visibility: visible;
    color: var(--font-secondary-color);
  }
}

.hide-history-item,
.lock-history-item,
.remove-history-item {
  color: var(--font-secondary-color);
  &:hover {
    color: var(--font-color);
  }
}

.hide-history-item {
  &.item__hidden {
    visibility: visible;
  }
}

.lock-history-item {
  &.item__locked {
    display: flex;
    color: var(--font-color);
  }
}

.mask-arrow {
  width: 1rem;
  height: 2.2rem;
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  margin-top: -2rem;
  margin-right: -0.3rem;
  margin-left: 0.8rem;
}

.mask-arrow-child-effect-of-mask {
  width: 0rem;
  height: 4.4rem;
  border-left: 1px solid var(--border-color);
  margin-bottom: -2.8rem;
  margin-left: 1.8rem;
  margin-right: -0.65rem;
}

.mask-arrow-childeffect {
  border-color: transparent;
  margin-left: 2rem;
}

</style>
