<script>
import { formatDate } from '../scripts/Helpers.js';
import { DesignsStore } from "../stores/DesignsStore.js";

export default {
  props: ['design'],
  data() {
    return {
      pendingDelete: null,
      renaming: false,
      contextMenuOpen: false,
      folderMenuOpen: false,
      state: DesignsStore.state,
      avatar: null
    }
  },
  computed: {
    updatedDate() {
      return formatDate(this.design.updatedAt)
    }
  },
  methods: {
    getAvatar() {
      return new Promise((resolve) => {
        // If no avatar is set, return false immediately
        if (!this.design?.avatar) {
          resolve(false);
          return;
        }
        
        // Create a test image element
        const img = new Image();
        
        // Set up event handlers
        img.onload = () => {
          // Image loaded successfully
          resolve(this.design.avatar);
        };
        
        img.onerror = () => {
          // Image failed to load
          resolve(false);
        };
        
        // Attempt to load the image
        img.src = this.design.avatar;
      });
    }
  },

  mounted() {
    this.getAvatar().then(avatar => {
      this.avatar = avatar;
    });
  }
}

</script>

<template>
    <div 
      :key="design.id"
      ref="item"
      class="design-listing-item"
      :title="design.name"
    >
      <RouterLink
        :to=" '/remix/' + design.id"
        class="design-item-link"
        draggable="false"
      >
        <img :src="design.thumbnail" :alt="design.name">
      </RouterLink>
      <div class="design-listing-info mt-2">
        <p class="p mb-3">{{ design.name || 'Untitled Design' }}</p>
        <div class="flex align-center">
          <div class="avatar-container">
            <img v-if="avatar" :src="avatar" alt="Creator avatar" />
            <div v-else class="avatar-placeholder">{{ design.creatorUsername?.charAt(0) || design.creatorName?.charAt(0) || 'A' }}</div>
          </div>
          <div>
            <div class="creator-info small-text" style="margin-bottom: 0.2rem;">
              {{ design.creatorName || design.creatorUsername || 'Anonymous' }}
            </div>
            <div class="creator-info font-secondary-color" :style="{'font-size': '1.2rem'}" v-if="design.creatorUsername">
              @{{ design.creatorUsername || 'Anonymous' }}
            </div>
          </div>
        </div>
        <div v-if="+design.remixCount" class="remix-count-item">{{ design.remixCount }}</div>
      </div>
    </div>
</template>

<style lang="scss">

.design-listing-item {
  position: relative;
  //width: calc(100% + 2rem);
  margin-left: -1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 1rem;

  &.design-listing-item__new {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem;
    text-decoration: none;
    
    &:hover {
      box-shadow: inset 0 0 0 0.1rem var(--border-color);
    }

    &:hover .new-item-content {
      color: var(--font-color);
    }
  }

  &.design-listing-item__pending-delete {
    opacity: 0.5;
    pointer-events: none;
  }

  &[draggable="true"] {
    cursor: move;
    cursor: grab;
    
    &:active {
      cursor: grabbing;
    }
  }

  img {
    position: relative;
    width: 100%;
    z-index: 0;
    object-fit: cover;
    aspect-ratio: 16/9;
  }

  &:hover {
    // background-color: var(--accent-color);
    box-shadow: 0 0 0 0.1rem var(--primary-color);

    img {
      z-index: 0;
    }

    .edit-design-button.button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
}

.edit-design-button.button.button__icon {
  display: none;
  position: absolute;
  background-color: var(--bg-color);
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  box-shadow: 0 0.1rem 0.3rem rgba(#000, 0.2);
}

.context-menu-link {
  display: block;
  text-align: left;
  font-size: 1.4rem;
  text-decoration: none;
  color: inherit;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;

  &:hover {
    background-color: var(--accent-color);
  }
}

.new-item-content {
  text-align: center;
}

.design-listing-info {
  width: 100%;
  color: inherit;
}

.design-item-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // aspect-ratio: 16/9;
  text-decoration: none;
  display: block;
  text-align: center;
}

.design-listing-name {
  text-decoration: none;
  color: var(--font-color);
  margin-bottom: 0.5em;
}

.design-listing-timestamp {
  font-size: 1.2rem;
  color: var(--font-secondary-color);
}

.remix-count-item {
  font-size: 1.2rem;
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  background-color: var(--bg-color);
  padding: 0.4rem 0.6rem;
  border-radius: 0.4rem;
  font-weight: 600;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
}

</style>