<script>
import Icon from './Icon.vue';

function checkForClassClick(event, className, element = event.target) {
  if (!element || !element.classList) {
    return false; // end recursion if target or classList is not defined
  }

  if (element.classList.contains(className)) {
    return true; // class found
  }

  // Recursively check parent elements
  return checkForClassClick(event, className, element.parentNode);
}

export default {
  props: ['options', 'icon', 'color'],
  components: {
    Icon
  },
  emits: ['select-option'],
  data() {
    return {
      open: false,
      itemHeight: 32.8
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    handleClick(e) {
      if(!checkForClassClick(e, 'action-menu')) {
        this.open = false;
      }
    },
    handleSelect(val) {
      this.$emit('select-option', val);
      this.open = false;
    },
  }
}
</script>

<template>
<div class="action-menu">
  <button @click="open = true" class="icon-button">
    <Icon :icon="icon" size="18" />
    <slot />
  </button>
  <div v-if="open" class="dropdown-options dropdown-options__short" ref="dropdown_options">
    <a href="javascript:void(0)"
      v-for="option in options"
      class="dropdown-option"
      :key="option.label"
      @click="handleSelect(option.value)"
    >{{ option.label }}</a>
  </div>
</div>
</template>

<style lang="scss">

.action-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-height: 20rem;
  width: auto;
  max-width: none;

  .dropdown-options {
    right: 0;
    left: unset;
  }
}

.icon-button {
  display: flex;
  padding: 0.3rem;
  border-radius: 0.3rem;

  &:hover {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
}

.dropdown-value {
  position: relative;
  display: block;
  padding: 0.7rem 2rem 0.7rem 1rem;
  border-radius: 0.4rem;
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
  line-height: 1.33333333;
}

.dropdown-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.85rem;
  pointer-events: none;
}

.dropdown-option {
  display: flex;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  margin: 0 0.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dropdown-option__large {
    padding: 0.8rem 1.2rem;
  }

  &:hover {
    background-color: var(--menu-hover-color);
  }
}

.dropdown-option.dropdown-option__active {
  background-color: var(--primary-color);
  color: var(--white);
  margin: 0;

  .gray {
    color: var(--font-color);;
  }
}

</style>