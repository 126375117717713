<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue)
      }
    }
  }
}
</script>

<template>
  <div class="checkbox-wrapper flex align-center" :class="{ checked: modelValue, disabled }">
    <div 
      class="checkbox mr-2" 
      :class="{ checked: modelValue, disabled }"
      @click="toggle"
    >
      <Icon 
        v-if="modelValue" 
        icon="checkBold" 
        :size="11"
      />
    </div>
    <slot></slot>
  </div>
</template>

<style scoped>

.checkbox-wrapper {
  color: var(--font-secondary-color);
  &.checked {
    color: var(--font-color);
  }
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--bg-color);
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  transition: all 0.2s ease;
}

.checkbox:hover:not(.disabled) {
  border-color: var(--primary-color);
}

.checkbox.checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style> 