<script>
import { StudioStore } from '../stores/StudioStore.js';
import { SCREEN_SIZES, ZOOM_LEVELS } from '../scripts/Constants.js';
import DropdownMenu from './DropdownMenu.vue';
import EditableText from './EditableText.vue';
import ClassicToggle from './ClassicToggle.vue';
import Tooltip from './Tooltip.vue';
import InputField from './InputField.vue';
import Icon from './Icon.vue';
import ScreenSizeBrowser from './ScreenSizeBrowser.vue';
import Button from './Button.vue';
import { DesignsStore } from '../stores/DesignsStore.js';
import ShareControls from './ShareControls.vue';

export default {
  components: {
    DropdownMenu,
    ScreenSizeBrowser,
    ClassicToggle,
    Tooltip,
    Icon,
    EditableText,
    InputField,
    Button,
    ShareControls
  },
  data() {
    return {
      sizes: SCREEN_SIZES,
      zoomLevels: ZOOM_LEVELS,
      scaleOptions: {0.5: '0.5x', 1: '1x', 1.5: '1.5x', 2: '2x'},
      state: StudioStore.state,
      showToolDropdown: false,
      showZoomDropdown: false,
      showShapeDropdown: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      // Check if the click was outside the shape dropdown
      if (!event.target.closest('.tool-options') && !event.target.closest('.tool-button')) {
        this.showShapeDropdown = false;
      }
    },
    clearAll() {
      StudioStore.clearAll();
    },
    async renameDesign(val) {
      if(val !== this.state.design.name) {
        this.state.design.name = val;
        
        // Check if this design has a remix and update its name
        if (this.state.design.id && this.state.design.remixEnabled) {
          try {
            const remixDoc = await DesignsStore.getRemixByDesignId(this.state.design.id);
            if (remixDoc && remixDoc.exists()) {
              const remixId = remixDoc.id;
              await DesignsStore.saveRemix(remixId, { name: val });
            }
          } catch (error) {
            console.error('Error updating remix name:', error);
          }
        }
        
        StudioStore.save();
      }
    },
    undo() {
      StudioStore.undo();
    },
    redo() {
      StudioStore.redo();
    },
    saveDesign() {
      StudioStore.save();
    },
    handlePreview() {
      StudioStore.handlePreview();
      this.$emit('set-zoom', 'fit');
    },
    resetBreakpoints() {
      this.$emit('reset-breakpoints');
    },
    duplicateDesign() {
      const currentFolderId = StudioStore.state.design.folderId;
      const folderBelongsToUser = currentFolderId && 
        DesignsStore.state.folders.some(folder => folder.id === currentFolderId);
      
      DesignsStore.duplicateDesign(StudioStore.state.design, folderBelongsToUser ? currentFolderId : null);
    },
    handleHDChange() {
      if(StudioStore.state.hd) {
        localStorage.setItem('unicornstudio-hd', 'true');
        StudioStore.setScale(1);
      } else {
        localStorage.setItem('unicornstudio-hd', 'false');
        StudioStore.setScale(0.5);
      }
      StudioStore.renderFrame();
    },
    handleSizeChange(name) {
      this.state.size = name;
      this.state.design.size = name;
    },
    getRemixUrl() {
      return `unicorn.studio/remix/${this.state.design.id}`;
    },
    copyRemixUrl() {
      navigator.clipboard.writeText(this.getRemixUrl());
    },
    toggleRemixEnabled() {
      if(this.state.design) {
        this.state.design.remixEnabled = !this.state.design.remixEnabled;
        StudioStore.save();
      }
    }
  }
}
</script>

<template>
  <div class="top-bar">
    <div class="flex align-center">
      <div v-if="!state.previewing" class="top-bar-group-wrapper flex align-center ml-1">
        <div class="menu-container">
          <button @click="state.mainMenuOpen = true" class="button button__icon">
            <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
          </button>
          <div v-if="state.mainMenuOpen" @mouseleave="state.mainMenuOpen = false" class="dropdown-options" style="top: 2rem; left: 2rem">
            <RouterLink :to="'/dashboard' + (state.design?.folderId ? '/' + state.design.folderId : '')" class="dropdown-option dropdown-option__large">Back</RouterLink>
            <!-- <a href="javascript:void(0)" class="dropdown-option dropdown-option__large" @click="viewVersions">Versions</a> -->
            <a href="javascript:void(0)" class="dropdown-option dropdown-option__large" @click="duplicateDesign">Duplicate</a>
            <div class="divider"></div>
            <a href="javascript:void(0)"
              class="dropdown-option dropdown-option__large"
              @click="undo()"
            >Undo</a>
            <a href="javascript:void(0)"
              class="dropdown-option dropdown-option__large"
              @click="redo()"
            >Redo</a>
            <div class="divider"></div>
            <a href="javascript:void(0)"
              class="dropdown-option dropdown-option__large"
              @click="resetBreakpoints(); state.mainMenuOpen = false;"
            >Reset breakpoints</a>
            <a href="javascript:void(0)"
              class="dropdown-option dropdown-option__large"
              @click="clearAll(); state.mainMenuOpen = false;"
            >Clear all</a>
          </div>
        </div>
        <div v-if="state.design" class="design-listing-name" style="margin-bottom: 0;">
          <EditableText
            :value="state.design.name"
            @update:value="renameDesign"
          />
        </div>
        <div class="saving-indicator ml-2">
          <svg v-if="state.saving" class="saving-spinner" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
            <path d="M16 8.5A7.5 7.5 0 1 1 8.5 1" stroke="currentColor"/>
          </svg>
          <svg v-else fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
            <circle cx="8.5" cy="8.5" r="7.5" fill="currentColor" stroke="currentColor"/>
            <path d="m4 8.5 3 3 6-6" stroke="#fff"/>
          </svg>
          <div style="top: -1rem; left: 2.7rem" class="help-indicator help-indicator__black help-indicator__right">{{ state.saving ? 'Saving...' : 'Saved' }}</div>
        </div>
      </div>
      <div v-if="!state.previewing" class="tool-container mt-1 ml-1">
        <button @click="state.tool = 'selector'" :class="{'tool-button__selected': state.tool === 'selector'}" class="tool-button"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M38.2,48.5,96.4,203.7a8,8,0,0,0,15-.1l23.3-64.1a8.1,8.1,0,0,1,4.8-4.8l64.1-23.3a8,8,0,0,0,.1-15L48.5,38.2A8,8,0,0,0,38.2,48.5Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path><line x1="136.6" y1="136.6" x2="208" y2="208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line></svg></button>

        <div class="relative">
          <button class="tool-button" :class="{'tool-button__selected': state.tool === 'shape'}" @click="showShapeDropdown = true; state.tool = 'shape'">
            <svg v-if="state.shape.type === 'circle'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></circle></svg>
            <svg v-else-if="state.shape.type === 'polygon'"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><path d="M235.07,189.09,147.61,37.22a22.75,22.75,0,0,0-39.22,0L20.93,189.09a21.53,21.53,0,0,0,0,21.72A22.35,22.35,0,0,0,40.55,222h174.9a22.35,22.35,0,0,0,19.6-11.19A21.53,21.53,0,0,0,235.07,189.09ZM224.66,204.8a10.46,10.46,0,0,1-9.21,5.2H40.55a10.46,10.46,0,0,1-9.21-5.2,9.49,9.49,0,0,1,0-9.72L118.79,43.21a10.75,10.75,0,0,1,18.42,0l87.46,151.87A9.49,9.49,0,0,1,224.66,204.8Z"></path></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="40" y="40" width="176" height="176" rx="8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></rect></svg>
          </button>
          <div v-if="showShapeDropdown" class="tool-options dropdown-options">
            <a href="javascript:void(0)" class="dropdown-option w-100 flex justify-start" @click="state.tool = 'shape'; state.shape.type = 'rectangle'; showShapeDropdown = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="40" y="40" width="176" height="176" rx="8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></rect></svg>
              <div class="tool-label">Rectangle</div>
            </a>
            <a href="javascript:void(0)" class="dropdown-option w-100 flex justify-start" @click="state.tool = 'shape'; state.shape.type = 'circle'; showShapeDropdown = false" >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></circle></svg>
              <div class="tool-label">Circle</div>
            </a>
            <a href="javascript:void(0)" class="dropdown-option w-100 flex justify-start" @click="state.tool = 'shape'; state.shape.type = 'polygon'; showShapeDropdown = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><path d="M235.07,189.09,147.61,37.22a22.75,22.75,0,0,0-39.22,0L20.93,189.09a21.53,21.53,0,0,0,0,21.72A22.35,22.35,0,0,0,40.55,222h174.9a22.35,22.35,0,0,0,19.6-11.19A21.53,21.53,0,0,0,235.07,189.09ZM224.66,204.8a10.46,10.46,0,0,1-9.21,5.2H40.55a10.46,10.46,0,0,1-9.21-5.2,9.49,9.49,0,0,1,0-9.72L118.79,43.21a10.75,10.75,0,0,1,18.42,0l87.46,151.87A9.49,9.49,0,0,1,224.66,204.8Z"></path></svg>
              <div class="tool-label">Polygon</div>
            </a>
          </div>
        </div>

        <button @click="state.tool = 'text'" :class="{'tool-button__selected': state.tool === 'text'}" class="tool-button"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="none" d="M0 0h256v256H0z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M128 56v144"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M48 88V56h160v32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M96 200h64"/></svg></button>

        <button class="tool-button relative" @click="$emit('add-image')">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></rect><path d="M32,168l50.3-50.3a8,8,0,0,1,11.4,0l44.6,44.6a8,8,0,0,0,11.4,0l20.6-20.6a8,8,0,0,1,11.4,0L224,184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path><circle cx="156" cy="100" r="10"></circle></svg>
        </button>
        
        <button class="tool-button" @click="state.sdfShapeBrowser = true">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256"><path d="M222.72,67.9l-88-48.17a13.9,13.9,0,0,0-13.44,0l-88,48.18A14,14,0,0,0,26,80.18v95.64a14,14,0,0,0,7.28,12.27l88,48.18a13.92,13.92,0,0,0,13.44,0l88-48.18A14,14,0,0,0,230,175.82V80.18A14,14,0,0,0,222.72,67.9ZM127,30.25a2,2,0,0,1,1.92,0L212.51,76,128,122.24,43.49,76ZM39,177.57a2,2,0,0,1-1-1.75V86.66l84,46V223Zm177.92,0L134,223V132.64l84-46v89.16A2,2,0,0,1,217,177.57Z"></path></svg>
        </button>

        <button class="tool-button" @click="state.browsingEffects = true">
          <Icon size="20" icon="effect" />
        </button>

      </div>
    </div>
    <div v-if="!state.previewing" class="tool-container tool-container__centered mt-1 mr-1">
      <Tooltip tooltip="Desktop (Base breakpoint)" position="bottom">
        <button @click="handleSizeChange('Desktop')" :class="{'tool-button__selected': state.size === 'Desktop'}" class="breakpoint-button tool-button font-secondary-color">
          <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.25C1 3.11193 1.11193 3 1.25 3H13.75C13.8881 3 14 3.11193 14 3.25V10.75C14 10.8881 13.8881 11 13.75 11H1.25C1.11193 11 1 10.8881 1 10.75V3.25ZM1.25 2C0.559643 2 0 2.55964 0 3.25V10.75C0 11.4404 0.559644 12 1.25 12H5.07341L4.82991 13.2986C4.76645 13.6371 5.02612 13.95 5.37049 13.95H9.62951C9.97389 13.95 10.2336 13.6371 10.1701 13.2986L9.92659 12H13.75C14.4404 12 15 11.4404 15 10.75V3.25C15 2.55964 14.4404 2 13.75 2H1.25ZM9.01091 12H5.98909L5.79222 13.05H9.20778L9.01091 12Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
        </button>
      </Tooltip>

      <Tooltip tooltip="Tablet (991px and smaller)" position="bottom">
        <button @click="handleSizeChange('Tablet')" :class="{'tool-button__selected': state.size === 'Tablet'}" class="breakpoint-button tool-button font-secondary-color">
          <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-10ZM3.5 1A1.5 1.5 0 0 0 2 2.5v10A1.5 1.5 0 0 0 3.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 11.5 1h-8ZM6 11.65a.35.35 0 1 0 0 .7h3a.35.35 0 1 0 0-.7H6Z" fill="currentColor"/></svg>
        </button>
      </Tooltip>

      <Tooltip tooltip="Phone (575px and smaller)" position="bottom">
        <button @click="handleSizeChange('Mobile')" :class="{'tool-button__selected': state.size === 'Mobile'}" class="breakpoint-button tool-button font-secondary-color">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.33333 3.33301C6.33333 2.96482 6.63181 2.66634 7 2.66634H13C13.3681 2.66634 13.6667 2.96482 13.6667 3.33301V16.6663C13.6667 17.0345 13.3681 17.333 13 17.333H7C6.63181 17.333 6.33333 17.0345 6.33333 16.6663V3.33301ZM7 1.33301C5.89543 1.33301 5 2.22843 5 3.33301V16.6663C5 17.7709 5.89543 18.6663 7 18.6663H13C14.1045 18.6663 15 17.7709 15 16.6663V3.33301C15 2.22843 14.1045 1.33301 13 1.33301H7ZM8 15.533C7.74227 15.533 7.53333 15.7419 7.53333 15.9997C7.53333 16.2574 7.74227 16.4663 8 16.4663H12C12.2577 16.4663 12.4667 16.2574 12.4667 15.9997C12.4667 15.7419 12.2577 15.533 12 15.533H8Z" fill="currentColor"/></svg>
        </button>
      </Tooltip>
    </div>
    <div class="flex">

      <div class="flex top-bar-group-wrapper top-bar-group-wrapper__utilities mr-1">
      <div class="top-bar-group">
        <div class="size-dropdown dropdown">
          <a class="dropdown-value" href="javascript:void(0)" @click="state.browsingSizes = true">
            <div class="size-label">
              <span style="white-space: nowrap">{{ state.currentSize.name }} <span class="font-secondary-color">{{ state.currentSize.realDimensions[0] }} x {{ state.currentSize.realDimensions[1] }}</span></span>
            </div>
          </a>
        </div>
      </div>
      <ScreenSizeBrowser
        v-if="state.browsingSizes"
        @resize-canvas="$emit('resize-canvas')"
      />
      <div class="top-bar-group ml-1">
        <div class="zoom-control">
          <div class="button button__icon button__icon-large zoom-level" @click="showZoomDropdown = true">{{ (state.userZoom * 100).toFixed(0) }}%</div>
          <div v-if="showZoomDropdown" class="zoom-options dropdown-options" @mouseleave="showZoomDropdown = false">
            <a href="javascript:void(0)"
              v-for="(val, name) in zoomLevels"
              :key="name"
              class="dropdown-option"
              @click="$emit('set-zoom', val)"
            >
              <div class="size-label">
                {{ name }}
              </div>
              <div v-if="val === 'fit'" class="shortcut">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M32,120l96-96,96,96H176v88a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V120Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
                1
              </div>
              <div v-if="val === 'in'" class="shortcut"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="128" y1="40" x2="128" y2="216" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg></div>
              <div v-if="val === 'out'" class="shortcut"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg></div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!state.previewing" class="flex tool-container mr-1 mt-1">
      <div class="flex align-center mx-2">
        <label class="parameter-label mr-1 flex">HD</label> <ClassicToggle v-model="state.hd" @update:modelValue="handleHDChange" />
      </div>
    </div>
    <div class="flex tool-container mr-1 mt-1">
      <button class="tool-button" title="Preview" :class="{'tool-button__selected': state.previewing}" @click="handlePreview">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><path d="M232.4,114.49,88.32,26.35a16,16,0,0,0-16.2-.3A15.86,15.86,0,0,0,64,39.87V216.13A15.94,15.94,0,0,0,80,232a16.07,16.07,0,0,0,8.36-2.35L232.4,141.51a15.81,15.81,0,0,0,0-27ZM80,215.94V40l143.83,88Z"></path></svg>
      </button>
    </div>
    <div v-if="!state.previewing" class="flex top-bar-group-wrapper top-bar-group-wrapper__utilities mr-1">
      <div class="button-group">
        <ShareControls 
          :design="state.design"
          @update:remixEnabled="val => { state.design.remixEnabled = val; saveDesign(); }"
          @update:makeDiscoverable="val => { state.design.makeDiscoverable = val; saveDesign(); }"
        />
        <Button @click="state.export.active = true;" class="primary small">Export</Button>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.tool-container {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  background-color: var(--bg-color);
  padding: 0.3rem;
  left: 0;
  right: 0;
  top: 0;
  
  &.tool-container__centered {
    position: absolute;
    display: inline-flex;
    margin: 0.5rem auto 0;
    width: max-content;
  }
}


.tool-button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  &:hover {
    background-color: var(--accent-color);
  }

  &.tool-button__selected {
    background-color: var(--primary-color);
    border-top: 1px solid var(--primary-color-light);
    border-bottom: 1px solid var(--primary-hover-color);
    color: white;
  }

}

.top-bar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  //right: 28rem;
  width: 100%;
  //background-color: var(--bg-color);
  z-index: 9999;
}

.top-bar-group {
  //position: absolute;
  display: flex;
  padding: 0.9rem 0;
  align-items: center;
  justify-content: center;
  z-index: 99;
  &.top-bar-group__left {
    left: 1.5rem;
  }
  &.top-bar-group__right {
    right: 0;
  }
  &.top-bar-group__centered {
    right: 6rem;
    width: fit-content;
  }
}

.size-label {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.size-dimensions {
  margin: 0 1rem;
}

.dropdown-option__active .size-icon {
  border: 1px solid var(--white);
}

.dropdown-options.size-options {
  position: absolute;
  right: 0;
  left: unset;
  top: 5rem;
  min-width: 30rem;
}

.dropdown-options.zoom-options {
  max-width: 20rem;
  width: max-content;
  right: 0;
  left: unset;
  top: 5rem;
}

.tool-options {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  left: 0;
  right: 0;
  top: 5rem;
  min-width: 12rem;
  text-align: left;
  margin: 0 auto;
}

.tool-label {
  margin-left: 1rem;
}

.size-option {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  .gray {
    color: var(--font-secondary-color);
  }
  &+.size-option {
    border-top: 1px solid var(--accent-color);
  }
  &.size-option__selected {
    background-color: var(--primary-color);
    border-top: 1px solid var(--primary-color);
    color: var(--white);
    .gray {
      color: var(--font-color);
    }
  }
}

.top-bar-group-wrapper {
  position: relative;
  background-color: var(--bg-color);
  border-radius: 0.4rem;
  margin-top: 0.5rem;
  padding: 0.7rem 1rem 0.7rem 0.5rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1);

  &.top-bar-group-wrapper__utilities {
    padding: 0rem 0.9rem;
  }
}

.small-pad {
  padding: 0.8rem 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

</style>