import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  CLIP_EFFECT,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const halftoneShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uRotation;
  uniform float uThreshold;
  uniform int uStyle;
  uniform float uMix;
  uniform vec2 uPos;
  uniform vec3 uColor;
  ${UNIVERSAL_UNIFORMS}

  float luma(vec4 color) {
    return dot(color.rgb, vec3(0.299, 0.587, 0.114));
  }

  // modified from https://www.shadertoy.com/view/wsjGD1

  vec3 CMYKtoRGB (vec4 cmyk) {
    float c = cmyk.x;
    float m = cmyk.y;
    float y = cmyk.z;
    float k = cmyk.w;

    float invK = 1.0 - k;
    float r = 1.0 - min(1.0, c * invK + k);
    float g = 1.0 - min(1.0, m * invK + k);
    float b = 1.0 - min(1.0, y * invK + k);
    return clamp(vec3(r, g, b), 0.0, 1.0);
  }

  vec4 RGBtoCMYK (vec3 rgb) {
      float r = rgb.r;
      float g = rgb.g;
      float b = rgb.b;
      float k = min(1.0 - r, min(1.0 - g, 1.0 - b));
      vec3 cmy = vec3(0.0);
      float invK = 1.0 - k;
      if (invK != 0.0) {
          cmy.x = (1.0 - r - k) / invK;
          cmy.y = (1.0 - g - k) / invK;
          cmy.z = (1.0 - b - k) / invK;
      }
      return clamp(vec4(cmy, k), 0.0, 1.0);
  }

  float aastep(float threshold, float value) {
    float afwidth = uAmount*200. * (1./uResolution.x);
    float minval = threshold - afwidth;
    float maxval = threshold + afwidth;
    return smoothstep(minval, maxval, value);
  }

  vec2 rotate2D(vec2 st, float degrees) {
      float c = cos(radians(degrees));
      float s = sin(radians(degrees));
      return mat2(c,-s,s,c) * st;
  }

  float halftone(vec2 st, float col, float angle) {
      float aspectRatio = uResolution.x / uResolution.y;
      float aspectCorrection = mix(aspectRatio, 1./aspectRatio, 0.5);

      st *= vec2(aspectRatio, 1.);
      vec2 r_st = uAmount*200. * rotate2D(st - uPos, angle - uRotation*360.);
      r_st /= aspectCorrection;
      st = (2. * fract(r_st) - 1.) * 0.82;

      return aastep(uThreshold, sqrt(col) - length(st));
  }

  out vec4 fragColor;
  
  void main() {
    ${CLIP_EFFECT}
    
    vec2 uv = vTextureCoord;
    vec4 color = texture(uTexture, uv);
    vec4 cmyk = uStyle == 0 ? RGBtoCMYK(color.rgb) : color;
    float alpha = color.a;

    float k = halftone(uv, cmyk.w, 45.);
    float c = halftone(uv, cmyk.x, 15.);
    float m = halftone(uv, cmyk.y, 75.);
    float y = halftone(uv, cmyk.z, 0.);

    float rC = 1. - halftone(uv, 1. - cmyk.x, 15.);
    float rM = 1. - halftone(uv, 1. - cmyk.y, 75.);
    float rY = 1. - halftone(uv, 1. - cmyk.z, 0.);

    float g = 1. - halftone(uv, 1. - luma(color), 0.);

    vec4 halftone = uStyle == 0 ? vec4(CMYKtoRGB(vec4(c,m,y,k)), 1) : vec4(rC,rM,rY,1);
    halftone = uStyle == 2 ? vec4(mix(vec3(g), uColor, 1.-(g*g)), 1) : halftone;
    
    halftone *= color.a;

    color = mix(color, halftone, uMix);
    
    ${computeFragColor('color')}
  }
`;

const halftoneParams = {
  fragmentShader: halftoneShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    style: {
      name: 'uStyle',
      type: '1i',
      value: 0,
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 0,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(0),
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.75,
    },
    threshold: {
      name: 'uThreshold',
      type: '1f',
      value: 0.5,
    },
    rotation: {
      name: 'uRotation',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const HALFTONE = {
  id: 'halftone',
  label: 'Halftone',
  params: halftoneParams,
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    style: {
      label: 'Style',
      value: 0,
      options: {
        0: 'CYMK',
        1: 'RGB',
        2: 'Monochrome',
      },
      responsiveDisabled: true,
    },
    color: {
      label: 'Color',
      value: new Vec3(0),
      output: 'color',
      conditional: {
        prop: 'style',
        value: '2',
      },
    },
    amount: {
      label: 'Scale',
      value: 0.75,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    rotation: {
      label: 'Rotation',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    threshold: {
      label: 'Gamma',
      value: 0,
      min: -0.5,
      max: 0.5,
      step: 0.01,
      tooltip: 'Gamma correction',
      output: 'percent',
    },
  },
};
