<script>
import DesignItem from '../components/DesignItem.vue';
import Folder from '../components/Folder.vue';
import AdminFlyout from '../components/AdminFlyout.vue';
import TemplateItem from '../components/TemplateItem.vue';
import EditableText from '../components/EditableText.vue';
import DesignLoadingItem from '../components/DesignLoadingItem.vue';
import Navigation from '../components/Navigation.vue';
import ProModal from '../components/ProModal.vue';
import Button from '../components/Button.vue';
import Icon from '../components/Icon.vue';
import { UserStore } from "../stores/UserStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import router from '../router';

export default {
  components: {
      DesignItem,
      TemplateItem,
      Folder,
      AdminFlyout,
      EditableText,
      ProModal,
      Button,
      Navigation,
      Icon,
      DesignLoadingItem
  },
  data() {
      return {
          UserStore,
          DesignsStore,
          pendingDelete: null,
          creatingDesign: false,
          renaming: false,
          proModalOpen: false,
          contextMenuOpen: false,
      }
  },
  watch: {
    folderId() {
      this.fetchFolderDesigns();
    },
  },
  mounted() {
    this.fetchFolderDesigns();
  },
  computed: {
    numPlaceholder() {
      return UserStore.designCount || 0;
    },
    folderId() {
      return this.$route.params.folderId
    },
    folder() {
      return DesignsStore.state.folders.find(n => n.id === this.folderId)
    },
    folderDesigns() {
      return DesignsStore.state.designs.filter(n => !n.deleted && n.folderId === this.folderId).sort((a, b) => b.updatedAt - a.updatedAt);
    }
  },  
  methods: {
    fetchFolderDesigns() {
      DesignsStore.getFolderDesigns(this.folderId);
    },
    createDesign() {
      this.creatingDesign = true;
      DesignsStore.createDesign((id, vid) => {
        router.push('/edit/' + id);
      }, {folderId: this.folderId});
    },
    renameFolder(newName) {
      DesignsStore.renameFolder(this.folderId, newName);
    },
    goBackToDashboard() {
      this.$router.push('/dashboard');
    }
  }
}
</script>

<template>
  <div class="folder-container">
    <div v-if="!UserStore.id || DesignsStore.state.loadingDesigns" class="loading-bar"></div>
    <div class="folder-content">
      
      <!-- <Navigation @open-promodal="proModalOpen = true" /> -->
      <div class="folder-header">
        <div class="folder-title">
          <h1 class="h2 my-0">{{ folder ? folder.name : 'Loading folder...' }}</h1>
          <Button class="primary" icon="plus" :loading="creatingDesign" @click="createDesign">
            New project
          </Button>
        </div>
      </div>
      <div class="design-listing-container">
        <div class="design-container">
          <DesignItem
            v-for="design in folderDesigns"
            :design="design"
            :key="design.id"
          ></DesignItem>
          <template v-if="DesignsStore.state.loadingDesigns">
            <DesignLoadingItem
              v-for="index in 6"
              :key="'design_item_' + index"
            ></DesignLoadingItem>
          </template>
        </div>
        <div v-if="folderDesigns.length === 0 && !DesignsStore.state.loadingDesigns" class="empty-folder">
          <p>This folder is empty.</p>
        </div>
      </div>
      <ProModal v-if="proModalOpen" @close="proModalOpen = false" />
    </div>
  </div>
</template>


<style lang="scss" scoped>

// Folder-specific styles
.folder-header {
}

.folder-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: 1px solid var(--accent-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  color: var(--font-secondary-color);
  
  &:hover {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
}

.empty-folder {
  width: 100%;
  text-align: center;
  padding: 3rem;
  color: var(--font-secondary-color);
}

.h1 {
  font-family: 'Bebas Neue';
}

.logo-container {
  margin-top: 0.5rem;
  width: 3rem;

  svg {
    object-fit: contain;
  }
}
</style>