<script>
import AdminFlyout from '../components/AdminFlyout.vue';
import Navigation from '../components/Navigation.vue';
import EditableText from '../components/EditableText.vue';
import DesignLoadingItem from '../components/DesignLoadingItem.vue';
import Icon from '../components/Icon.vue';
import Input from '../components/Input.vue';
import Button from '../components/Button.vue';
import UsernameEditor from '../components/UsernameEditor.vue';
import InputField from '../components/InputField.vue';
import { UserStore } from "../stores/UserStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import { auth } from "../firestore/init.js";
import router from '../router';
import { StudioStore } from '../stores/StudioStore';
import { FirebaseStore } from '../stores/FirebaseStore';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  linkWithCredential,
  linkWithPopup
} from "firebase/auth";

export default {
  components: {
    EditableText,
    AdminFlyout,
    Navigation,
    Input,
    Button,
    Icon,
    DesignLoadingItem,
    UsernameEditor,
    InputField
  },
  data() {
    return {
      UserStore,
      DesignsStore,
      pendingDelete: null,
      renaming: false,
      proModalOpen: false,
      resettingPassword: false,
      password: '',
      addingPassword: false,
      savingAddedPassword: false,
      linkingGoogle: false,
    }
  },
  computed: {
    numPlaceholder() {
      return UserStore.designCount || 0;
    },
    subscription() {
      return UserStore.hasSubscription;
    },
    cancelledButActive() {
      return UserStore.hasPaidButCancelledSubscription();
    },
    cancelledInactive() {
      return UserStore.hasCancelledSubscription();
    },
  },
  methods: {
    createDesign() {
      DesignsStore.createDesign((id, vid) => {
        router.push('edit/' + id + '?vid=' + vid);
      }, {});
    },
    resetPassword() {
      if (this.resettingPassword) {
        return false;
      }
      this.resettingPassword = true;
      sendPasswordResetEmail(auth, UserStore.email).then(() => {
        console.log("Password reset email sent.");
        this.resettingPassword = false;
        alert("Password reset email sent. Please check your email.");
      }).catch((error) => {
        this.resettingPassword = false;
        console.error("Error sending password reset email: ", error);
        alert("Failed to send password reset email. Please try again.");
      });
    },
    setUserFirstName(e) {
      FirebaseStore.setFirstName(e);
      UserStore.firstName = e;
    },
    setUserLastName(e) {
      FirebaseStore.setLastName(e);
      UserStore.lastName = e;
    },
    openProModal() {
      StudioStore.state.signUpForPro = true;
    },
    handlePasswordSubmit(e) {
      e.preventDefault();
      this.linkGoogleWithEmailPassword(this.password);
    },
    linkGoogleWithEmailPassword(password) {
      this.savingAddedPassword = true;
      const credential = EmailAuthProvider.credential(UserStore.email, password);

      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          console.log("Google account linked with email/password", usercred.user);
          UserStore.updateProviderInfo(usercred.user);
          this.addingPassword = false;
          this.savingAddedPassword = false;
        })
        .catch((error) => {
          console.error("Error linking Google account with email/password", error);
          this.validation.error = error.code + ': ' + error.message;
          this.addingPassword = false;
          this.savingAddedPassword = false;
          window.alert(this.validation.error);
        });
    },
    linkGoogle() {
      const provider = new GoogleAuthProvider();
      this.linkingGoogle = true;

      linkWithPopup(auth.currentUser, provider)
        .then((result) => {
          console.log('Google account successfully linked', result.user);
          UserStore.updateProviderInfo(result.user);
          this.linkingGoogle = false;
        })
        .catch((error) => {
          console.error('Error linking Google account', error);
          this.linkingGoogle = false;
          window.alert(error.message);
        });
    }
  }
}
</script>


<template>
    <div class="admin-container">
      <!-- <Navigation /> -->
      <div class="design-listing-container">
        <div class="account-container">
          <h1 class="h2">Account</h1>

          <label class="small-text block mt-4 font-secondary-color">First name</label>
          <div class="flex w-100 small-text mt-1">
            <InputField
              :value="UserStore.firstName || ''"
              placeholder="Add your first name"
              output="string"
              class="w-100"
              @change="setUserFirstName"
            />
          </div>

          <label class="small-text block mt-4 font-secondary-color">Last name</label>
          <div class="flex w-100 small-text mt-1">
            <InputField
              :value="UserStore.lastName || ''"
              placeholder="Add your last name"
              output="string"
              class="w-100"
              @change="setUserLastName"
            />
          </div>

          <label class="block mt-4 small-text font-secondary-color">Username</label>
          <UsernameEditor />

          <label class="block mt-5 small-text font-secondary-color">Email</label>
          <p class="mb-0 mt-2">{{UserStore.email}}</p>
          
          <template v-if="UserStore.provider === 'google'">
            <p class="small-text font-secondary-color" v-if="UserStore.provider === 'google'">Auth managed by Google. <a v-if="!UserStore.password" href="#" class="link small-text" @click="addingPassword = true">Add a password</a></p>
            <a v-if="UserStore.password" href="#" class="small-text link mt-2 block" @click="resetPassword">
              <template v-if="resettingPassword">Sending reset request...</template>
              <template v-else>Send reset password link</template>
            </a>
          </template>
          <a v-else href="#" class="small-text link mt-2 block" @click="resetPassword">
            <template v-if="resettingPassword">Sending reset request...</template>
            <template v-else>Send reset password link</template>
          </a>

          <template v-if="UserStore.provider === 'email'">
            <a href="#" class="small-text link mt-2 block ml-0" @click="linkGoogle">
              <template v-if="linkingGoogle">Linking Google account...</template>
              <template v-else>Link Google account</template>
            </a>
          </template>

          <label class="block mt-5 small-text flex font-secondary-color">Plan</label>
          <template v-if="subscription">
            <template v-if="subscription.status === 'active'">
              <div class="mb-0 mt-2 flex">
                <div>
                  <span class="flex align-center">Legend <Icon icon="legend" style="color: var(--gold)" class="ml-1" size="12" /></span>
                  <a :href="subscription.links.customer_portal" target="_blank" class="small-text link mt-2 block">Manage your subscription</a>
                </div>
                <div class="ml-auto" style="text-align: right">
                  <div>Active</div>
                  <div class="small-text mt-2 block font-secondary-color" v-if="UserStore.hasPaidButCancelledSubscription()">Expires {{new Date(subscription.renewsAt).toDateString()}}</div>
                </div>
              </div>
            </template>
            <template v-else-if="cancelledButActive">
              <div class="mb-0 mt-2 flex">
                <div>
                  <span class="flex align-center">Legend <Icon icon="legend" style="color: var(--gold)" class="ml-1" size="12" /></span>
                  <a :href="subscription.links.customer_portal" target="_blank" class="small-text link mt-2 block">Re-subscribe</a>
                </div>
                <div class="ml-auto" style="text-align: right">
                  <div>Cancelled</div>
                  <div class="small-text mt-2 block font-secondary-color" v-if="UserStore.hasPaidButCancelledSubscription()">Paid through {{new Date(subscription.renewsAt).toDateString()}}</div>
                </div>
              </div>
            </template>
            <template v-else-if="cancelledInactive">
              <div class="mb-0 mt-2 flex">
                <div>
                  <span class="flex align-center">Free</span>
                  <a :href="subscription.links.customer_portal" target="_blank" class="small-text link mt-2 block">Re-subscribe</a>
                </div>
                <div class="ml-auto" style="text-align: right">
                  <div>Inactive</div>
                  <div class="small-text mt-2 block font-secondary-color" v-if="UserStore.hasPaidButCancelledSubscription()">Ended {{new Date(subscription.renewsAt).toDateString()}}</div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="UserStore.grandFathered">
              <div class="mb-0 mt-2 flex">
                <div>
                  <span class="flex align-center">Legend <Icon icon="legend" style="color: var(--gold)" class="mx-1" size="12" /> (Free trial)</span>
                  <a href="#" class="small-text link mt-2 block" @click="proModalOpen = true">Subscribe</a>
                </div>
                <div class="ml-auto" style="text-align: right">
                  <div>Active</div>
                  <div class="small-text mt-2 block font-secondary-color">Ends {{ new Date(2025, 0, 1).toDateString() }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="mb-0 mt-2">Free</p>
              <a href="#" class="small-text link mt-2 block" @click="proModalOpen = true">Upgrade</a>
            </template>
          </template>

          <div class="small-text mt-6 block font-secondary-color">For support or questions please email george@unicorn.studio.</div>
        </div>  
      </div>

      <div v-if="addingPassword" class="modal modal__prompt">
        <h3 class="h3 mb-3">Add a password</h3>
        <form @submit="handlePasswordSubmit">
        <Input v-model="password" type="password" class="small-text font-primary-color" />
        <p class="small-text font-secondary-color">This will allow you to log in with a password in addition to Google.</p>
        <div class="flex mt-3 ml-auto small-text">
          <Button class="secondary ml-auto" @click="addingPassword = false">Cancel</Button>
          <Button class="primary ml-2" type="submit" :loading="savingAddedPassword">Save</Button>
        </div>
        </form>
      </div>
    </div>

</template>


<style lang="scss" scoped>

.account-container {
  max-width: 50rem;
  margin: 3rem auto 0;
  border-radius: 0.5rem;
  padding: var(--unit5);
}

.link {
  text-decoration: underline;
  text-decoration-style: dotted;

}

</style>