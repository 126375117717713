import axios from 'axios';
import { reactive, computed } from 'vue';
import { UserStore } from './UserStore.js';
import { FirebaseStore } from './FirebaseStore.js';
import { StudioStore } from './StudioStore.js';
import { FontsStore } from './FontsStore.js';
import { Effect } from '../scripts/layerTypes/Effect.js';
import { logsnagTrack } from '../scripts/LogSnag.js';
import router from '../router';
import {
  listAll,
  getMetadata,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
  uploadBytes,
} from 'firebase/storage';
import { setDoc } from 'firebase/firestore';
import { auth } from '../firestore/init.js';

function extractFileName(input) {
  // Check if the input is already a simple file name by looking for the absence of protocol markers (://) and slash characters
  if (!input.includes('://') && !input.includes('/')) {
    return input; // It's already a simple file name
  }

  // If it's not a simple file name, decode the URL to handle encoded characters
  let decodedUrl = decodeURIComponent(input);
  while (input !== decodedUrl) {
    input = decodedUrl;
    decodedUrl = decodeURIComponent(input);
  }

  // Extract the file name using a regex that captures the last part of the path before any query parameters
  const regex = /\/([^\/?]+)(?=\.\w+)(\.\w+)(?=[?#]|$)/;
  const matches = regex.exec(decodedUrl);

  // If a match is found, return the concatenation of the captured groups to form the full file name with its extension
  return matches ? matches[1] + matches[2] : '';
}

function designTemplate(uid, name, tags, size, thumbnail, folderId, teamId) {
  return {
    creatorId: uid,
    createdAt: new Date(),
    deleted: false,
    name: name || 'Untitled project',
    updatedAt: new Date(),
    tags: tags || [],
    size: size || 'Desktop',
    hasEmbed: false,
    folderId,
    teamId: teamId || '',
    thumbnail:
      thumbnail ||
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUUAAAFFAQMAAABBum0eAAAABlBMVEXv7+////9mUzfqAAAAhUlEQVRo3u3WsQ3CQABDUSMKSkZgFEaD0RiFESgpEE4fpc+d8mq/2vpJ8mhfya39JJf2l5zaroaQJHkg2faZ3Nt3cm2/ybn9bwwkSR5IzvJgJElqBpIkNQNJkqPJWR6MJEnNQJKkZiBJcjQ5y4ORJKkZSJLUDCRJjiZneTCSJDUDSZK7ygV3tbuzNhunrAAAAABJRU5ErkJggg==',
    versionId: '',
  };
}

async function checkThumbnailExists(retryCount, thumbnailRef, fileName, file, resolve, reject) {
  try {
    const thumbnailURL = await getDownloadURL(thumbnailRef);
    resolve({
      src: thumbnailURL.replace('_%40thumbnail', ''),
      thumb: thumbnailURL,
    });

    StudioStore.state.imageUploading = false;
    addImageToStore(thumbnailURL, fileName, file);
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      if (retryCount < 5) {
        setTimeout(
          () => checkThumbnailExists(retryCount + 1, thumbnailRef, fileName, file, resolve, reject),
          1000 * (retryCount + 1)
        );
      } else {
        console.error('Thumbnail not found after several retries:', error);
        reject(error);
      }
    } else {
      console.error('Error checking for thumbnail:', error);
      reject(error);
    }
  }
}

function addImageToStore(thumbnailURL, fileName, file) {
  StudioStore.state.images.unshift({
    src: thumbnailURL.replace('_%40thumbnail', ''),
    name: fileName,
    thumb: thumbnailURL,
    aspectRatio: 1,
    delete() {
      const mainImageRef = FirebaseStore.storageRef(UserStore.id + '/' + file.name);
      const correctedThumbnailRef = FirebaseStore.storageRef(
        UserStore.id + '/' + file.name.replace(/(\.[\w\d_-]+)$/i, '_@thumbnail$1')
      );

      deleteObject(mainImageRef).catch(error => {
        alert('Delete failed: ' + error.message);
      });

      deleteObject(correctedThumbnailRef)
        .then(() => {
          const index = StudioStore.state.images.findIndex(n => n.src === thumbnailURL.replace('_%40thumbnail', ''));
          if (index !== -1) {
            StudioStore.state.images.splice(index, 1);
          }
        })
        .catch(error => {
          alert('Delete failed: ' + error.message);
        });
    },
  });
}

export const DesignsStore = {
  state: reactive({
    designs: [],
    templates: [],
    folders: [],
    displayedDesigns: computed(() => DesignsStore.state.designs.filter(n => !n.folderId)),
    lastDesignLoaded: null,
    copiedItem: '',
    loadingDesigns: true,
    loadingTemplates: false,
    fetchedDesigns: false,
    fetchedFolders: false,
    version: '1.4.8',
    changelog: 'This update is just bugfixes in the sdk.',
    breakingChange: false,
    hasMoreDesigns: true,
  }),

  async getDesigns(amount = 25) {
    try {
      this.state.loadingDesigns = true;
      const querySnapshot = await FirebaseStore.getDesigns(amount, this.state.lastDesignLoaded);

      // Get the last document for pagination
      const docs = querySnapshot.docs;
      if (docs.length > 0) {
        this.state.lastDesignLoaded = docs[docs.length - 1];
        // Set hasMoreDesigns based on whether we got fewer documents than requested
        this.state.hasMoreDesigns = docs.length === amount;
      } else {
        this.state.hasMoreDesigns = false;
      }

      // Create a Set of existing design IDs for O(1) lookup
      const existingDesignIds = new Set(this.state.designs.map(d => d.id));

      // Process new designs and add them only if they don't exist
      const newDesigns = docs
        .filter(doc => !existingDesignIds.has(doc.id))
        .map(doc => ({ id: doc.id, ...doc.data() }));

      // Append new designs to the existing array
      if (newDesigns.length) {
        this.state.designs = [...this.state.designs, ...newDesigns];
      }
    } catch (err) {
      console.error('Error fetching designs:', err);
    } finally {
      this.state.loadingDesigns = false;
      this.state.fetchedDesigns = true;
    }
  },

  async getFolders() {
    try {
      const querySnapshot = await FirebaseStore.getFolders();
      // Direct mapping without spread operator
      this.state.folders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      this.state.fetchedFolders = true;
      return this.state.folders;
    } catch (err) {
      console.error('Error fetching folders:', err);
    }
  },

  async getFolderDesigns(id) {
    try {
      this.state.loadingDesigns = true;
      const querySnapshot = await FirebaseStore.getFolderDesigns(id);

      // Create a Map of existing designs for O(1) lookup
      const existingDesignIds = new Set(this.state.designs.map(d => d.id));

      // Process all new designs in one batch
      const newDesigns = querySnapshot.docs
        .filter(doc => !existingDesignIds.has(doc.id))
        .map(doc => ({ id: doc.id, ...doc.data() }));

      if (newDesigns.length) {
        this.state.designs.push(...newDesigns);
      }
    } catch (err) {
      console.error('Error fetching folder designs:', err);
    } finally {
      this.state.loadingDesigns = false;
    }
  },

  async getTemplates(callback) {
    this.state.loadingTemplates = true;
    FirebaseStore.getTemplateDesigns()
      .then(docs => {
        this.state.templates = docs.docs.map(n => n.data());
        this.state.loadingTemplates = false;
      })
      .catch(err => {
        this.state.loadingTemplates = false;
        console.log(err);
      });
  },

  async getDesign(id) {
    if (this.state.designs.find(n => n.id === id)) {
      return await this.state.designs.find(n => n.id === id);
    } else {
      return (await FirebaseStore.getDesign(id)).data();
    }
  },
  async getVersion(id) {
    return await FirebaseStore.getVersion(id);
  },
  getAssets(ref) {
    return listAll(FirebaseStore.storageRef(ref));
  },
  async getImages(ref) {
    try {
      const storageRef = FirebaseStore.storageRef(ref);
      const res = await listAll(storageRef);
      const thumbnailFetchPromises = res.items
        .filter(itemRef => {
          // Only select thumbnails that don't start with "remix_"
          return /@thumbnail\./.test(itemRef.name) && !itemRef.name.startsWith('remix_');
        })
        .map(thumbnailRef =>
          getMetadata(thumbnailRef).then(metadata => {
            return getDownloadURL(thumbnailRef).then(url => {
              return { metadata, url, itemRef: thumbnailRef };
            });
          })
        );

      let thumbnails = await Promise.all(thumbnailFetchPromises);
      // Sort the thumbnails array by the `timeCreated` property of the metadata
      thumbnails = thumbnails.sort((a, b) => new Date(b.metadata.timeCreated) - new Date(a.metadata.timeCreated));

      thumbnails.forEach(thumbnail => {
        if (StudioStore.state.imagesBrowser.open) {
          const originalImageName = thumbnail.metadata.name.replace('_@thumbnail', '');
          const originalImageRef = FirebaseStore.storageRef(ref + '/' + originalImageName);

          StudioStore.state.images.push({
            src: thumbnail.url.replace('_%40thumbnail', ''),
            name: originalImageName,
            thumb: thumbnail.url, // Thumbnail URL
            aspectRatio: 1, // Adjust this as needed
            delete() {
              // Delete the thumbnail
              deleteObject(thumbnail.itemRef).catch(error => {
                alert('Error deleting thumbnail: ' + error.message);
              });

              // Delete the original image
              deleteObject(originalImageRef)
                .then(() => {
                  const index = StudioStore.state.images.findIndex(
                    n => n.src === thumbnail.url.replace('_%40thumbnail', '')
                  );
                  if (index !== -1) {
                    StudioStore.state.images.splice(index, 1);
                  }
                })
                .catch(error => {
                  alert('Error deleting image: ' + error.message);
                });
            },
          });
        }
      });
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  },

  async publishEmbed(designData) {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.post(
        'https://us-central1-unicorn-studio.cloudfunctions.net/publishEmbed',
        designData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('File uploaded successfully:', response.data);

      if (!StudioStore.state.design.hasEmbed) {
        StudioStore.state.design.hasEmbed = true;
        StudioStore.save();
      }

      return response;
    } catch (error) {
      logsnagTrack({
        channel: 'error',
        event: 'Embed error',
        description: `email: ${UserStore.email};${error}`,
        icon: '🌎',
        user_id: UserStore.id,
        notify: true,
        tags: {
          email: UserStore.email,
          uid: UserStore.id,
          id: StudioStore.state.design.versionId,
        },
      });

      console.error('Error uploading file:', error.response?.data);
      throw error;
    }
  },

  async prepareEmbed(history, options, id) {
    const images = await this.handleImages(history);

    const fontItems = history.filter(
      n =>
        n.layerType === 'text' &&
        n.fontCSS &&
        n.visible &&
        !n.fontCSS.src.includes('https://storage.googleapis.com') &&
        !n.fontCSS.src.includes('assets.unicorn.studio')
    );

    // Await the resolution of uploadFonts to get fonts
    const fonts = await this.uploadFonts(fontItems);

    const packagedHistory = history
      .filter(n => n.visible)
      .map(n => {
        let copy = n.copy(null, null, true);
        delete copy.local;
        delete copy.thumb;
        copy.packageShaders();

        if (n.layerType === 'text') {
          copy.windowWidth = StudioStore.state.canvasWidth;
          if (fonts.find(o => o.id === n.local.id)) {
            copy.fontCSS.src = fonts.find(o => o.id === n.local.id).url;
          }
        }


        const image = images.find(i => i.id === n.local.id);
        if (image) {
          if (n.layerType === 'image') {
            copy.src = image ? image.src : copy.src;
          } else {
            copy.texture = {
              src: image ? image.src : copy.texture.src,
              sampler: copy.texture.sampler,
            }
          }
        }
        return copy;
      });

    if (!UserStore.hasProAccess) {
      const logo = new Effect({
        type: 'freeLogo',
      });
      logo.packageShaders();
      packagedHistory.push(logo);
    }

    delete options.version;

    return {
      history: packagedHistory,
      options: options,
      version: DesignsStore.state.version,
      id,
    };
  },

  async createEmbed(history, id, options) {
    try {
      const data = await this.prepareEmbed(history, options, id);
      data.fileName = id; // Add the fileName property to the data object
      data.invalidateCache = UserStore.hasProAccess && options.isProduction;

      data.logsnagData = {
        email: UserStore.email,
        uid: UserStore.id,
        firstPublish: !StudioStore.state.design.hasEmbed,
        description: `email: ${UserStore.email}; https://unicorn.studio/embed/${StudioStore.state.design.versionId}`,
        pro: UserStore.hasProAccess,
      };

      await this.publishEmbed(data);
      return data; // Resolve the promise with the data object
    } catch (error) {
      console.error('Error creating embed:', error);
      throw error; // Reject the promise with the caught error
    }
  },

  async handleImages(items) {
    let imageData = [];

    // Filter items to include both 'image' layers and layers with a 'texture' attribute
    let images = items.filter(n => n.layerType === 'image' || n.texture);

    for (const item of images) {
      // Determine the source of the image or texture
      let src = item.layerType === 'image' ? item.src : item.texture.src;

      let filename = decodeURIComponent(src.split('?')[0].split('/').pop());
      filename = filename.replace(UserStore.id + '/', '');

      try {
        let data = {
          userId: UserStore.id,
          imageUrl: filename,
        };
        const token = await auth.currentUser.getIdToken(true); // Move await outside
        const response = await axios.post('https://us-central1-unicorn-studio.cloudfunctions.net/handleImages', data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        imageData.push({
          src: response.data.imageUrl,
          thumb: response.data.thumbnailUrl,
          id: item.local.id,
        });
      } catch (err) {
        console.error(err);
      }
    }

    return imageData;
  },

  uploadFonts(items) {
    return new Promise(async (resolve, reject) => {
      let fonts = [];

      for (const item of items) {
        let fontData = FontsStore.state.fonts[item.fontFamily];
        try {
          let payload = {};
          if (fontData && fontData.category === 'custom') {
            payload.url = fontData.files.regular;
            payload.fileName = `fonts/custom/${fontData.user_id}/${fontData.family}/${fontData.fileNames.regular}`;
          } else {
            payload.url = item.fontCSS.src.includes('fonts.gstatic.com')
              ? item.fontCSS.src
              : extractFileName(item.fontCSS.src);
          }

          const response = await axios.post(
            'https://us-central1-unicorn-studio.cloudfunctions.net/copyFontToStorage',
            payload
          );
          fonts.push({
            url: response.data.url,
            id: item.local.id,
          });
        } catch (err) {
          console.error(err);
        }
      }

      resolve(fonts);
    });
  },
  uploadImage(e, paste) {
    return new Promise((resolve, reject) => {
      const file = paste || e.target.files[0];
      if (file) {
        if (file.size < 1_000_000) {
          let baseFileName = file.name.replace(/(\.[\w\d_-]+)$/, '');
          let fileExtension = file.name.match(/(\.[\w\d_-]+)$/)[1];
          let storageRef = FirebaseStore.storageRef(UserStore.id + '/' + file.name);

          const metadata = {
            cacheControl: 'public, max-age=31536000',
          };

          const checkFileExistsAndUpdateName = async (name, version = 0) => {
            const fileName = version === 0 ? name : `${baseFileName}_v${version}${fileExtension}`;
            const fileRef = FirebaseStore.storageRef(UserStore.id + '/' + fileName);

            try {
              await getDownloadURL(fileRef);
              return checkFileExistsAndUpdateName(name, version + 1);
            } catch (error) {
              if (error.code === 'storage/object-not-found') {
                return fileName; // This is a new or available file name
              }
              throw error;
            }
          };

          checkFileExistsAndUpdateName(file.name)
            .then(newFileName => {
              storageRef = FirebaseStore.storageRef(UserStore.id + '/' + newFileName); // Update the reference with the new name
              const uploadTask = uploadBytesResumable(storageRef, file, metadata);

              uploadTask.on(
                'state_changed',
                snapshot => {
                  const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0);
                  StudioStore.state.imageUploadProgress = progress;
                },
                error => {
                  console.error('Upload failed:', error);
                  alert('Upload failed: ' + error.message);
                  reject(error);
                },
                () => {
                  console.log('Upload successful');
                  const thumbnailName = newFileName.replace(/(\.[\w\d_-]+)$/i, '_@thumbnail$1');
                  const thumbnailRef = FirebaseStore.storageRef(UserStore.id + '/' + thumbnailName);
                  checkThumbnailExists(0, thumbnailRef, newFileName, file, resolve, reject); // Pass resolve and reject
                }
              );
            })
            .catch(error => {
              console.error('Error in checking file existence or uploading:', error);
              reject(error);
            });
        } else {
          alert('Image too big, must be under 1MB. Small images will perform much better, ideally under 250kb.');
          reject(
            new Error('Image too big, must be under 1MB. Small images will perform much better, ideally under 250kb.')
          );
        }
      } else {
        reject(new Error('No file provided'));
      }
    });
  },

  duplicateDesign(design, folderId = null) {
    StudioStore.state.loading = true;

    // Function to handle adding "Copy of" or incrementing copy numbers
    const getNewName = name => {
      // Check if the design name starts with "Copy of"
      if (name.startsWith('Copy of ')) {
        // Extract the base name and any potential copy number
        const match = name.match(/^(Copy of .+?)(?: \((\d+)\))?$/);
        const baseName = match[1];
        const copyNumber = match[2] ? parseInt(match[2]) : 1;

        // Increment the copy number
        return `${baseName} (${copyNumber + 1})`;
      } else {
        // First copy
        return `Copy of ${name}`;
      }
    };

    const newName = getNewName(design.name);

    this.getVersion(design.versionId).then(resp => {
      this.createDesign(
        id => {
          StudioStore.state.loading = false;
          const currentRoute = router.currentRoute.value.path;
          if (currentRoute === '/') {
            router.push('/edit/' + id);
          } else if (currentRoute.startsWith('/edit/')) {
            router.replace({ path: `/edit/${id}` });
          } else {
            router.push('/edit/' + id);
          }
        },
        {
          name: newName, // Use the new name with "Copy of" or incremented copy number
          tags: design.tags,
          size: design.size,
          history: resp.data().history,
          thumbnail: design.thumbnail,
          folderId: folderId,
          customWidth: design.customWidth || null,
          customHeight: design.customHeight || null,
        }
      );
    });
  },

  createDesign(
    callback,
    { name = '', tags = null, size = '', history = null, templates = false, thumbnail = null, folderId = null, customWidth = null, customHeight = null }
  ) {
    const temp = designTemplate(UserStore.id, name, tags, size, thumbnail, folderId);
    temp.customWidth = customWidth;
    temp.customHeight = customHeight;
    FirebaseStore.createDesign(temp).then(designRef => {
      const designId = designRef.id;
      FirebaseStore.createVersion({
        creatorId: UserStore.id,
        designId: designId,
        history: history || [],
      }).then(versionRef => {
        setDoc(designRef, { versionId: versionRef.id }, { merge: true })
          .then(doc => {
            if (callback) {
              if (!templates) {
                this.state.designs.unshift({ ...temp, id: designId, versionId: versionRef.id });
              }

              if (!templates) {
                logsnagTrack({
                  channel: 'new-project',
                  event: 'New project',
                  description: `email: ${UserStore.email}`,
                  icon: '💎',
                  user_id: UserStore.id,
                  notify: true,
                  tags: {
                    email: UserStore.email,
                    uid: UserStore.id,
                    name: name,
                  },
                });
              }

              callback(designId, versionRef.id);
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    });
  },
  createFolder(callback) {
    // Determine the base name for the folder
    const baseName = 'Untitled folder';

    // Filter existing folders to find the ones that start with 'Untitled folder'
    const existingFolders = this.state.folders.filter(folder => folder.name.startsWith(baseName));

    // Find the highest suffix number if there are existing folders with a similar name
    let newName = baseName;
    if (existingFolders.length > 0) {
      let highestNumber = 0;

      existingFolders.forEach(folder => {
        const match = folder.name.match(/^Untitled folder(?: \((\d+)\))?$/);
        if (match && match[1]) {
          const number = parseInt(match[1], 10);
          if (number > highestNumber) {
            highestNumber = number;
          }
        }
      });

      // Increment the highest number found
      newName = `${baseName} (${highestNumber + 1})`;
    }

    // Create the folder with the incremented name
    let folderData = {
      creatorId: UserStore.id,
      createdAt: new Date(),
      name: newName,
    };

    FirebaseStore.createFolder(folderData)
      .then(folderRef => {
        folderData.id = folderRef.id;
        this.state.folders = [folderData, ...this.state.folders];
        if (callback) {
          callback();
        }
      })
      .catch(err => {
        if (callback) {
          callback();
        }
        console.log(err);
      });
  },

  renameDesign(id, name) {
    this.state.designs.find(n => n.id === id).name = name;
    setDoc(FirebaseStore.getDesignRef(id), { name: name }, { merge: true })
      .then(resp => {
        //console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
  },
  renameFolder(id, name) {
    this.state.folders.find(n => n.id === id).name = name;
    setDoc(FirebaseStore.getFolderRef(id), { name: name }, { merge: true })
      .then(resp => {
        //console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
  },
  deleteDesign(id) {
    setDoc(FirebaseStore.getDesignRef(id), { deleted: true }, { merge: true })
      .then(resp => {
        this.state.designs = this.state.designs.filter(n => n.id !== id);
      })
      .catch(err => {
        console.log(err);
      });
  },
  deleteFolder(id) {
    FirebaseStore.deleteFolder(id).then(() => {
      this.state.folders = this.state.folders.filter(n => n.id !== id);
      this.state.designs.forEach(n => {
        if (n.folderId === id) {
          n.folderId = null;
        }
      });
    });
  },

  moveDesignToFolder(design, folderId) {
    setDoc(FirebaseStore.getDesignRef(design.id), { folderId }, { merge: true })
      .then(resp => {
        design.folderId = folderId;
        this.updateDesignLocally(design);
      })
      .catch(err => {
        console.log(err);
      });
  },
  saveDesign(design, history, callback) {
    setDoc(FirebaseStore.getDesignRef(design.id), design, { merge: true })
      .then(resp => {
        this.updateDesignLocally(design);
      })
      .catch(err => {
        console.log(err);
      });
    setDoc(
      FirebaseStore.getVersionRef(design.versionId),
      { history: [...history].map(item => item.package()) },
      { merge: true }
    )
      .then(resp => {
        if (callback) {
          callback(resp);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  saveVersionOptions(versionId, options, callback) {
    setDoc(FirebaseStore.getVersionRef(versionId), { options: options }, { merge: true })
      .then(resp => {
        if (callback) {
          callback(resp);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateDesignLocally(design) {
    const index = this.state.designs.map(n => n.id).indexOf(design.id);
    this.state.designs[index] = design;
  },

  async createMsdf(url, precision) {
    const token = await auth.currentUser.getIdToken(true);
    StudioStore.state.loading = true;
    precision = +precision;
    let size = 512;
    if(precision === 0) {
      size = 256;
    } else if(precision === 2) {
      size = 1024;
    }

    let params = {
      scale: 1,
      range: size/5,
      padding: size/5,
      msdfSize: size,
      downScale: 0.5,
    }

    try {
      const response = await fetch('https://us-central1-unicorn-studio.cloudfunctions.net/generateMSDF', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: UserStore.id,
          params: params,
          svgUrl:
            url ||
            'https://storage.googleapis.com/unicornstudio-production/images/Zz28X5RDkvcGGVYLr9X6QdTIhxy1/metalab.svg',
        }),
      });

      const result = await response.json();
      if (response.ok) {
        StudioStore.state.loading = false;
        return result.imageUrl;
      } else {
        console.error('Error:', result.error);
        alert('Error generating MSDF: ' + result.error);
        StudioStore.state.loading = false;
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error generating MSDF: ' + error.message);
      StudioStore.state.loading = false;
    }
  },

  async copyRemixAssets(sourceUserId, targetUserId, history, remixId = null) {
    // Find all image assets in the history
    const imageAssets = [];
    
    // Extract image URLs from history
    if (Array.isArray(history)) {
      history.forEach(item => {
        // Look for image assets in the history items
        if (item.layerType === 'image' && item.src) {
          // Extract just the filename from the URL
          const url = item.src;
          if (url.includes('firebasestorage.googleapis.com')) {
            imageAssets.push(url);
          }
        }
        
        // Also check for any texture maps in materials or effects
        if ((item.layerType === 'effect') && item.texture && item.texture.src) {
          const url = item.texture.src;
          imageAssets.push(url);
        }
        
        // Check for font files if applicable
        if (item.layerType === 'text' && item.fontCSS && item.fontCSS.src) {
          const fontUrl = item.fontCSS.src;
          //check if fontUrl is is assets url and has userid
          imageAssets.push(fontUrl);
        }
      });
    }
    
    // Remove duplicates
    const uniqueAssets = [...new Set(imageAssets)];
    
    // If there are assets to copy, call the cloud function
    try {
      console.log(`Copying ${uniqueAssets.length} assets from user ${sourceUserId} to user ${targetUserId}`);
      
      // Get auth token for the cloud function call
      const token = await auth.currentUser.getIdToken(true);
      
      // Call the cloud function to copy the assets and increment remix count
      const response = await axios.post(
        'https://us-central1-unicorn-studio.cloudfunctions.net/copyRemixAssets',
        {
          sourceUserId,
          targetUserId,
          assets: uniqueAssets,
          remixId: remixId // Pass the remixId to the cloud function
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Assets copied successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error copying remix assets:', error);
      // Don't throw the error - we don't want to fail the remix if asset copying fails
      // Just log it and continue
    }
    
    return { success: true, results: [] };
  },

  async createRemix(design) {
    try {
      // First, get the current version data
      const versionData = await this.getVersion(design.versionId);
      if (!versionData) {
        throw new Error('Could not find version data for design');
      }
      
      // Create a new version with the history from the original design
      const versionRef = await FirebaseStore.createVersion({
        creatorId: UserStore.id,
        designId: design.id,
        history: versionData.data().history,
        createdAt: new Date()
      });

      let name = UserStore.firstName && UserStore.lastName ? UserStore.firstName + ' ' + UserStore.lastName : '';
      
      // Create a remix object that references the new version
      const remixData = {
        designId: design.id,
        originalVersionId: design.versionId,
        versionId: versionRef.id,
        creatorId: UserStore.id,
        creatorUsername: UserStore.username || '',
        creatorName: name,
        name: design.name,
        thumbnail: design.thumbnail,
        avatar: UserStore.avatar,
        createdAt: new Date(),
        size: design.size,
        customWidth: design.customWidth || null,
        customHeight: design.customHeight || null,
        discoverable: false,
        remixCount: 0
      };
      
      // Save the remix to Firestore
      return await FirebaseStore.createRemix(remixData);
    } catch (error) {
      console.error('Error creating remix:', error);
      throw error;
    }
  },
  
  async getRemix(remixId) {
    try {
      return await FirebaseStore.getRemix(remixId);
    } catch (error) {
      console.error('Error getting remix:', error);
      throw error;
    }
  },
  
  async remixDesign(remixId, successCallback, errorCallback) {
    try {
      StudioStore.state.loading = true;
      
      // Get the remix data
      const remixDoc = await this.getRemix(remixId);
      if (!remixDoc.exists()) {
        throw new Error('Remix not found');
      }
      
      const remixData = remixDoc.data();
      
      // Get the version data that was created for this remix
      const versionData = await this.getVersion(remixData.versionId);
      if (!versionData.exists()) {
        throw new Error('Version data not found');
      }
      
      // Get the history from the version data
      let history = versionData.data().history;
      const sourceUserId = remixData.creatorId;
      const targetUserId = UserStore.id;
      
      // Copy any image assets if needed
      await this.copyRemixAssets(sourceUserId, targetUserId, history, remixId);
      
      // Update image URLs in the history to point to the new user's files
      history = history.map(item => {
        // Create a copy of the item to avoid modifying the original
        const updatedItem = { ...item };
        
        // Update image src URLs
        if (item.layerType === 'image' && item.src) {
          // Use a more robust pattern that handles URL encoding
          updatedItem.src = item.src.replace(
            new RegExp(`${sourceUserId}(%2F|/)`, 'g'), 
            `${targetUserId}$1`
          );
        }
        
        // Update texture src URLs
        if ((item.layerType === 'effect') && 
            item.texture && item.texture.src) {
          updatedItem.texture = {
            ...item.texture,
            src: item.texture.src.replace(
              new RegExp(`${sourceUserId}(%2F|/)`, 'g'), 
              `${targetUserId}$1`
            )
          };
        }
        
        // Update font URLs if needed
        if (item.layerType === 'text' && item.fontCSS && item.fontCSS.src) {
          updatedItem.fontCSS = {
            ...item.fontCSS,
            src: item.fontCSS.src.replace(
              new RegExp(`${sourceUserId}(%2F|/)`, 'g'), 
              `${targetUserId}$1`
            )
          };
        }
        
        return updatedItem;
      });
      
      // Create a new design based on the remix with the updated history
      this.createDesign(
        (designId, versionId) => {
          StudioStore.state.loading = false;
          
          if (successCallback) {
            successCallback(designId, versionId);
          }
        },
        {
          name: `${remixData.name} (Remix)`,
          tags: [],
          size: remixData.size,
          history: history, // Use the updated history
          thumbnail: remixData.thumbnail,
          customWidth: remixData.customWidth,
          customHeight: remixData.customHeight,
        }
      );
    } catch (error) {
      StudioStore.state.loading = false;
      console.error('Error remixing design:', error);
      if (errorCallback) {
        errorCallback(error.message);
      } else {
        throw error;
      }
    }
  },
  
  async getRemixByDesignId(designId) {
    try {
      const querySnapshot = await FirebaseStore.getRemixesByDesign(designId);
      
      if (querySnapshot.empty) {
        console.log('No remixes found for this design');
        return null;
      }
      
      return querySnapshot.docs[0];
    } catch (error) {
      console.error('Error getting remix by design ID:', error);
      throw error;
    }
  },

  async deleteRemix(remixId) {
    if (!remixId) return;
    await FirebaseStore.deleteRemix(remixId);
  },

  async saveRemix(remixId, data) {
    try {
      await setDoc(FirebaseStore.getRemixRef(remixId), data, { merge: true });
      return true;
    } catch (error) {
      console.error('Error saving remix:', error);
      throw error;
    }
  },

  clear() {
    // Reset all user-specific state
    this.state.designs = [];
    this.state.templates = [];
    this.state.folders = [];
    this.state.lastDesignLoaded = null;
    this.state.copiedItem = '';
    this.state.loadingDesigns = true;
    this.state.loadingTemplates = false;
    this.state.fetchedDesigns = false;
    this.state.fetchedFolders = false;
    this.state.hasMoreDesigns = true;
  },
};
