<script>
import { UserStore } from "../stores/UserStore.js";
import Button from "../components/Button.vue";
import Icon from "../components/Icon.vue";
import RemixItem from "../components/RemixItem.vue";
import { FirebaseStore } from "../stores/FirebaseStore.js";
export default {
  name: 'CommunityView',
  components: {
    Button,
    Icon,
    RemixItem
  },
  data() {
    return {
      user: UserStore,
      scene: null,
      remixIds: [
        'eVin7Nhvih9DHP4idGYU',
        'ljU2VTHYSZSSACv8kwF8',
        'lqgX7NjDaHKgdM9b2qLo',
        'tJE8kfecasaA61b0hOlr',
        'CFNZ7cUmsItVYIf8fFyz',
        'MNNBfg6eqFnJ52x1xDJL',
        '43TLALQJoW7PVAPCPJ8V',
        '9EQPpU1gYDlajK2RPI7H',
        '7NH0ivICRStcUijGwHvI',
        '3zG0FndmAgqzcMKffLcL',
        '9HK3Gnjoe8rnWMvpBaZ1',
        'A4kT7LA2KrtJVB5QKhyL',
        'TCT5BDHs8mAGMnam5CSG',
        'nkSQOsM0KPMikum0M2wi',
        'v9xa7k0GPn16iXQkkfkx',
        'fkh4xxeFQLpxWStESFye',
        'zW8AeHJSSXCP9vD78jjt',
        'od6eBl7Wp9yHYbQMOcuD',
        'LeoDWbnaaGWlVLbnqohn',
        'KdM53VyDc16OcwRlR9Id'
      ],
      remixes: []
    }
  },
  methods: {
    goToDashboard() {
      this.$router.push('/dashboard');
    }
  },
  created() {
    UnicornStudio.addScene({
      elementId: 'community-scene',
      projectId: 'NJ7kZX1PbbLex0XeQtX2?production=true'
    }).then(scene => {
      this.scene = scene;
    });

    this.remixIds.forEach(remixId => {
      FirebaseStore.getRemix(remixId).then(remix => {
        if(remix.exists()) {
          this.remixes.push({...remix.data(), id: remix.id});
        }
      });
    });
  },
  beforeUnmount() {
    if (this.scene) {
      this.scene.element.innerHTML = '';
      this.scene.destroy();
    }
  }
}
</script>

<template>
  <div class="community-view">
    <!-- <div class="page-header">
      <h1 class="h2">Community</h1>
    </div> -->

    <div class="empty-state-container">
      <div class="empty-state">
        <div class="empty-state-scene" id="community-scene"></div>
        <div class="inner-content">
          <h2 class="h2">Contribute to the Community Gallery</h2>
          <p class="empty-state-text">
            We're building a space to showcase amazing creations from our community. 
            Want to contribute? Create a design, enable "Allow Remix" and "Make Discoverable" 
            in your sharing settings.
          </p>
        </div>
      </div>
    </div>

    <div class="design-container mb-6 mt-6">
      <RemixItem v-for="remix in remixes" :key="remix.id" :design="remix" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.community-view {
  padding: 2rem;
  
  .page-header {
    margin-bottom: 3rem;
    
    .h1 {
      margin-bottom: 0.5rem;
    }
    
    .subtitle {
      color: var(--font-secondary-color);
      font-size: 1.6rem;
    }
  }
  
  .empty-state-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 12rem);
  }

  .inner-content {
    padding: 4rem;

  }
  
  .empty-state {
    border-radius: 0.8rem;
    max-width: 60rem;
    text-align: center;
    
    .empty-state-icon {
      font-size: 4rem;
      margin-bottom: 2rem;
      color: var(--primary-color);
    }
    
    .h2 {
      margin-bottom: 1.5rem;
    }
    
    .empty-state-text {
      font-size: 1.6rem;
      color: var(--font-secondary-color);
      line-height: 1.5;
    }
    
    .action-buttons {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
    }
  }

  .empty-state-scene {
    width: 60rem;
    height: 20rem;
    margin: 2rem auto 0;
    border-radius: 0.8rem;
    overflow: hidden;
  }
}
</style> 