<script>
import { UserStore } from "../stores/UserStore.js";
import AdminFlyout from './AdminFlyout.vue';
import ProModal from './ProModal.vue';
import TrialInfo from './TrialInfo.vue';
import Button from './Button.vue';
import Icon from './Icon.vue';

export default {
    components: {
      AdminFlyout,
      ProModal,
      TrialInfo,
      Button,
      Icon
    },
    data() {
      return {
          UserStore,
          contextMenuOpen: false,
      }
    }
}
</script>

<template>
  <div class="navigation flex align-center">
    <div class="flex align-center">
      <div class="logo-container">
        <RouterLink
          to="/dashboard"
          event="click"
          draggable="false"
        >
          <svg width="24px" height="24px"  viewBox="0 0 102 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.219 0.488281H32.1798H46.1494C59.8085 0.488281 61.4972 11.5198 60.5069 16.5089C56.1461 38.4795 47.2636 83.1691 46.6206 86.1626C46.2038 88.1028 47.1067 88.3799 47.7847 86.4397C56.2015 62.3534 73.329 13.3491 74.5042 10.023C76.3335 4.89534 83.2629 -1.17476 92.5482 1.09806C99.9764 2.91632 102 10.91 102 14.6241V90.6805C102 96.0299 96.157 106.169 83.8727 102.821C71.5883 99.4724 72.6194 87.7332 74.6705 82.2821C81.7292 65.7996 96.1403 32.1248 97.3156 29.2866C97.9468 27.7621 97.2107 27.4018 96.29 28.76C84.002 46.8871 58.1565 85.0151 53.0787 92.5098C48.0009 100.005 41.8532 101.961 38.0005 101.961C8.79748 101.961 0 73.0245 0 58.5838V15.3171C0 3.58708 9.47933 0.543716 14.219 0.488281Z" fill="#fff"/></svg>
        </RouterLink>
      </div>
    </div>
    <div class="profile-container flex align-center">
      <a class="mr-4 link" target="_blank" href="https://www.unicorn.studio/docs">Help docs</a>
      <a href="https://discord.gg/yCvM5qeTbv" target="_blank" class="mr-5 link flex align-center"><Icon class="mr-1" icon="discord" /> Join Discord</a>
      <TrialInfo 
        v-if="(UserStore.hasActiveTrial || UserStore.trialEnded) && !UserStore.hasSubscription" class="mr-4" 
        @sign-up="$emit('open-promodal')"  
        :showButton="true"
      />
      <AdminFlyout v-if="UserStore.email" :letter="UserStore.email[0]">
        <RouterLink
          to="/account"
          event="click"
          style="text-decoration: none"
          draggable="false"
        >
          <div class="context-menu-link">Account</div>
        </RouterLink>
        <a @click="UserStore.signOut" class="context-menu-link" href="javascript:void(0)" title="Delete">Logout</a>
      </AdminFlyout>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.logo-container {
  margin-top: 0.5rem;
  width: 3rem;

  svg {
    object-fit: contain;
  }
}

.navigation {
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

</style>