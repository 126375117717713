import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform sampler2D uSprite;
uniform sampler2D uCustomTexture;
uniform float uScale;
uniform float uMix;
uniform int uGlyph;
uniform int uShowBg;
uniform int uGlyphCount;
uniform int uInvertLuma;
uniform int uMonochrome;
uniform vec2 uPos;
uniform vec3 uBackground;
uniform vec3 uColor;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

const float GLYPH_HEIGHT = 40.0;
const float DEFAULT_NUM_SPRITES = 10.0;
const float DEFAULT_NUM_ROWS = 6.0;

void main() {
    vec2 uv = vTextureCoord;
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    float aspectRatio = uResolution.x / uResolution.y;
    float aspectCorrection = mix(aspectRatio, 1./aspectRatio, 0.5);

    float gridSize = (uScale + 0.01) * 0.05;

    float baseGrid = 1.0 / gridSize;
    vec2 cellSize = vec2(1.0/(baseGrid * aspectRatio), 1.0/baseGrid) * aspectCorrection;
    vec2 offsetUv = uv - pos;
    vec2 cell = floor(offsetUv / cellSize);
    vec2 cellCenter = (cell + 0.5) * cellSize;
    vec2 pixelatedCoord = cellCenter + pos;
    
    vec4 bg = texture(uTexture, vTextureCoord);
    vec4 color = texture(uTexture, pixelatedCoord);

    float luminance = dot(color.rgb, vec3(0.2126, 0.7152, 0.0722));
    luminance = mix(luminance, 1.0 - luminance, float(uInvertLuma));

    float scaleFactor = gridSize / GLYPH_HEIGHT;

    ivec2 customTextureSize = textureSize(uCustomTexture, 0);
    float customSpriteWidth = float(customTextureSize.x);
    
    float numSprites = mix(DEFAULT_NUM_SPRITES, customSpriteWidth / GLYPH_HEIGHT, float(uGlyph == 6));
    float numGlyphRows = mix(DEFAULT_NUM_ROWS, 1.0, float(uGlyph == 6));

    float spriteIndex = clamp(floor(luminance * numSprites), 0.0, numSprites - 1.0);
    float glyphIndex = mix(5.0 - float(uGlyph), 0.0, float(uGlyph == 6));

    float normalizedSpriteSizeX = 1.0 / numSprites;
    float normalizedSpriteSizeY = 1.0 / numGlyphRows;

    vec2 spriteSheetUV = vec2(
        spriteIndex * normalizedSpriteSizeX,
        glyphIndex / numGlyphRows
    );

    vec2 spriteSize = vec2(GLYPH_HEIGHT / aspectRatio, GLYPH_HEIGHT) * scaleFactor * aspectCorrection;
    vec2 localOffset = mod(uv - pos, spriteSize) / spriteSize;

    spriteSheetUV += vec2(
        localOffset.x * normalizedSpriteSizeX,
        localOffset.y * normalizedSpriteSizeY
    );

    vec4 spriteColor1 = texture(uCustomTexture, spriteSheetUV);
    vec4 spriteColor2 = texture(uSprite, spriteSheetUV);
    vec4 spriteColor = mix(spriteColor2, spriteColor1, float(uGlyph == 6));

    float alpha = smoothstep(0.0, 1.0, spriteColor.r);

    vec3 cc = (color.rgb - spriteIndex * 0.04) * 1.4;
    vec3 col = mix(cc, uColor, float(uMonochrome));
    vec3 dithered = mix(
        mix(vec3(0.0), vec3(1.0), float(uInvertLuma)), 
        col, 
        alpha
    );
    color.rgb = mix(bg.rgb, dithered, uMix);

    ${computeFragColor('color')}
}
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0,
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 1,
    },
    glyphSet: {
      name: 'uGlyph',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    monochrome: {
      name: 'uMonochrome',
      type: '1i',
      value: 0,
    },
    invertLuma: {
      name: 'uInvertLuma',
      type: '1i',
      value: 0,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(0),
    },
    background: {
      name: 'uBackground',
      type: '3f',
      value: new Vec3(0),
    },
    ...universalUniformParams,
  },
};

export const ASCII = {
  id: 'ascii',
  label: 'Glyph dither',
  params,
  aspectRatio: 1,
  texture: {
    src: 'https://assets.unicorn.studio/media/ascii_spritesheet_larger.png',
    sampler: 'uSprite',
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    glyphSet: {
      label: 'Glyph set',
      value: 0,
      options: {
        0: 'jetbrains',
        1: 'foundy',
        2: 'space',
        3: 'spline',
        4: 'symbols 1',
        5: 'symbols 2',
        6: 'custom',
      },
    },
    texture: {
      label: 'Sprite',
      value: {},
      tooltip:
        'Tiles are 40x40 white on black, going dark to light from left to right. Example: for a three tile sprite, image dimensions must be 120x40.',
      output: 'texture',
      conditional: {
        prop: 'glyphSet',
        value: '6',
        notEqual: false,
      },
      responsiveDisabled: true,
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    monochrome: {
      label: 'Monochrome',
      value: 0,
      classic: true,
      options: {
        1: 'On',
        0: 'Off',
      },
      tooltip: 'Limit to the selected color',
    },
    color: {
      label: 'Color',
      value: new Vec3(0, 1, 1),
      output: 'color',
      conditional: {
        prop: 'monochrome',
        value: 1,
      },
    },
    invertLuma: {
      label: 'Invert order',
      value: 0,
      classic: true,
      options: {
        1: 'On',
        0: 'Off',
      },
      tooltip: 'Inverts the order of the glyphs. Inverted is better for light scenes.',
    },
    ...interactiveProperties
  },
};
