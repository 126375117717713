import { Vec2, Vec3 } from 'curtainsjs';
import { StudioStore } from '../stores/StudioStore';

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

function interpolate(start, end, progress) {
  if (start?.type === 'Vec3' && end?.type === 'Vec3') {
    return new Vec3(
      start._x + (end._x - start._x) * progress,
      start._y + (end._y - start._y) * progress,
      start._z + (end._z - start._z) * progress
    );
  }
  if (start?.type === 'Vec2' && end?.type === 'Vec2') {
    return new Vec2(
      start._x + (end._x - start._x) * progress,
      start._y + (end._y - start._y) * progress
    );
  }
  return start + (end - start) * progress;
}

function weightedLerp(current, last, amount) {
  for (let i = 0; i < amount; i++) {
    current = (current + last) / 2;
  }
  return +((current + last) / 2).toFixed(4);
}

export function getEaseFunction(ease) {
  switch (ease) {
    case 'linear':
      return x => x;
    case 'easeInQuad':
      return x => x * x;
    case 'easeOutQuad':
      return x => 1 - (1 - x) * (1 - x);
    case 'easeInOutQuad':
      return x => (x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2);
    case 'easeInCubic':
      return x => x * x * x;
    case 'easeInOutCubic':
      return x => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2);
    case 'easeOutCubic':
      return x => 1 - Math.pow(1 - x, 3);
    case 'easeInQuart':
      return x => x * x * x * x;
    case 'easeOutQuart':
      return x => 1 - Math.pow(1 - x, 4);
    case 'easeInOutQuart':
      return x => (x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2);
    case 'easeInQuint':
      return x => x * x * x * x * x;
    case 'easeOutQuint':
      return x => 1 - Math.pow(1 - x, 5);
    case 'easeInOutQuint':
      return x => (x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2);
    case 'easeOutElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
      };
    case 'easeInElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
      };
    case 'easeInOutElastic':
      return x => {
        const c5 = (2 * Math.PI) / 4.5;
        return x === 0
          ? 0
          : x === 1
          ? 1
          : x < 0.5
          ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
          : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
      };
    case 'easeInSine':
      return x => 1 - Math.cos((x * Math.PI) / 2);
    case 'easeOutSine':
      return x => Math.sin((x * Math.PI) / 2);
    case 'easeInOutSine':
      return x => -(Math.cos(Math.PI * x) - 1) / 2;
    case 'easeInCirc':
      return x => 1 - Math.sqrt(1 - Math.pow(x, 2));
    case 'easeOutCirc':
      return x => Math.sqrt(1 - Math.pow(x - 1, 2));
    case 'easeInOutCirc':
      return x =>
        x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    case 'easeInExpo':
      return x => (x === 0 ? 0 : Math.pow(2, 10 * x - 10));
    case 'easeOutExpo':
      return x => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x));
    case 'easeInOutExpo':
      return x =>
        x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2;
    default:
      return x => x; // Linear as default
  }
}

function cloneVector(value, isPos) {
  if (value.type === 'Vec2') {
    value = new Vec2(value._x, isPos ? 1 - value._y : value._y);
  } else if (value.type === 'Vec3') {
    value = new Vec3(value._x, value._y, value._z);
  }
  return value;
}

function packageValue(value) {
  if (value?.type === 'Vec2') {
    return {
      type: 'Vec2',
      _x: value._x,
      _y: value._y,
    };
  } else if (value?.type === 'Vec3') {
    return {
      type: 'Vec3',
      _x: value._x,
      _y: value._y,
      _z: value._z,
    };
  } else {
    return value;
  }
}

export class StateEffectAppear {
  type = 'appear';
  constructor({ prop, value, endValue, transition, id, breakpoints }) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.transition = transition;
    this.complete = false;
    this.progress = 0;
    this.value = cloneVector(value);
    this.endValue = endValue !== undefined && endValue !== null ? cloneVector(endValue) : undefined;
    this.lastTick = undefined;
    this.initialized = false;
    this.breakpoints = breakpoints || [];
  }

  package() {
    let data = {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      transition: this.transition,
      breakpoints: this.breakpoints,
    }
    if(this.endValue !== undefined) {
      data.endValue = packageValue(this.endValue);
    }
    return data;
  }

  updateEffect(endVal, createdAt) {
    endVal = this.endValue ?? endVal;
    if (endVal === undefined || endVal === null) {
      return false;
    }

    const currentTime = performance.now();
    const easeFunction = getEaseFunction(this.transition.ease);
    const effectStart = createdAt + this.transition.delay;
    const progress = Math.max(0, Math.min(1, (currentTime - effectStart) / this.transition.duration));

    this.progress = easeFunction(progress);

    if (this.progress === 0) {
      return false;
    }

    if (progress >= 1) {
      this.complete = true;
      this.progress = 0;
    }

    this.lastTick = currentTime;
    return this.complete ? false : interpolate(this.value, endVal, this.progress);
  }

  resetState() {
    this.progress = 0;
    this.complete = false;
    this.lastTick = undefined;
    this.initialized = false;
  }
}

export class StateEffectScroll {
  type = 'scroll';

  constructor({
    prop,
    value,
    range,
    offset,
    momentum,
    id,
    mode = 'scrollIntoView',
    delta = 0.01,
    absScrollValue = true,
    breakpoints,
  }) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.progress = 0;
    this.momentum = momentum;
    this.range = range;
    this.offset = offset;
    this.mode = mode;
    this.delta = delta;
    this.sceneTop = 0;
    this.startScroll = 0;
    this.endScroll = 0;
    this.lastScrollTop = 0;
    this.absScrollValue = absScrollValue;
    this.value = cloneVector(value);
    this.lastTick = undefined;
    this.breakpoints = breakpoints || [];
  }

  package() {
    return {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      range: this.range,
      offset: this.offset,
      momentum: this.momentum,
      mode: this.mode,
      complete: false,
      delta: this.delta,
      absScrollValue: this.absScrollValue,
      breakpoints: this.breakpoints,
    };
  }

  updateEffect(startVal, { top, height, scroll }) {
    if (startVal === undefined) {
      return false;
    }

    const viewportHeight = window.innerHeight;
    const scrollTop = scroll + 1 || window.scrollY || window.pageYOffset;

    if (this.mode === 'scrollIntoView') {
      const startScroll = top - viewportHeight * this.offset;
      const endScroll = startScroll + (viewportHeight + height) * this.range;

      let progress = (scrollTop - startScroll) / (endScroll - startScroll);
      progress = Math.max(0, Math.min(1, progress));

      this.startScroll = startScroll / window.innerHeight;
      this.endScroll = endScroll / window.innerHeight;
      this.sceneHeight = height / window.innerHeight;
      this.sceneTop = top;
      this.progress = progress;

      let easing = progress;
      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);
      }

      if (Math.abs(this.lastTick - easing) < 0.001) {
        return false;
      }

      this.lastTick = easing;
      return interpolate(startVal, this.value, easing);

    } else if (this.mode === 'whileScrolling') {
      const scrollDelta = scrollTop - this.lastScrollTop;
      this.lastScrollTop = scrollTop;

      let easing = scrollDelta * this.delta;
      if (this.absScrollValue) {
        easing = Math.abs(easing);
      }

      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);
        if (Math.abs(easing) < 0.001) {
          return false;
        }
      }

      this.lastTick = easing;
      return interpolate(startVal, this.value, easing);
    }

    return false;
  }

  resetState() {
    this.progress = 0;
    this.lastTick = undefined;
    this.lastScrollTop = 0;
  }
}

export class StateEffectHover {
  type = 'hover';
  constructor({ prop, value, transition, triggerOnElement, id, breakpoints }) {
    if (id) {
      this.id = id;
    } else {
      this.id = generateUUID();
    }
    this.prop = prop;
    this.transition = transition;
    this.progress = 0;
    this.rawProgress = 0;
    this.lastProgress = null;
    this.value = cloneVector(value);
    this.lastTick = undefined;
    this.lastEnterTime = undefined;
    this.triggerOnElement = triggerOnElement !== undefined ? triggerOnElement : 1;
    this.breakpoints = breakpoints || [];
  }

  package() {
    return {
      id: this.id,
      prop: this.prop,
      value: packageValue(this.value),
      transition: this.transition,
      triggerOnElement: this.triggerOnElement,
      breakpoints: this.breakpoints,
    };
  }

  updateEffect(startVal, enterTime, item) {
    if (startVal === undefined) {
      return false;
    }

    const parent = item?.getParent?.();
    const currentTime = performance.now();
    
    if(this.triggerOnElement && (item?.isElement || parent)) {
      let isHovered = (parent || item).isHovered();
      if(isHovered && !this.lastEnterTime) {
        enterTime = currentTime;
      } else if(!isHovered) {
        enterTime = null;
      } else {
        enterTime = this.lastEnterTime;
      }
    }

    const isReversing = enterTime === null;
    const effectStart = isReversing ? (this.lastTick || currentTime) : enterTime + this.transition.delay;    
    const timeDelta = (currentTime - effectStart) / this.transition.duration;
    const deltaProgress = Math.max(0, Math.min(1, timeDelta));
    
    if (isReversing) {
      this.rawProgress = Math.max(0, this.rawProgress - deltaProgress);
    } else {
      if (this.lastEnterTime !== enterTime) {
        this.lastProgress = this.rawProgress || 0;
      }
      this.rawProgress = Math.min(1, this.lastProgress + deltaProgress);
    }

    const newProgress = getEaseFunction(this.transition.ease)(this.rawProgress);
    const needsUpdate = Math.abs(this.progress - newProgress) > 0.0000001;
    this.progress = newProgress;

    if (!enterTime && !needsUpdate) {
      return false;
    }

    if (!enterTime && this.transition.forwardsOnly) {
      this.progress = 0;
      this.rawProgress = 0;
    }
    
    this.lastTick = currentTime;
    this.lastEnterTime = enterTime;

    return needsUpdate ? interpolate(startVal, this.value, this.progress) : false;
  }

  resetState() {
    this.progress = 0;
  }
}
