<script>
import DesignItem from '../components/DesignItem.vue';
import Folder from '../components/Folder.vue';
import AdminFlyout from '../components/AdminFlyout.vue';
import TemplateItem from '../components/TemplateItem.vue';
import DesignLoadingItem from '../components/DesignLoadingItem.vue';
import Navigation from '../components/Navigation.vue';
import ProModal from '../components/ProModal.vue';
import Button from '../components/Button.vue';
import Icon from '../components/Icon.vue';
import { UserStore } from "../stores/UserStore.js";
import { DesignsStore } from "../stores/DesignsStore.js";
import router from '../router';
import Sidebar from '../components/Sidebar.vue';

export default {
  components: {
      DesignItem,
      TemplateItem,
      Folder,
      AdminFlyout,
      ProModal,
      Button,
      Navigation,
      Icon,
      DesignLoadingItem,
      Sidebar
  },
  data() {
      return {
          UserStore,
          DesignsStore,
          pendingDelete: null,
          renaming: false,
          proModalOpen: false,
          creatingDesign: false,
          creatingFolder: false,
          contextMenuOpen: false,
      }
  },
  mounted() {
    if(!DesignsStore.state.fetchedDesigns) {
      DesignsStore.getDesigns(30);
    }
    if(!DesignsStore.state.templates.length) {
      DesignsStore.getTemplates();
    }
  },
  computed: {
    numPlaceholder() {
      return UserStore.designCount || 0;
    },
    hasProAccess() { return UserStore.hasProAccess}
  },  
  methods: {
    createDesign() {
      this.creatingDesign = true;
      DesignsStore.createDesign((id, vid) => {
        router.push('edit/' + id);
      }, {});
    },
    createFolder() {
      if(!this.hasProAccess) {
        this.proModalOpen = true;
        return;
      }
      this.creatingFolder = true;
      DesignsStore.createFolder(() => {
        this.creatingFolder = false;
      });
    },
    loadMoreDesigns() {
      DesignsStore.getDesigns(48);
    },
    handleTabChange(tab) {
      if (tab === 'recents') {
        DesignsStore.filterRecentDesigns();
      } else if (tab === 'all') {
        DesignsStore.resetFilters();
      }
    },
    navigateToFolder(folderId) {
      this.$router.push(`/dashboard/${folderId}`);
    }
  }
}
</script>

<template>
    <div class="admin-container">
      <div v-if="!UserStore.id || DesignsStore.state.loadingDesigns" class="loading-bar"></div>
      <div class="dashboard-content">
        <div class="flex align-center justify-between">
          <h2 class="h2 my-0">Dashboard</h2>
          <div class="flex align-center">
            <!-- <Button icon="plus" class="secondary mr-2" :loading="creatingFolder" @click="createFolder">
              New folder
            </Button> -->
            <Button icon="plus" class="primary" :loading="creatingDesign" @click="createDesign">
              New project
            </Button>
          </div>
        </div>
        <h1 class="h3 mt-4">Starter templates</h1>
        <div class="template-listing-container mt-2">
          <div class="starter-templates flex align-center">
            <div class="starter-template-scroll-container ml-2">
              <template v-if="!UserStore.id || DesignsStore.state.loadingTemplates">
                <DesignLoadingItem
                    v-for="index in 10"
                    :template="true"
                    :key="'template_loading_' + index"
                ></DesignLoadingItem>
              </template>
              <template v-else>
                <TemplateItem 
                  v-for="design in DesignsStore.state.templates"
                  :design="design"
                  :key="design.id"
                ></TemplateItem>
              </template>
            </div>
          </div>
        </div>
        <div class="design-listing-container">
          <div class="mb-2 mt-5 flex align-center">
            <h1 class="h3 my-0">Recent projects</h1>
          </div>
          <div class="design-container">
            <template v-if="DesignsStore.state.designs.length > 0">
              <DesignItem
                v-for="design in DesignsStore.state.displayedDesigns"
                :design="design"
                :key="design.id"
              ></DesignItem>
            </template>
            <template v-if="DesignsStore.state.loadingDesigns">
              <DesignLoadingItem
                v-for="index in 6"
                :key="'design_loading_' + index"
              ></DesignLoadingItem>
            </template>
          </div>
          <div v-if="DesignsStore.state.designs.length === 0 && !DesignsStore.state.loadingDesigns">
            <div class="empty-state">
              <p class="h4 mt-0">Here are some ways to get started:</p>
              <ul>
                <li>Explore the starter templates above or <RouterLink to="dashboard/community">community templates</RouterLink></li>
                <li>Check out a <a href="https://youtu.be/3h4YGu_w35E" target="_blank">video tutorial</a></li>
                <li>Explore the <a href="https://unicorn.studio/docs/" target="_blank">online guide</a></li>
              </ul>
            </div>
          </div>
          <div v-if="DesignsStore.state.designs.length >= 23 && DesignsStore.state.hasMoreDesigns" class="pagination">
            <a href="javascript:void(0)" @click="loadMoreDesigns" class="page-number" :class="{'page-number__loading': DesignsStore.state.loadingDesigns}">
              <template v-if="DesignsStore.state.loadingDesigns">Loading more...</template>
              <template v-else>Load more</template>
            </a>
          </div>  
        </div>
      </div>
    </div>
</template>


<style lang="scss" scoped>

.template-listing-container {
  position: relative;
  background-color: var(--bg-color);
  padding: 1rem 1rem 1rem 0;
  border-radius: 0.8rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 1rem;
    width: 6rem;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to right, transparent, var(--bg-color));
  }
}

.empty-state {
  background-color: var(--bg-color);
  padding: 2rem;
  border-radius: 0.8rem;
  color: var(--font-secondary-color);
}

</style>