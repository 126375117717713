import { universalUniformParams, vertexShaderNoMatrix, UNIVERSAL_UNIFORMS } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;
uniform vec2 uPreviousMousePos;
uniform float uRadius;
uniform float uDissipate;
uniform float uHardness;
uniform float uDecay;
uniform float uLiquidity;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

const float PI = 3.1415926;
const float TWOPI = 6.2831852;

out vec4 fragColor;

vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

mat2 rot(float a) {
    return mat2(cos(a), -sin(a), sin(a), cos(a));
}

vec2 liquify(vec2 st, float angle) {
    float aspectRatio = uResolution.x / uResolution.y;
    st.x *= aspectRatio;
    st = st * rot(angle * TWOPI);
    float amplitude = 0.003;
    for (float i = 1.0; i <= 5.0; i++) {
        st = st * rot(i / 5.0 * PI * 2.0);
        st += vec2(
            amplitude * cos(i * 5.0 * st.y + uTime * 0.02),
            amplitude * sin(i * 5.0 * st.x + uTime * 0.02)
        );
    }
    st = st * rot(-angle * TWOPI);
    st.x /= aspectRatio;
    return st;
}

void main() {
    float aspectRatio = uResolution.x / uResolution.y;
    vec2 uv = vTextureCoord;
    vec2 correctedUv = uv * vec2(aspectRatio, 1.0);

    vec2 dir = (uMousePos - uPreviousMousePos) * vec2(aspectRatio, 1.0);
    float dist = length(dir);
    dir = normalize(dir);

    float rad = uRadius * 0.4 * mix(aspectRatio, 1.0, 0.5);
    float angle = atan(dir.y, dir.x);
    if (angle < 0.0) angle += TWOPI;


    // #ifelseopen
    if(uLiquidity > 0.) {
      uv = mix(uv, liquify(uv, smoothstep(0.0, 1.0, angle)), uLiquidity);
    }
    // #ifelseclose

    float distLine = distance(uPreviousMousePos, uMousePos);

    float t = clamp(dot(correctedUv - uPreviousMousePos * vec2(aspectRatio, 1.0), dir) / dist, 0.0, 1.0);
    vec2 closestPoint = mix(uPreviousMousePos, uMousePos, t) * vec2(aspectRatio, 1.0);
    float distanceToLine = distance(correctedUv, closestPoint);

    float s = smoothstep(rad, rad * uHardness, distanceToLine);

    vec3 color = vec3(angle / TWOPI, 1.0, 1.0);
    vec3 mouseColor = hsv2rgb(color);

    vec2 sampleUv = mix(uv, uv / (1.0 + uDissipate * 0.03) + uDissipate * 0.015, uDissipate);
    vec3 lastFrameColor = texture(uPingPongTexture, sampleUv).rgb;
    
    lastFrameColor = pow(lastFrameColor, vec3(2.2));
    mouseColor = pow(mouseColor, vec3(2.2));
    
    float intensity = min(0.7, dist * 10.0) * s * 0.4;
    vec3 draw = mix(lastFrameColor, mouseColor, intensity);
    
    draw *= pow(uDecay, 0.2);
    
    draw = pow(draw, vec3(1.0/2.2));
    
    fragColor = vec4(draw, 1.0);
}`;

export const MOUSE_PINGPONG = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShaderNoMatrix,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: 'half-float',
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    previousMousePos: {
      name: 'uPreviousMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    radius: {
      name: 'uRadius',
      type: '1f',
      value: 0.5,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    liquidity: {
      name: 'uLiquidity',
      type: '1f',
      value: 0,
    },
    hardness: {
      name: 'uHardness',
      type: '1f',
      value: 0,
    },
    dissipate: {
      name: 'uDissipate',
      type: '1f',
      value: 0.25,
    },
    decay: {
      name: 'uDecay',
      type: '1f',
      value: 0.5,
    },
    ...universalUniformParams,
  },
};
